import React from "react";
import { useGetActiveLocation } from "hooks/useGetActiveLocation";
import classes from "./OrderDiscountNew.module.scss";
import styled from "styled-components";
import { toast } from "react-toastify";

const DiscountByOrder = ({
  label,
  discountInfo,
  setDiscountInfo,
  cartTotalAmount,
  cartTotalTax,
  onFocus = () => {},
}) => {
  const { decimal_preference, currency } = useGetActiveLocation();

  const id = label + Math.random();

  const cartPayableAmount = cartTotalAmount + Number(cartTotalTax);

  const itemDiscountHandler = (e, valueFor) => {
    if(Number(e.target.value < 0)){
      toast.error("Please enter a valid amount.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setDiscountInfo((prevDiscountInfo) => ({
        ...prevDiscountInfo,
        [valueFor]: 0,
      }));
      return;
    }
    if (valueFor === "discount_value") {
      if (!e.target.value) {
        setDiscountInfo((prevDiscountInfo) => ({
          ...prevDiscountInfo,
          [valueFor]: "",
        }));
        return;
      }
      let discountValue = Number(e.target.value);
      if (discountInfo.discount_type === "percentage" && discountValue > 100) {
        // If discount type is percentage and entered value is greater than 100, set discount value to 0
        discountValue = "";
        toast.error("Please enter a valid percentage value (0-100).", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else if (
        discountInfo.discount_type === "fixed" &&
        discountValue > cartPayableAmount
      ) {
        // If discount type is fixed and entered value is greater than payable amount, set discount value to 0
        discountValue = "";
        toast.error("Discount value cannot exceed the payable amount.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
      setDiscountInfo((prevDiscountInfo) => ({
        ...prevDiscountInfo,
        [valueFor]: discountValue,
      }));
    } else if (valueFor === "discount_type") {
      let discountType = e.target.value;
      let discountValue = discountInfo.discount_value;

      if (discountType === "percentage" && discountValue > 100) {
        discountValue = 0
        toast.error("Please enter a valid percentage value (0-100).", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else if (discountType === "fixed" && discountValue > cartPayableAmount) {
        discountValue = 0
        toast.error("Discount value cannot exceed the payable amount.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
      setDiscountInfo((prevDiscountInfo) => ({
        ...prevDiscountInfo,
        [valueFor]: e.target.value,
        discount_value: discountValue
      }));
    }
  };

  const calculateTotalDiscount = () => {
    if (discountInfo.discount_type === "percentage") {
      return cartPayableAmount * (Number(discountInfo.discount_value) / 100);
    } else if (discountInfo.discount_type === "fixed") {
      return Number(discountInfo.discount_value);
    }
  };

  const payableAmount = cartPayableAmount - calculateTotalDiscount();

  return (
    <>
      <div className="flex justify-between mt-5">
        {/* Sub Total */}
        <div className="flex flex-col items-center justify-between mb-8">
          <p className="font-Inter text-xl font-normal text-gray-600">
            Subtotal
          </p>
          <p className="font-Inter text-xl font-semibold text-greyText">
            {cartTotalAmount.toFixed(decimal_preference)}
          </p>
        </div>
        {/* Tax */}
        <div className="flex flex-col items-center justify-between mb-8 space-y-3">
          <p className="font-Inter text-xl font-normal text-gray-600">Tax</p>
          <p className="font-Inter text-xl font-semibold text-greyText">
            {cartTotalTax?.toFixed(decimal_preference) || "N/A"}
          </p>
        </div>
        <div className="flex flex-col items-center justify-between mb-8">
          <p className="font-Inter text-xl font-normal text-gray-600">
            Discount
          </p>
          <p className="font-Inter text-xl font-semibold text-greyText">
            {discountInfo.discount_type === "fixed" && currency}{" "}
            {discountInfo.discount_value || 0}
            {discountInfo.discount_type === "percentage" && "%"}
          </p>
        </div>
        {/* Payable amount */}
        <div className="flex flex-col items-center justify-between mb-8">
          <p className="font-Inter text-xl font-normal text-gray-600">
            Payable Amount
          </p>
          <p className="font-Inter text-xl font-semibold text-greyText">
            {Number(payableAmount?.toFixed(decimal_preference)) || 0}
          </p>
        </div>
      </div>
      <div className="w-full h-[0.2px] mb-8 bg-gray-300" />
      <div>
        <div className="flex justify-between items-center">
          <p className="text-gray-600 text-xl font-medium">
            Add Discount by Check
          </p>
          <div className="custom-box flex">
            <label className={`${classes.TableRadioButton} text-xl`}>
              <input
                onChange={(e) => itemDiscountHandler(e, "discount_type")}
                type="radio"
                name="discount-type"
                value="fixed"
                checked={discountInfo?.discount_type === "fixed"}
              />
              <span className={classes.TableRadioButtonIcon}></span>
              <span className={classes.TableRadioButtonShapeRounded}></span>
              Fixed
            </label>
            <label className={`${classes.TableRadioButton} text-xl ml-6`}>
              <input
                onChange={(e) => itemDiscountHandler(e, "discount_type")}
                type="radio"
                name="discount-type"
                value="percentage"
                checked={discountInfo?.discount_type === "percentage"}
              />
              <span className={classes.TableRadioButtonIcon}></span>
              <span className={classes.TableRadioButtonShapeRounded}></span>
              Percentage
            </label>
          </div>
        </div>
      </div>

      {/* Discount */}
      <div className="h-16   w-full rounded border-2 border-gray-300 mt-4">
        <InputContainer className="w-full h-full pl-2 ">
          <input
            className="outline-none focus:outline-none bg-transparent w-full h-full text-xl font-Inter text-primary"
            type="number"
            id={id}
            value={discountInfo?.discount_value}
            max={
              discountInfo.discount_type === "percentage"
                ? 100
                : cartTotalAmount
            }
            min={0}
            onChange={(e) => itemDiscountHandler(e, "discount_value")}
            placeholder="Please enter discount value"
            required={true}
            onFocus={onFocus}
            autoFocus={true}
          />
        </InputContainer>
        <small>This will be applied to the whole bill.</small>
      </div>
    </>
  );
};

export default DiscountByOrder;

const InputContainer = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;
