import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  duplicateOrder: null,
};

const duplicateOrderSlice = createSlice({
  name: "duplicateOrder",
  initialState,
  reducers: {
    addDuplicateOrder(state, action) {
      state.duplicateOrder = action.payload;
    },
  },
});

export const { addDuplicateOrder } = duplicateOrderSlice.actions;
export default duplicateOrderSlice.reducer;
