import React from "react";
import DefaultIMG from "../../../../assets/img/talabat.png";
import moment from "moment";
import { useDispatch } from "react-redux";
import { saveDeliverectOrder } from "redux/editingOrder";

const DeliverectOrder = ({ order, toggleDrawer }) => {
  const dispatch = useDispatch();

  const { channel_order_display_id, delivery_channel, created_at, order_no } =
    order;
  const imgUrl =
    delivery_channel && delivery_channel?.smallimage
      ? delivery_channel?.smallimage
      : DefaultIMG;

  return (
    <div
      onClick={() => {
        window.scrollTo({ top: 0 });
        toggleDrawer();
        dispatch(saveDeliverectOrder(order));
      }}
      className="w-full bg-gray-50 px-5 py-8 rounded-md cursor-pointer"
      role="listitem"
    >
      <div className="w-full">
        <img
          src={imgUrl}
          alt={`${delivery_channel?.name}`}
          className="block w-[60px] h-[60px] rounded-full"
        />
      </div>
      <div className="mt-8">
        <span className="md:text-2xl lg:text-3xl text-greyText pl-2 font-Inter font-medium">
          #{channel_order_display_id || order_no}
        </span>
      </div>
      <div className="mt-5 font-Inter font-normal text-gray-400">
        <span>Time: {moment(created_at).format("h:mm:ss A")}</span>
      </div>
    </div>
  );
};

export default DeliverectOrder;
