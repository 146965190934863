import React from "react";
import Icon from "components/Icon/Icon";
import styled from "styled-components";
import { useSelector } from "react-redux";

const SingleServices = ({
  icon,
  engText,
  arabicText,
  onClick,
  isSelected,
  isLocked,
}) => {
  let activeStyles = "";
  const { orderTypes } = useSelector((state) => state.createOrder);

  switch (isSelected) {
    case true:
      activeStyles =
        "w-full rounded bg-[#7C3A09] h-48 flex flex-row items-center px-8 cursor-pointer";
      break;

    default:
      activeStyles =
        "w-full rounded bg-primary h-48 flex flex-row items-center px-8 cursor-pointer";
      break;
  }

  return (
    <Container
      className={activeStyles}
      onClick={() => onClick(engText)}
      onKeyDown={(e) => {
        if (e.code === "Enter") {
          onClick(engText);
        }
      }}
    >
      <div className="w-12 h-12 rounded-full bg-white flex flex-row items-center justify-center">
        {orderTypes.includes(engText) ? (
          <Icon
            name="check"
            height={1.6}
            width={1.6}
            className="fill-primary text-primary"
          />
        ) : (
          <Icon
            name={icon}
            height={1.6}
            width={1.6}
            className="fill-primary text-primary"
          />
        )}
      </div>
      <div className="ml-5">
        <p className="font-Inter text-3xl text-white font-medium capitalize">
          {engText}
        </p>
        <p className="font-Inter text-3xl text-white font-medium pt-1">
          {arabicText}
        </p>
      </div>
    </Container>
  );
};

export default SingleServices;
const Container = styled.div`
  svg {
    color: #f3781f;
  }
`;
