import Portal from "../Portal/Portal";
import Loader from "../Loader/Loader";
import Backdrop from "../Backdrop/Backdrop";

const LoadingScreen = ({ show }) => {
  let screen = null;

  if (show) {
    screen = (
      <Portal>
        <Backdrop>
          <Loader />
        </Backdrop>
      </Portal>
    );
  }
  return screen;
};

export default LoadingScreen;
