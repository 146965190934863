import React, { useState } from "react";
import SingleDesktopFilterSelect from "./SingleDesktopFilterSelect";
import styled from "styled-components";
import PrimaryButton from "components/PrimaryButton/PrimaryButton";
import { useTranslation } from "react-i18next";
import Icon from "components/Icon/Icon";
import SingleMobileFilterSelect from "./SingleMobileFilterSelect";

const HeaderFilter = ({
  selectedOption,
  onChangeFilter,
  createNewOrder = () => {},
  hideButton,
  data,
  style = {},
}) => {
  const tabLength = data.length;
  const { t } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false);
  return (
    <div className="w-full">
      {/* Desktop Tab View */}
      <TabHeader
        length={tabLength}
        className={`w-full border-b border-b-gray-200 lg:grid hidden  lg:gap-3 xl:gap-5`}
        style={style}
      >
        {data.map((item, index) => (
          <SingleDesktopFilterSelect
            item={item}
            key={index}
            active={selectedOption}
            onChangeFilter={onChangeFilter}
          />
        ))}
      </TabHeader>
      {/* Tab View */}
      <div className="w-full flex flex-row items-center justify-between lg:hidden">
        {/* Dropdown Section */}
        <div className="w-[26%] relative">
          <div
            onClick={() => setShowDropdown(!showDropdown)}
            className={
              showDropdown
                ? "w-full border border-primary rounded-md h-14 bg-gray-200 flex flex-row items-center justify-between px-3 font-medium text-xl font-Inter text-greyText"
                : "w-full border border-gray-400 rounded-md h-14 bg-gray-200 flex flex-row items-center justify-between px-3 font-medium text-xl font-Inter text-greyText"
            }
          >
            <div className="flex flex-row">
              <p> {t(selectedOption?.textLg1)}</p>
              <p className="px-4"> {selectedOption?.textLg2}</p>
            </div>
            <Icon name="chevron-down" />
          </div>
          {/* Dropdown */}
          {showDropdown && (
            <div
              className={
                showDropdown
                  ? "bg-white w-full absolute pb-2 max-h-96 overflow-y-auto pt-2 border border-primary rounded-md left-0 right-0 top-[100%] z-50 px-2"
                  : "bg-white w-full absolute pb-2 max-h-96 overflow-y-auto pt-2 border border-gray-100 rounded-md left-0 right-0 top-[100%] z-50 px-2"
              }
            >
              {data.map((item, index) => (
                <SingleMobileFilterSelect
                  item={item}
                  key={index}
                  active={selectedOption}
                  onChangeFilter={onChangeFilter}
                  closeDropdown={() => setShowDropdown(false)}
                />
              ))}
            </div>
          )}
        </div>
        {/* Create Order section */}
        {!hideButton && (
          <div>
            <PrimaryButton
              text="New Order طلب جديد"
              onClicked={() => createNewOrder()}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderFilter;

const TabHeader = styled.div`
  grid-template-columns: repeat(6, minmax(0, 1fr));
  grid-template-columns: ${(props) =>
    `repeat(${props.length}, minmax(0, 1fr))`};
`;
