import { useState } from "react";

export default function useToggle(initialState = false) {
  const [value, setValue] = useState(initialState);

  const toggle = () => {
    setValue((oldState) => !oldState);
  };

  return [value, toggle];
}
