import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  refetchConfig: false,
};

const appSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    toggleConfig(state, action) {
      state.refetchConfig = action.payload;
    },
  },
});

export const { toggleConfig } = appSlice.actions;
export default appSlice.reducer;
