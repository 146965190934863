import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosWithLocationV2 from "../config/axiosWithLocationV2";

const initialState = {
  tables: [],
  isLoading: true,
  error: null,
  message: "",
};

export const fetchTables = createAsyncThunk(
  "table/fetchTables",
  async (payload, thunkAPI) => {
    const response = await axiosWithLocationV2
      .get("/outlet/tables")
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        return thunkAPI.rejectWithValue(
          err?.response?.message ||
            "Something went wrong! Please try again later"
        );
      });
    return response;
  }
);

export const tableSlice = createSlice({
  name: "table",
  initialState,
  reducers: {
    addTable(state, action) {
      state.tables.push(action.payload);
    },
    updateTable(state, action) {
      const index = state.tables.findIndex(
        (table) => table.id === action.payload.id
      );
      if (index > -1) {
        state.tables[index] = { ...action.payload };
      }
    },
    deleteTable(state, action) {
      const index = state.tables.findIndex(
        (table) => table.id === action.payload
      );
      if (index > -1) {
        state.tables.splice(index, 1);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTables.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchTables.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.tables = action.payload.data;
      state.message = action.payload.message;
    });
    builder.addCase(fetchTables.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export const { addTable, updateTable, deleteTable } = tableSlice.actions;

export default tableSlice.reducer;
