import Portal from "../Portal/Portal";
import classes from "./Backdrop.module.scss";

const Backdrop = ({ type, onClicked, children, className }) => {
  let styleClasses = classes.Backdrop;

  switch (type) {
    case "dark":
      styleClasses = [styleClasses, classes.Dark].join(" ");
      break;

    default:
      styleClasses = [styleClasses, classes.Light].join(" ");
      break;
  }

  return (
    <Portal>
      <div onClick={onClicked} className={styleClasses}>
        {children}
      </div>
    </Portal>
  );
};

export default Backdrop;
