import React from "react";
import Icon from "components/Icon/Icon";
import { updateString } from "utillity/utils";
import OrderStatus from "./OrderStatus";
import { useDispatch, useSelector } from "react-redux";

import { changeActiveService } from "redux/editingOrder";

const OrderServicesView = ({ multiOrderTypes = [] }) => {
  const dispatch = useDispatch();
  // dispatch(changeActiveCart(selectedOrders[0]?.type));
  const { translations: servicesTranslations } = useSelector(
    (state) => state.service
  );
  if (multiOrderTypes.length === 1) {
    return (
      <div
        onClick={() => {
          dispatch(changeActiveService(multiOrderTypes[0]?.type));
        }}
        className="w-full border border-gray-200 rounded-md h-[240px] bg-gray-100 flex flex-row items-center justify-center cursor-pointer truncate"
      >
        <div className="flex flex-col items-center">
          {multiOrderTypes[0]?.type === "dine in" && (
            <div className="flex items-center space-x-2 w-full">
              <h6 className="font-bold text-2xl text-black">{multiOrderTypes[0]?.outletTable?.table_number}</h6>{": "}
              <span className="text-xl font-bold text-gray-500">
                {multiOrderTypes[0]?.zone?.name} 
              </span>
            </div>
          )}
          <div className="flex flex-row items-center mt-10">
            <Icon name={updateString(multiOrderTypes[0]?.type, " ", "-")} />
            <p className="text-2xl text-greyText font-Inter font-medium capitalize pl-4 ">
              {multiOrderTypes[0]?.type}
            </p>
          </div>
          {/* Translation */}
          <p className="font-Inter pt-5 text-greyText text-center">
            {servicesTranslations?.ar[multiOrderTypes[0]?.type] || ""}
          </p>
          <div className="mt-10">
            <OrderStatus status={multiOrderTypes[0]?.status} />
          </div>
        </div>
      </div>
    );
  } else if (multiOrderTypes.length === 2) {
    return (
      <div className="w-full h-[240px] grid grid-cols-1 grid-rows-2 gap-4">
        {multiOrderTypes.map((item, index) => (
          <div
            onClick={() => {
              dispatch(changeActiveService(item?.type));
            }}
            className="w-full border border-gray-200 rounded-md h-full bg-gray-100 px-5 py-4 relative cursor-pointer"
            key={index}
          >
            <div className="flex flex-row items-center justify-between">
              <div className="flex">
                <Icon name={updateString(item?.type, " ", "-")} />
                <p className="text-2xl text-greyText font-Inter font-medium capitalize pl-4 ">
                  {item?.type}
                </p>
              </div>
              {item?.type === "dine in" && (
                <div className="flex items-center space-x-2">
                  <h6 className="font-bold text-2xl text-black">{item?.outletTable?.table_number}</h6>{": "}
                  <span className="text-xl font-bold text-gray-500">
                    {item?.zone?.name}
                  </span>
                </div>
              )}
            </div>
            <p className="font-Inter pt-2 text-greyText text-right">
              {servicesTranslations?.ar[item?.type] || ""}
            </p>
            <div className="mt-2 w-[40%] absolute left-5 bottom-5">
              <OrderStatus status={item?.status} />
            </div>
          </div>
        ))}
      </div>
    );
  } else if (multiOrderTypes.length === 3) {
    return (
      <div className="w-full h-[240px] grid grid-cols-1 grid-rows-2 gap-4 ">
        {/* Top Details */}
        <div
          onClick={() => {
            dispatch(changeActiveService(multiOrderTypes[0]?.type));
          }}
          className="w-full h-full cursor-pointer"
        >
          <div className="w-full border border-gray-200 rounded-md h-full bg-gray-100 px-5 py-4 relative">
            <div className="flex flex-row items-center justify-between">
              <div className="flex">
                <Icon name={updateString(multiOrderTypes[0]?.type, " ", "-")} />
                <p className="text-2xl text-greyText font-Inter font-medium capitalize pl-4 ">
                  {multiOrderTypes[0]?.type}
                </p>
              </div>
              {multiOrderTypes[0]?.type === "dine in" && (
                <div className="flex items-center space-x-2">
                  <h6 className="font-bold text-2xl text-black">{multiOrderTypes[0]?.outletTable?.table_number}</h6>{": "}
                  <span className="text-xl font-bold text-gray-500">
                    {multiOrderTypes[0]?.zone?.name}
                  </span>
                </div>
              )}
            </div>
            {/* Translation */}
            <p className="font-Inter pt-5 text-greyText text-right">
              {servicesTranslations?.ar[multiOrderTypes[0]?.type] || ""}
            </p>
            <div className="mt-2 w-[40%] absolute left-5 bottom-5">
              <OrderStatus status={multiOrderTypes[0]?.status} />
            </div>
          </div>
        </div>
        <div className="w-full h-full grid grid-cols-2 gap-4">
          {/* First */}
          <div
            onClick={() => {
              dispatch(changeActiveService(multiOrderTypes[1]?.type));
            }}
            className="w-full border border-gray-200 rounded-md h-full bg-gray-100 px-5 py-4 relative cursor-pointer"
          >
            <div className="flex flex-col items-start justify-between">
              <div className="flex">
                <Icon name={updateString(multiOrderTypes[1]?.type, " ", "-")} />
                <p className="text-2xl text-greyText font-Inter font-medium capitalize pl-4 ">
                  {multiOrderTypes[1]?.type}
                </p>
              </div>
              {multiOrderTypes[1]?.type === "dine in" && (
                <div className="flex items-center space-x-2 mt-3">
                  <h6 className="font-bold text-2xl text-black w-[45%] truncate">{multiOrderTypes[1]?.outletTable?.table_number}</h6>{": "}
                  <span className="text-xl font-bold text-gray-500 w-[42%] truncate">
                    {multiOrderTypes[1]?.zone?.name}
                  </span>
                </div>
              )}
            </div>
            {/* Translation */}
            <p className="font-Inter pt-5 text-greyText text-right">
              {servicesTranslations?.ar[multiOrderTypes[1]?.type] || ""}
            </p>
            <div className="mt-2 w-[90%] absolute left-5 bottom-5">
              <OrderStatus status={multiOrderTypes[1]?.status} />
            </div>
          </div>
          {/* Second */}
          <div
            onClick={() => {
              dispatch(changeActiveService(multiOrderTypes[2]?.type));
            }}
            className="w-full border border-gray-200 rounded-md h-full bg-gray-100 px-5 py-4 relative cursor-pointer"
          >
            <div className="flex flex-col items-start justify-between">
              <div className="flex">
                <Icon name={updateString(multiOrderTypes[2]?.type, " ", "-")} />
                <p className="text-2xl text-greyText font-Inter font-medium capitalize pl-4 ">
                  {multiOrderTypes[2]?.type}
                </p>
              </div>
              {multiOrderTypes[2]?.type === "dine in" && (
                <div className="flex items-center space-x-2 mt-3">
                  <h6 className="font-bold text-2xl text-black w-[45%] truncate">{multiOrderTypes[2]?.outletTable?.table_number}</h6>{": "}
                  <span className="text-xl font-bold text-gray-500 w-[42%] truncate">
                  {multiOrderTypes[2]?.zone?.name}
                  </span>
                </div>
              )}
            </div>
            {/* Translation */}
            <p className="font-Inter pt-5 text-greyText text-right">
              {servicesTranslations?.ar[multiOrderTypes[2]?.type] || ""}
            </p>
            <div className="mt-2 w-[90%] absolute left-5 bottom-5">
              <OrderStatus status={multiOrderTypes[2]?.status} />
            </div>
          </div>
        </div>
      </div>
    );
  } else if (multiOrderTypes.length === 4) {
    return (
      <div className="w-full h-[240px] grid grid-cols-2 grid-rows-2 gap-4">
        {multiOrderTypes.map((item, index) => (
          <div
            onClick={() => {
              dispatch(changeActiveService(item?.type));
            }}
            className="w-full border border-gray-200 rounded-md h-full bg-gray-100 px-5 py-4 relative cursor-pointer"
            key={index}
          >
            <div className="flex flex-row items-center">
              <Icon name={updateString(item?.type, " ", "-")} />
              <p className="text-2xl text-greyText font-Inter font-medium capitalize pl-4 ">
                {item?.type}
              </p>
            </div>
            <p className="font-Inter pt-2 text-greyText text-right">
              {servicesTranslations?.ar[item?.type] || ""}
            </p>
            <div className="mt-2 w-[90%] absolute left-5 bottom-5">
              <OrderStatus status={item?.status} />
            </div>
          </div>
        ))}
      </div>
    );
  } else {
    return <></>;
  }
};

export default OrderServicesView;
