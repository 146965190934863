import { useNavigate } from "react-router-dom";
import FilterCategoryButton from "./FilterCategoryButton/FilterCategoryButton";
// import classes from "./FilterCategoryTab.module.scss";

const FilterCategoryTab = ({
  categories,
  selectedCategory,
  onChangeFilter,
  customClasses = "",
}) => {
  const navigate = useNavigate();
  // const styleClasses = [customClasses, classes.FilterCategoriesTab].join(" ");
  // className = { styleClasses };
  return (
    <div className="w-full bg-white px-2 py-5 rounded-md grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 max-h-80 overflow-y-scroll">
      <div
        onClick={() => navigate("/orders/create/category-selection")}
        // className={classes.SeeAll}
        className="rounded-md text-[#F3781F] hover:bg-[#F3781F] flex flex-row items-center justify-center hover:text-white hover:font-semibold cursor-pointer font-Inter text-xl"
      >
        See All
      </div>
      {categories.map((category) => {
        return (
          <FilterCategoryButton
            key={category.name}
            name="filterCategory"
            value={category.name}
            text={category.name}
            isChecked={selectedCategory === category.name}
            onChanged={onChangeFilter}
          />
        );
      })}
    </div>
  );
};

export default FilterCategoryTab;
