import { useState, useRef, useEffect } from "react";
import Icon from "components/Icon/Icon";
import useOutsideClick from "hooks/useOutsideClick";
import { useTranslation } from "react-i18next";
import classes from "./LanguageSwitcher.module.scss";

const LANGUAGES = [
  { text: "ENG", icon: "flag-us", value: "en" },
  { text: "ARB", icon: "flag-sa", value: "ar" },
];

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [showMenu, setShowMenu] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  let selectLangClasses = [
    classes.LanguageSwitcherItemButton,
    classes.SelectedLanguage,
  ].join(" ");
  const menuRef = useRef();
  useOutsideClick(menuRef, () => {
    if (showMenu) setShowMenu(false);
  });

  const toggleMenu = () => {
    setShowMenu((oldState) => !oldState);
  };

  const handleLanguageChange = (newLang) => {
    i18n.changeLanguage(newLang);
    const langIndex = LANGUAGES.findIndex((lang) => lang.value === newLang);
    if (langIndex > -1) {
      setSelectedLanguage(LANGUAGES[langIndex]);
    }
  };

  useEffect(() => {
    if (!selectedLanguage) {
      const langIndex = LANGUAGES.findIndex(
        (lang) => lang.value === i18n.language
      );
      if (langIndex > -1) {
        setSelectedLanguage(LANGUAGES[langIndex]);
      }
    }
  }, [selectedLanguage, i18n]);

  return (
    <div
      ref={menuRef}
      onClick={toggleMenu}
      className={classes.LanguageSwitcher}
    >
      {selectedLanguage && (
        <button className={classes.LanguageSwitcherHeader}>
          <Icon name={selectedLanguage.icon} width={1.6} height={1.2} />
          <span>{selectedLanguage.text}</span>
          <Icon name="chevron-down" width={1.6} height={1.6} />
        </button>
      )}
      {showMenu && (
        <ul className={classes.LanguageSwitcherList}>
          {LANGUAGES.map((lang) => {
            return (
              <li key={lang.text} className={classes.LanguageSwitcherListItem}>
                <button
                  className={
                    lang.value === selectedLanguage.value
                      ? selectLangClasses
                      : classes.LanguageSwitcherItemButton
                  }
                  onClick={() => handleLanguageChange(lang.value)}
                >
                  <Icon name={lang.icon} width={1.6} height={1.2} />
                  <span>{lang.text}</span>
                </button>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default LanguageSwitcher;
