import Icon from "components/Icon/Icon";
import classes from "./DrawerCartPrimaryActionButtons.module.scss";
import { usePermissionHooks } from "hooks/usePermissionHooks";
import { ORDER_COMPLETE_PERMISSION, ORDER_UPDATE_PERMISSION } from "constants/constants";

const DrawerCartPrimaryActionButtons = ({
  onProceed = () => {},
  onEdit = () => {},
  text = "",
  textTwo = "",
  icon = "",
  iconTwo = "",
  hideVoidBtn = false,
  proceedDisabled = false,
}) => {

  const { canView: canCompleteOrder } = usePermissionHooks(ORDER_COMPLETE_PERMISSION);
  const { canView: canUpdateOrder } = usePermissionHooks(ORDER_UPDATE_PERMISSION);
  return (
    <div className={classes.DrawerCartPrimaryActionButtons}>
      {!hideVoidBtn && canUpdateOrder && (
        <button onClick={onEdit} className={classes.EditButton}>
          <Icon name={icon ? icon : "order-edit-1"} width={2.4} height={2.4} />
          <span>{text ? text : "Edit Order"}</span>
        </button>
      )}

      {canCompleteOrder && (
        <button disabled={proceedDisabled} className={classes.ProceedButton} onClick={onProceed}>
          <Icon name={iconTwo ? iconTwo : "bill"} width={2.4} height={2.4} />
          <span>{textTwo ? textTwo : "Proceed to Checkout"}</span>
        </button>
      )}
    </div>
  );
};

export default DrawerCartPrimaryActionButtons;
