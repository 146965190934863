import axiosWithLocation from "config/axiosWithLocationV2";

export const ZoneServices = {
  getAllZones: function (success, fail) {
    axiosWithLocation.get("/zone").then(success).catch(fail);
  },
  createZone: function (zone, success, fail) {
    axiosWithLocation.post("/zone/store", zone).then(success).catch(fail);
  },
  deleteZone: function (zoneId, success, fail) {
    axiosWithLocation
      .delete("/zone/" + zoneId)
      .then(success)
      .catch(fail);
  },
};
