import Header from "./Header/Header";
import Sidebar from "./Sidebar/Sidebar";
import classes from "./Layout.module.scss";
import { useWindowSize } from "hooks//useWindowSize";
import Drawer from "./Drawer/Drawer";
import { useSelector } from "react-redux";
import ToggleDrawerButton from "components//ToggleDrawerButton/ToggleDrawerButton";
import noSupportImg from "assets//img/responsive-website-no-support.png";
import Icon from "components//Icon/Icon";
import { useTranslation } from "react-i18next";
import Loader from "components/Loader/Loader";
import { useContext } from "react";
import { AuthContext } from "context/authContext";

const POSLayout = ({ children }) => {
  const { t } = useTranslation();
  let size = useWindowSize();
  const { isOpen } = useSelector((state) => state.drawer);
  const { logoutLoading } = useContext(AuthContext);

  const screenNotSupported = (
    <div className={classes.NoSupportScreen}>
      <ToggleDrawerButton />
      <br />
      <div className={classes.NoSupportScreenContent}>
        <div className={classes.NoSupportScreenContentImg}>
          <img src={noSupportImg} alt={t("VIEW_NOT_SUPPORTED")} />
          <Icon name="cross-with-circle" />
        </div>
        <h2>{t("VIEW_NOT_SUPPORTED")}</h2>
        <p>{t("VIEW_NOT_AVAILABLE")}</p>
      </div>
    </div>
  );

  if (logoutLoading) {
    return <Loader />;
  }

  return (
    <div className={classes.POSLayout}>
      <div>
        {size.width > 850 && <Sidebar />}
        {size.width <= 850 && <Drawer show={isOpen} />}
      </div>
      <div
        isOpen={isOpen}
        className={`${!isOpen ? classes.Container : classes.Collapsed_Container}`}
        id="outerContainer"
      >
        <Header isOpen={isOpen} />
        <main className={`${!isOpen ? classes.Main : classes.Collapsed_Main}`} id={"innerContainer"}>
          {/* {size.width >= 768 && <ToggleDrawerButton />} */}

          <div className="w-full mt-5">
            {size.width >= 768 && children}
            {size.width < 768 && screenNotSupported}
          </div>
        </main>
      </div>
    </div>
  );
};

export default POSLayout;
