import { useGetActiveLocation } from "hooks/useGetActiveLocation";
import React from "react";

const SingleOrderType = ({
  onChanged,
  textLg2,
  value,
  isChecked,
  totalCartPrice,
}) => {
  const { currency } = useGetActiveLocation();
  // const dispatch = useDispatch();
  return (
    <div
      onClick={() => {
        // dispatch(changeActiveService(value));
        onChanged(value);
      }}
      className={
        isChecked
          ? "w-full rounded-md p-5 flex flex-col items-center justify-center border border-primary text-xl cursor-pointer"
          : "w-full border border-gray-200 rounded-md p-5 flex flex-col items-center justify-center text-xl cursor-pointer"
      }
    >
      <p
        className={
          isChecked
            ? "text-primary font-semibold font-Inter capitalize pb-1"
            : "text-textColor font-medium font-Inter capitalize pb-1"
        }
      >
        {value}
      </p>
      <p
        className={
          isChecked
            ? "text-primary font-semibold font-Inter capitalize pb-1"
            : "text-placeholder font-medium font-Inter capitalize pb-1"
        }
      >
        {currency} {totalCartPrice}
      </p>

      <p
        className={
          isChecked
            ? "text-primary font-semibold font-Inter capitalize "
            : "text-textColor font-semibold font-Inter capitalize "
        }
      >
        {textLg2}
      </p>
    </div>
  );
};

export default SingleOrderType;
