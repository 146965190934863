import IconButton from "components/IconButton/IconButton";
import Loader from "components/Loader/Loader";
import ErrorImg from "assets/img/errorOne.webp";
// import { useMemo } from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchZoneTableDetail } from "redux/zoneTable";
import { fetchZones, addSelectedZone } from "redux/zone";
import { useNavigate } from "../../../../../node_modules/react-router-dom/dist/index";
import PrimaryButton from "components/PrimaryButton/PrimaryButton";
import SingleZone from "./components/SingleZone";
import GlobalPadding from "components/Layout/globalContainer/GlobalPadding";

// const TABS = [
//   {
//     textLg1: "VACANT",
//     textLg2: "شاغرة",
//     value: "vacant",
//   },
//   {
//     textLg1: "RESERVED",
//     textLg2: " محجوز",
//     value: "reserved",
//   },
//   {
//     textLg1: "OCCUPIED",
//     textLg2: "محتلة",
//     value: "occupied",
//   },
//   {
//     textLg1: "DISABLED",
//     textLg2: "معاق",
//     value: "disabled",
//   },
//   {
//     textLg1: "ALL_TABLES",
//     textLg2: "كل الطاولات",
//     value: "all",
//   },
// ];

// const removeSpecialChars = (str, exp) => {
//   return str.replace(exp, "").split("-").join("");
// };

const TableAllocation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [selectedTab, setSelectedTab] = useState(TABS[0]);
  const { table: selectedTable } = useSelector((state) => state.createOrder);
  // const { selectedZone: selectedZoneName } = useSelector((state) => state.zone);
  // const { isOpen } = useSelector((state) => state.drawer);
  // const [selectedZone, setSelectedZone] = useState(selectedZoneName);

  const {
    error: zonesError,
    isLoading: zonesLoading,
    zones,
  } = useSelector((state) => state.zone);
  const {
    zoneTableDetail,
    isLoading: zoneDetailLoading,
    error: zoneDetailError,
  } = useSelector((state) => state.zoneTableDetail);
  const [activeZone, setActiveZone] = useState({});

  // console.log(zones);
  const getData = () => {
    dispatch(fetchZones());
  };
  const getTable = () => {
    dispatch(fetchZoneTableDetail({ id: activeZone?.id }));
  };

  const goToUpdatScreen = (tableNum) => {
    navigate("/floor/tables/update/" + tableNum);
  };
  // Change viewed zone
  const changeZone = (zone) => {
    setActiveZone(zone);
  };

  useEffect(() => {
    dispatch(fetchZones());
  }, [dispatch]);

  useEffect(() => {
    if (zones && zones.length > 0) {
      dispatch(addSelectedZone(Number(zones[0].id)));
      setActiveZone(zones[0]);
    }
  }, [zones, dispatch]);

  useEffect(() => {
    if (zones && zones?.length > 0 && activeZone?.id) {
      dispatch(fetchZoneTableDetail({ id: activeZone?.id }));
    }
  }, [activeZone, dispatch, zones]);

  if (zonesLoading) {
    return (
      <div className="h-screen">
        <Loader />
      </div>
    );
  } else if (zonesError) {
    return (
      <div className="w-full h-screen  flex flex-col mt-20 justify-center">
        <img
          src={ErrorImg}
          alt="Error Illustration"
          className="block w-[200px] h-[200px]"
        />
        <p className="text-3xl lg:text-4xl font-Inter font-medium text-red-300">
          Error occurred while fetching your zones
        </p>
        <div className="mt-16 w-full flex flex-row items-center justify-center">
          <PrimaryButton
            text="Try Again"
            onClicked={() => {
              getData();
            }}
          />
        </div>
      </div>
    );
  } else if (!zonesError && !zonesLoading && zones?.length === 0) {
    return (
      <div className="w-full h-[70vh] flex flex-col justify-center  ">
        <p className="pt-5 text-2xl font-Inter font-medium text-textColor">
          <span>
            No zones available. Please contact your Manager to add zones.
          </span>
        </p>
      </div>
    );
  }
  return (
    <GlobalPadding>
      <div className="w-full">
        <div className="w-full mt-10 mb-10 flex flex-row items-center justify-between">
          <div>
            <h6 className="text-3xl font-Inter font-semibold text-textColor">
              Table Allocation
            </h6>
            <p className="text-xl font-Inter font-medium pt-2 text-placeholder">
              Choose the table from the zones below
            </p>
          </div>
          <div>
            <h6 className="text-3xl font-Inter font-semibold text-textColor text-right">
              تخصيص الجدول
            </h6>
            <p className="text-xl font-Inter font-medium pt-2 text-placeholder text-right">
              اختر الجدول من المناطق أدناه.
            </p>
          </div>
        </div>
        {zones.map((item, index) => (
          <SingleZone
            zone={item}
            key={index}
            onClick={changeZone}
            zoneTableDetail={zoneTableDetail}
            zoneDetailLoading={zoneDetailLoading}
            zoneDetailError={zoneDetailError}
            getTable={getTable}
            activeZone={activeZone}
            showPlaceOrderBtn={true}
            goToUpdatScreen={goToUpdatScreen}
          />
        ))}
        <div className="w-full mt-20 flex flex-row items-center justify-end">
          <IconButton
            iconPosition="left"
            icon="bag"
            text="Select & Place Order"
            //   customClasses={classes.StartOrderButton}
            disabled={!selectedTable.isAllocated}
            onClicked={() => navigate("/orders/create/category-selection")}
          />
        </div>
      </div>
    </GlobalPadding>
  );
};

export default TableAllocation;

// <Fragment>
//   {isLoading && <Loader />}
//   {!isLoading && !tablesError && !zonesError && tables.length > 0 && (
//     <div className={classes.TableAllocation}>
//       <div className={classes.FilterTabheader}>
//         <FilterTab
//           group="orderType"
//           options={TABS}
//           selectedOption={selectedTab}
//           onChangeFilter={onChangeFilter}
//         />
//         {/* Drop down here */}
//         {!zonesError && zones.length > 0 && (
//           <div className={classes.SelectBox}>
//             <Icon name="chevron-down" />
//             <select
//               onChange={(e) => {
//                 dispatch(addSelectedZone(Number(e.target.value)));
//               }}
//               className={classes.SelectInput}
//               defaultValue={selectedZone}
//             >
//               <option disabled>Select Zones</option>
//               {zones.map((zone) => {
//                 return (
//                   <option key={zone.name} value={zone.id}>
//                     {zone.name}
//                   </option>
//                 );
//               })}
//             </select>
//           </div>
//         )}
//       </div>
//       <ul className={classes.Tables}>
//         {filterTables().map((table, index) => (
//           <TableCard
//             key={index}
//             id={table.id}
//             tableNum={table.table_number}
//             status={table.order_status}
//             orderNum={table.order_no}
//             size={table.no_of_seats}
//             shape={table.shape}
//             isDisabled={table.status === "occupied"}
//             isSelected={selectedTable.id === table.id}
//             onClicked={onSelectTableHandler}
//           />
//         ))}
//       </ul>
//       <div
//         style={{ left: `${!isOpen ? "27.7rem" : "8.6rem"}` }}
//         className={classes.ActionBar}
//       >
//         <div className={classes.ActionInner}>

//           <IconButton
//             iconPosition="left"
//             icon="bag"
//             text="Select & Place Order"
//             customClasses={classes.StartOrderButton}
//             disabled={!selectedTable.isAllocated}
//             onClicked={() => navigate("/orders/create/category-selection")}
//           />
//         </div>
//       </div>
//     </div>
//   )}
//   {errorEl}
//   {!isLoading &&
//     !tablesError &&
//     !zonesError &&
//     filterTables().length === 0 && (
//       <p className={classes.NoTables}>No tables available.</p>
//     )}
// </Fragment>
