import { Fragment } from "react";
import { Outlet } from "../../../../node_modules/react-router-dom/dist/index";
// import classes from "./CreateOrdersPage.module.scss";

const CreateOrdersPage = () => {
  return (
    <Fragment>
      <Outlet />
    </Fragment>
  );
};

export default CreateOrdersPage;
