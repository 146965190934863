import React, { useState } from "react";
// App Imports
import Modal from "components/Modal/Modal";
import classes from "./CancelOrder.module.scss";

const CancelOrderModal = ({
  showModal,
  toggleModal,
  selectedChannel = {},
  onProceed = () => {},
  onFocus = () => {},
  type = "text",
  label = "",
  placeholder,
  value,
  onChange,
  error = false,
  errorText = "",
}) => {
  const id = label + Math.random();
  const [focused, setFocused] = useState(false);
  return (
    <Modal
      show={showModal}
      onClickOutside={toggleModal}
      customClasses={classes.CustomModal}
    >
      <div className={classes.ModalHeader}>
        <p>Reason for cancelling order</p>
        <p>سبب إلغاء الطلب</p>
      </div>
      {/* Inputs */}
      <div className={classes.content}>
        <div
          className={
            focused
              ? "w-full h-[30rem] rounded-lg border border-primary"
              : "w-full h-[30rem] rounded-lg border border-gray-300"
          }
        >
          <label htmlFor={id}>{label}</label>
          <input
            className={classes.inputField}
            type={type}
            id={id}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            placeholder={placeholder}
            required={true}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            autoFocus={true}
          />
        </div>
        {error && (
          <p className="text-base text-red-600 pt-2 font-Inter font-bold">
            {errorText}
          </p>
        )}
        {/* Buttons */}
        <div className="w-full mt-20 flex flex-row  justify-end ">
          <div className="w-1/2 flex flex-row items-center justify-end justify-self-end">
            {/* Cancel btn */}
            <button
              tabIndex="0"
              onClick={toggleModal}
              className="py-4 w-[45%] flex-row items-center justify-center cursor-pointer"
            >
              <span className="text-[#1F2937] lg:text-xl text-base text-center">
                Cancel <span>يلغي</span>
              </span>
            </button>
            {/* Cancel Order btn */}
            <button
              tabIndex="0"
              onClick={onProceed}
              className="flex-row w-[45%] items-center justify-center py-4 bg-[#F3781F] rounded cursor-pointer"
            >
              <span className="text-white lg:text-xl text-base text-center">
                Save <span>يحفظ</span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CancelOrderModal;
