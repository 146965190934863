import React, { useEffect, useState } from "react";
import Modal from "components/Modal/Modal";
import classes from "./CheckoutReceiptChangeModal.module.scss";
import KeyPadInterface from "./components/KeyPadInterface";
import OrderChangeInterface from "./components/OrderChangeInterface";
import { toast } from "react-toastify";
import { useGetActiveLocation } from "hooks/useGetActiveLocation";

const CheckoutReceiptChangeModal = ({
  showModal,
  toggleModal,
  orderTotal,
  completeOrder,
  changeUserAmount,
  amount,
}) => {
  // const [amount, changeUserAmount] = useState(0);
  const [returnChange, setReturnChange] = useState(0.0);
  const { decimal_preference } = useGetActiveLocation();

  // Change the amount
  const changeAmount = (val) => {
    changeUserAmount(val);
  };
  const numberPressed = (val) => {
    const amountString = String(amount).split("");
    if (amount === 0 && val !== ".") {
      changeUserAmount(val);
    } else if (amount === 0 && val === "." && !amountString.includes(val)) {
      changeUserAmount(0);
    } else if (amount !== 0 && val === "." && !amountString.includes(val)) {
      const value = String(amount).concat(val);
      changeUserAmount(value);
    } else if (amount !== 0 && val === "." && amountString.includes(val)) {
      changeUserAmount(amount);
    } else {
      changeUserAmount((prev) => prev.concat(val));
    }
  };
  // Reset the amount
  const resetAmount = () => {
    changeUserAmount(0);
    setReturnChange(0.0);
  };

  // Delete single digit
  const deleteNumber = () => {
    if (amount === 0) {
      return;
    } else {
      const mainAmount = amount.split("");
      mainAmount.pop();
      if (mainAmount.length === 0) {
        changeUserAmount(0);
        setReturnChange(0.0);
      } else {
        changeUserAmount(mainAmount.join(""));
      }
    }
  };

  // Calculate change
  const calculateChange = () => {
    // e?.preventDefault();
    if (amount === 0) {
      setReturnChange(0.0);
      toast.warning("Please enter the cash received", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      const orderChange = Number(amount) - orderTotal;
      setReturnChange(orderChange.toFixed(decimal_preference));
    }
  };

  useEffect(() => {
    if (showModal) {
      calculateChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);

  return (
    <Modal
      show={showModal}
      onClickOutside={toggleModal}
      customClasses={classes.CustomModal}
    >
      <div className="bg-white w-full px-10 py-10 rounded-2xl ">
        <div className="flex flex-row  justify-between gap-5 h-full">
          {/* Key pads side */}
          <div className="w-1/2 h-full">
            <KeyPadInterface
              amount={amount}
              changeAmount={changeAmount}
              resetAmount={resetAmount}
              deleteNumber={deleteNumber}
              calculateChange={calculateChange}
              numberPressed={numberPressed}
            />
          </div>
          {/* Values sides */}
          <div className="w-1/2">
            <OrderChangeInterface
              orderTotal={orderTotal}
              toggleModal={toggleModal}
              returnChange={returnChange}
              amount={amount}
              completeOrder={completeOrder}
              resetAmount={resetAmount}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CheckoutReceiptChangeModal;
