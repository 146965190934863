import { useState, useEffect } from "react";
import Icon from "components/Icon/Icon";
import classes from "./TimeBox.module.scss";
import moment from "moment-timezone";
import { useGetActiveLocation } from "hooks/useGetActiveLocation";

const TimeBox = () => {
  const [time, setTime] = useState(moment().format("hh:mm, DD MMMM yyyy"));

  const { time_zone } = useGetActiveLocation();

  useEffect(() => {
    const timeZone = time_zone || "Asia/Dubai";

    const formatTime = () => {
      const currentTime = moment().tz(timeZone).format("hh:mm A, DD MMMM yyyy");
      setTime(currentTime);
    };

    formatTime();

    const interval = setInterval(formatTime, 1000);

    return () => clearInterval(interval);
  }, [time_zone]);

  return (
    <div className={classes.TimeBox}>
      <Icon name="clock" />
      <p className={classes.Time}>{time}</p>
    </div>
  );
};

export default TimeBox;
