import React from "react";

const SingleZone = ({ name, active, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={
        active?.name === name
          ? "w-full h-14 border-b border-b-gray-100 flex flex-row items-center px-5 hover:bg-gray-100 bg-gray-100  transition cursor-pointer"
          : "w-full h-14 border-b border-b-gray-100 flex flex-row items-center px-5 hover:bg-gray-100  transition cursor-pointer"
      }
    >
      <p className="font-Inter text-lg md:text-xl font-medium text-greyText">
        {name}
      </p>
    </div>
  );
};

export default SingleZone;
