import defaultImg from "assets/svg/jalebi.svg";
import classes from "./Dish.module.scss";
import { useGetActiveLocation } from "hooks/useGetActiveLocation";

const Dish = ({
  nameLg1 = "",
  nameLg2,
  price,
  stock,
  hasImage,
  img,
  onClicked,
  dish,
}) => {
  // const mainStock = Number(stock);
  const { currency, decimal_preference } = useGetActiveLocation();

  return (
    <div
      className="w-full xl:aspect-[10/3.5] lg:aspect-[10/4] md:aspect-[10/4]  border border-gray-200 rounded-md bg-gray-100 flex flex-row px-4 py-5 max-w-[300px] max-h-[90px]"
      onClick={onClicked}
      role="button"
    >
      {hasImage && (
        <img src={img} alt={nameLg1} className={classes.DishImage} />
      )}
      {!hasImage && (
        <img src={defaultImg} alt={nameLg1} className={classes.DishImage} />
      )}
      <div className="w-[90%] lg:ml-4 ml-2">
        <p className="xl:text-xl lg:text-base text-sm font-Inter font-normal text-gray-700 truncate ... leading-6 w-[90%] capitalize">
          {nameLg1}
        </p>
        <p className="text-base font-Inter font-medium text-gray-700 truncate ... leading-6 mt-2">
          {nameLg2 || " "}
        </p>
        <div className="w-full flex flex-row pt-2 ">
          <p className="lg:text-xl text-sm font-Inter text-gray-600 font-medium">
            {dish?.cal ? dish?.cal : "0.00"}
            <span className="text-xs font-Inter text-gray-700">CAL</span>
          </p>
          <p className="lg:text-xl text-sm font-Inter text-primary font-medium pl-4">
            {Number(price).toFixed(decimal_preference)}
            <span className="text-xs font-Inter text-gray-700 pl-[1px]">
              {currency}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Dish;

// <div className={classes.Dish} onClick={onClicked}>
//   {hasImage && <img src={img} alt={nameLg1} className={classes.DishImage} />}
//   {!hasImage && (
//     <img src={defaultImg} alt={nameLg1} className={classes.DishImage} />
//   )}
//   <p className={classes.DishName}>{nameLg1}</p>
//   <p className={classes.DishName}>{nameLg2 || " "}</p>
//   <p className={classes.DishPrice}>{price}</p>
//   <p className={classes.DishStock}>{mainStock.toFixed(0)} remaining</p>
//   <p className={classes.DishStock}>{mainStock.toFixed(0)} متبقٍ </p>
// </div>;
