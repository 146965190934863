import Icon from "components/Icon/Icon";
import useToggle from "hooks/useToggle";
import classes from "./FilterCategoriesDropdown.module.scss";

const FilterCategoriesDropdown = ({
  categories,
  selectedCategory,
  onChangeFilter,
}) => {
  const [showCategories, toggleDropdown] = useToggle(false);

  return (
    <div className={classes.SelectBox}>
      <div className={classes.Select}>
        <button onClick={toggleDropdown}>
          <span>{selectedCategory}</span>
          <Icon name="chevron-down" />
        </button>
        {showCategories && (
          <ul>
            {categories.map((category, i) => {
              return (
                <li key={i}>
                  <button
                    onClick={() => {
                      onChangeFilter(category.name);
                      toggleDropdown();
                    }}
                  >
                    {category.name}
                  </button>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

export default FilterCategoriesDropdown;
