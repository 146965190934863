import React from "react";
import { useSelector } from "react-redux";
import moment from "../../../node_modules/moment/moment";
import IconButton from "components/IconButton/IconButton";
import classes from "./Cart.module.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const CartHeader = ({ addServices, activeOrder }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const cart = useSelector((state) => state.cart);
  const createOrder = useSelector((state) => state.createOrder);
  return (
    <div className="w-full border-b-[0.2px] border-b-gray-200 mb-2">
      {/* Table number section */}
      <div className="w-full flex-col md:flex  md:flex-row items-center justify-between">
        {createOrder?.selectedTable?.tableNum ? (
          <h6 className="font-semibold font-Inter text-greyText text-3xl">
            T-21
          </h6>
        ) : (
          <h6 className="font-semibold font-Inter text-greyText text-3xl capitalize">
            {activeOrder}
          </h6>
        )}

        <div className=" justify-self-end">
          <p className="text-placeholder text-end">
            {moment(new Date()).format("hh:mm A")} -{" "}
            {moment(new Date()).format("DD/MM/YYYY")}
          </p>
        </div>
      </div>
      {/* Control Buttons */}
      <div className="w-full flex flex-row items-center justify-between mt-5 mb-5">
        <IconButton
          icon="edit"
          iconPosition="left"
          type="secondary"
          onClicked={() => navigate("/orders/create/allocation-steps")}
          text={t("Edit Party Size / Table")}
          customClasses={classes.EditServicesBtn}
        />
        <IconButton
          icon="plus"
          iconPosition="left"
          type="secondary"
          onClicked={addServices}
          text={t("BTN_ADD_SERVICES")}
          customClasses={classes.AddServicesBtn}
        />
      </div>
    </div>
  );
};

export default CartHeader;

//  <div className={classes.CartHeader}>
//    <p className={classes.OrderIdText}>ORDER ID</p>
//    <p className={classes.OrderIdText}>رقم التعريف الخاص بالطلب</p>
//    <IconButton
//      icon="plus"
//      iconPosition="left"
//      customClasses={classes.AddServicesBtn}
//      text={t("BTN_ADD_SERVICES")}
//      type="secondary"
//      onClicked={addServices}
//    />
//  </div>;
