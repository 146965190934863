export const useGetTenderTaxHook = ({
  tax,
  tax_type,
  tax_value,
  order_total,
}) => {
  const mainTax = Number(tax);

  const showTender = mainTax && mainTax > 0 ? true : false;

  // const getTenderFee = () => {
  //   if (mainTax && mainTax > 0) {
  //     if (mainTax && tax_type === "percentage") {
  //       return (Number(mainTax) * Number(order_total)) / 100;
  //     } else {
  //       return Number(mainTax);
  //     }
  //   } else {
  //     return 0;
  //   }
  // };
  const getTenderFee = () => {
    if (mainTax && mainTax > 0) {
      if (mainTax && tax_type === "percentage") {
        return (
          Number(tax_value) || (Number(mainTax) * Number(order_total)) / 100
        );
      } else {
        return Number(tax_value) || Number(mainTax);
      }
    } else {
      return 0;
    }
  };

  return {
    tenderFee: getTenderFee(),
    showTenderTax: showTender,
  };
};
