import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

const axiosWithLocationV2 = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_V2,
});

// For GET requests
axiosWithLocationV2.interceptors.request.use(
  (req) => {
    const token = Cookies.get("jalebi_pos_token");
    // const location = Cookies.get("jalebi_active_location_id");
    const location = JSON.parse(Cookies.get("jalebi_pos_location"));

    if (token && location) {
      req.headers = {
        Authorization: `Bearer ${token}`,
        // Location: location,
        Location: location.id,
        Accept: "application/json",
        "Content-Type": "application/json",
        Source: "POS",
      };
    }
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// For POST requests
axiosWithLocationV2.interceptors.response.use(
  (res) => {
    // Add configurations here
    if (res.status === 201) {
      // console.log("Posted Successfully");
    }
    return res;
  },
  (err) => {
    // const { manualLogout } = useContext(AuthContext);
    console.log(err.response?.status, "From Axios");
    if (err && err?.response && err.response.status === 401) {
      // manualLogout();
      toast.error("Session has expired!. Please login in Again", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      Cookies.remove("jalebi_pos_location");
      Cookies.remove("jalebi_pos_user");
      Cookies.remove("jalebi_pos_token");
      Cookies.remove("restaurant_logo");
      localStorage.removeItem("jalebi_pos_screen_lock_state");
      localStorage.removeItem("restaurant_details");
      localStorage.removeItem("jalebi_active_location_id");
      localStorage.removeItem("cashier_name");
      localStorage.removeItem("receiptOrder");
      localStorage.removeItem("staffReceipt");
      setTimeout(() => {
        window.location.replace("/login");
      }, 1000);
    }
    return Promise.reject(err);
  }
);

export default axiosWithLocationV2;
