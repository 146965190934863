import React, { useCallback, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import SingleTableStatus from "./SingleTableStatus";
import Loader from "components/Loader/Loader";
import PrimaryButton from "components/PrimaryButton/PrimaryButton";
import { tableTabStatusData } from "utillity/productionData";
import HeaderFilter from "pages/OrdersPage/AllOrdersPage/components/HeaderFilter";
import MainTableCard from "./MainTableCard";
import { setSelectedTable } from "redux/createOrder";
import { useDispatch, useSelector } from "react-redux";
import { Pagination, PaginationItem } from "@mui/material";
import IconButton from "components/IconButton/IconButton";
import { addActiveEditTable } from "redux/zoneTable";

const SingleZone = ({
  zone = {},
  onClick,
  zoneDetailError,
  zoneDetailLoading,
  zoneTableDetail,
  getTable = () => {},
  activeZone = {},
  isFloor,
  goToUpdatScreen = () => {},
  deleteZone = () => {},
}) => {
  const {
    name,
    // is_active,
    vacant_table,
    reserved_table,
    occupied_table,
    disabled_table,
    id,
  } = zone;
  const dispatch = useDispatch();
  const { table: selectedTable } = useSelector((state) => state.createOrder);
  const [page, setPage] = useState(1);
  const firstPageIndex = (page - 1) * 6;
  const lastPageIndex = firstPageIndex + 6;
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeTab, setActiveTab] = useState(tableTabStatusData[0]);
  //   Calculating the total table length for the zone
  const totalTable =
    vacant_table + reserved_table + occupied_table + disabled_table;
  const tableText = totalTable > 1 ? "tables" : "table";

  //
  const tableStatuses = [
    { vacant_table },
    { reserved_table },
    { occupied_table },
    { disabled_table },
  ];
  let detailArray = [];
  let allActiveArray = [];
  if (zoneTableDetail) {
    detailArray = [
      { vacant_table: zoneTableDetail?.vacant_table },
      { occupied_table: zoneTableDetail?.occupied_table },
      { reserved_table: zoneTableDetail?.reserved_table },
      { disabled_table: zoneTableDetail?.disabled_table },
    ];
    allActiveArray = [
      zoneTableDetail?.vacant_table,
      zoneTableDetail?.occupied_table,
      zoneTableDetail?.reserved_table,
      zoneTableDetail?.disabled_table,
    ];
  }
  //   console.log(detailArray);
  const onSelectTableHandler = (tableId) => {
    dispatch(setSelectedTable(tableId));
  };
  // Change active tab
  const onChangeFilter = useCallback((newValue) => {
    setActiveTab(newValue);
  }, []);

  //   FILTERING TABLES FOR THE ZONE
  const filteredData = () => {
    if (activeTab?.slug === "all") {
      let mainArray = [];
      allActiveArray.forEach((el) => {
        el.map((item) => {
          mainArray.push(item);
          return null;
        });
      });
      return mainArray;
    } else {
      const filter = detailArray.filter(
        (item) => Object.keys(item)[0] === activeTab?.slug
      );
      return filter[0][activeTab?.slug];
    }
  };

  const total_pages = Math.ceil(filteredData()?.length / 6);

  return (
    <div className="w-full border-t border-t-gray-400 border-b border-b-gray-400  bg-gray-200 mb-2 transition-all duration-1000 ease-in-out">
      {/* Header */}
      <div
        onClick={() => {
          setIsExpanded(!isExpanded);
          onClick(zone);
        }}
        className="w-full flex flex-row  justify-between cursor-pointer px-10 py-10"
      >
        <div className="w-[70%]">
          <p className="text-3xl font-Inter font-medium text-textColor">
            {name}
          </p>
          <div className="w-full flex flex-row items-center pt-5">
            {tableStatuses.map((item, index) => (
              <SingleTableStatus key={index} type={item} />
            ))}
          </div>
        </div>
        {/* Icon */}
        <div className="flex flex-row items-center">
          {totalTable >= 1 ? (
            <p className="text-2xl text-textColor font-Inter mr-5">
              {totalTable} {tableText}
            </p>
          ) : (
            <p className="text-2xl text-textColor font-Inter mr-5">
              No table available
            </p>
          )}
          {isExpanded ? (
            <FaChevronUp className="text-2xl text-greyText" />
          ) : (
            <FaChevronDown className="text-2xl text-greyText" />
          )}
        </div>
      </div>
      {isExpanded && (
        <div className="w-full  transition-all duration-1000 ease-in-out px-10 pb-10">
          {/* When zoneDetail is loading */}
          {zoneDetailLoading && id === activeZone?.id && (
            <div className="w-full flex flex-row items-center justify-center">
              <Loader />
            </div>
          )}
          {zoneDetailError && !zoneDetailLoading && id === activeZone?.id && (
            <div className="w-full flex flex-col items-center justify-center mt-14">
              <p className="text-xl lg:text-2xl font-Inter font-medium text-red-300">
                Error occurred while fetching the tables for this zone
              </p>
              <div className="mt-10 w-full flex flex-row items-center justify-center">
                <PrimaryButton text="Try Again" onClicked={getTable} />
              </div>
            </div>
          )}
          {/* No tables available */}
          {!zoneDetailLoading &&
            !zoneDetailError &&
            totalTable === 0 &&
            id === activeZone?.id && (
              <div className="w-full flex flex-col items-center justify-center mt-10">
                <p className="pt-5 text-xl font-Inter font-medium text-greyText text-center">
                  No table available for this zone. Please contact your Manager
                  to add a table.
                </p>
                {isFloor && (
                  <div className="w-full mt-20 flex flex-row items-center justify-end">
                    <IconButton
                      iconPosition="left"
                      icon="trash"
                      text="Delete Zone"
                      //   customClasses={classes.StartOrderButton}
                      disabled={!activeZone?.name}
                      onClicked={deleteZone}
                    />
                  </div>
                )}
              </div>
            )}
          {!zoneDetailLoading &&
            !zoneDetailError &&
            totalTable > 0 &&
            id === activeZone?.id && (
              <div className="w-full">
                <HeaderFilter
                  selectedOption={activeTab}
                  onChangeFilter={onChangeFilter}
                  data={tableTabStatusData}
                  hideButton={true}
                  style={{ width: "80%" }}
                />
                {/* When we have data to render */}
                {filteredData()?.length > 0 && (
                  <div className="w-full mt-16 grid lg:grid-cols-3 grid-cols-2 gap-5">
                    {filteredData()
                      .slice(firstPageIndex, lastPageIndex)
                      .map((table, index) => (
                        <MainTableCard
                          table={table}
                          isDisabled={table.status !== "available"}
                          isSelected={selectedTable.id === table.id}
                          onClicked={
                            isFloor
                              ? () => {
                                  dispatch(addActiveEditTable(table));
                                  goToUpdatScreen(table?.table_number);
                                }
                              : () => {
                                  onSelectTableHandler(table);
                                }
                          }
                          key={index}
                          isFloor={isFloor}
                        />
                      ))}
                  </div>
                )}
                {/* When there is no data to render */}
                {filteredData()?.length === 0 && (
                  <div className="w-full flex flex-row items-center justify-center mt-20">
                    <p className="pt-5 text-xl font-Inter font-medium text-greyText text-center">
                      No table available for {activeTab?.textLg1} option
                    </p>
                  </div>
                )}
                {/* Pagination */}
                {filteredData()?.length > 6 && (
                  <div className="flex mt-10 pb-6">
                    <div className="mx-auto">
                      {total_pages && total_pages > 1 && (
                        <p className="text-sm text-gray-400 font-semibold font-Inter text-center  my-3">
                          Page {page} of&nbsp;
                          {total_pages}
                        </p>
                      )}
                      <Pagination
                        count={total_pages}
                        page={page}
                        onChange={(event, val) => {
                          setPage(val);
                        }}
                        renderItem={(item) => (
                          <PaginationItem
                            {...item}
                            shape="rounded"
                            size="small"
                          />
                        )}
                      />
                    </div>
                  </div>
                )}
                {isFloor && (
                  <div className="w-full mt-20 flex flex-row items-center justify-end">
                    <IconButton
                      iconPosition="left"
                      icon="trash"
                      text="Delete Zone"
                      //   customClasses={classes.StartOrderButton}
                      disabled={!activeZone?.name}
                      onClicked={deleteZone}
                    />
                  </div>
                )}
              </div>
            )}
        </div>
      )}
    </div>
  );
};

export default SingleZone;
