import Icon from "components/Icon/Icon";
import MainInput from "components/mainInput/MainInput";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import Modal from "components/Modal/Modal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setCustomerDetails,
  setPartySize,
  skipPartySize,
} from "redux/createOrder";
import classes from "./PartySizeModal.module.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import styled from "styled-components";
import { useDefaultSetting } from "hooks/useDefaultSetting";
import { ResturantService } from "services/ResturantService";
import { toggleConfig } from "redux/app";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox";
import { ImSpinner2 } from "react-icons/im";
import { toast } from "react-toastify";

const PartySizeModal = ({ show, toggleModal }) => {
  const dispatch = useDispatch();
  const [size, setSize] = useState(1);
  const [customerDetail, setCustomerDetail] = useState({
    customer_name: "",
    customer_number: "",
  });
  const [makeDefault, setMakeDefault] = useState(false);
  const [loading, setLoading] = useState(false);
  const createOrder = useSelector((state) => state.createOrder);
  const { skip_customer_info } = useDefaultSetting();

  // Setting the party size if user comes from dish-selection page to edit
  useEffect(() => {
    if (createOrder && createOrder?.partySize?.value >= 1) {
      setSize(createOrder?.partySize?.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrder?.partySize?.value]);

  // const inputReference = useRef(null);

  // useEffect(() => {
  //   inputReference.current.focus();
  // }, []);

  const handlePartySize = (e) => {
    let { value } = e.target;
    if (value >= 1) {
      setSize(value);
    }
  };

  const updatePartySize = (value) => {
    if (isNaN(Number(value))) dispatch(skipPartySize());
    else {
      dispatch(setCustomerDetails(customerDetail));
      dispatch(setPartySize(value));
    }
    toggleModal();
  };

  // Default service type handler
  const checkboxChangeHandler = () => {
    setLoading(true);
    ResturantService.chooseDefaultServiceType(
      {
        type: "skip_customer_info",
        value: true,
      },
      (res) => {
        setLoading(false);
        dispatch(toggleConfig(true));
        setMakeDefault(true);
        dispatch(skipPartySize());
        toggleModal();
        toast.success(
          res?.response?.data?.message ||
            `This step has been set to skip successfully`,
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
      },
      (err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.message || `Unable to skip this as default.`,
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
      }
    );
  };

  return (
    <Modal
      show={show}
      customClasses={classes.PartySizeModal}
      onClickOutside={toggleModal}
    >
      <div className="w-full flex flex-row items-center justify-between mb-8">
        <p className="text-2xl font-Inter font-medium text-textColor">
          Choose the number of people?
        </p>
        {/* <p>أدخل عدد الضيوف؟</p> */}
      </div>
      <InputContainer className="w-full flex flex-row items-center border border-gray-300 rounded-md px-4 h-[4.5rem] mb-4">
        <Icon name="party-size" height={1.5} width={1.5} />
        <input
          aria-label="size-input"
          className="w-[90%] ml-8 outline-none h-full bg-transparent focused:outline-none text-4xl"
          value={size}
          onChange={(e) => handlePartySize(e)}
          min="1"
          type="number"
          // ref={inputReference}
        />
        <div className="w-[20px]  h-full flex flex-col justify-between py-2">
          <div
            onClick={() => setSize((oldState) => ++oldState)}
            className="rounded h-[45%] bg-[#FFE9DA] flex flex-row items-center justify-center cursor-pointer"
          >
            <MdKeyboardArrowUp className="text-primary text-3xl" />
          </div>
          <div
            onClick={() => {
              if (size > 1) setSize((oldState) => --oldState);
            }}
            className="rounded h-[45%] bg-[#FFE9DA] flex flex-row items-center justify-center cursor-pointer"
          >
            <MdKeyboardArrowDown className="text-primary text-3xl" />
          </div>
        </div>
      </InputContainer>

      {/* Customer name input */}
      <div className="w-full py-2 mb-5">
        <MainInput
          label="Customer Name (optional)"
          value={customerDetail.customer_name}
          onChange={(val) =>
            setCustomerDetail({ ...customerDetail, customer_name: val })
          }
        />
      </div>
      <div className="w-full py-1 mb-10">
        <label className="text-xl pb-5 block text-gray-400">
          Customer Number (optional)
        </label>
        <PhoneInput
          country={"ae"}
          value={customerDetail.customer_number}
          onChange={(val) =>
            setCustomerDetail({ ...customerDetail, customer_number: val })
          }
          containerClass="w-full  h-[4rem] rounded-lg"
          inputClass="w-full h-[4rem] py-[19px] px-40"
          // onChange={(phone) => this.setState({ phone })}
        />
      </div>
      <div
        onClick={() => updatePartySize(size)}
        className="w-full h-16 rounded bg-primary flex flex-row items-center justify-center font-Inter text-xl font-medium text-white cursor-pointer transition-all duration-300 hover:opacity-80"
      >
        <p>Submit</p>
      </div>
      <p
        onClick={() => updatePartySize("skip")}
        className="text-xl font-Inter text-greyText pt-10 text-center font-medium cursor-pointer"
      >
        Skip يختار
      </p>
      {(!skip_customer_info || skip_customer_info == null) && (
        <div className="flex flex-row items-center mt-4">
          <CustomCheckbox
            name="customerInfo"
            value="makeDefault"
            text="Skip this as a default"
            isChecked={makeDefault}
            onChanged={checkboxChangeHandler}
          />
          {loading && <ImSpinner2 className="animate-spin ml-2 text-primary" />}
        </div>
      )}
      {/* <div className={classes.ModalActions}>
        <button
          onClick={() => updatePartySize("skip")}
          className={classes.ModalCancelBtn}
        >
          <span>Skip</span>
          <span>يتخطى</span>
        </button>
        <button
          onClick={() => updatePartySize(size)}
          className={classes.ModalNextBtn}
        >
          <span>Select</span>
          <span>يختار</span>
        </button>
      </div> */}
    </Modal>
  );
};

export default PartySizeModal;

const InputContainer = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;
// const Input = styled.input``;
