import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosWithLocationV2 from "../config/axiosWithLocationV2";

const initialState = {
  zones: [],
  isLoading: true,
  error: null,
  selectedZone: 1,
};

export const fetchZones = createAsyncThunk(
  "zone/fetchZones",
  async (payload, thunkAPI) => {
    const response = await axiosWithLocationV2
      .get("/zone")
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        return thunkAPI.rejectWithValue(err.response.data.message);
      });
    return response;
  }
);

export const zoneSlice = createSlice({
  name: "zone",
  initialState,
  reducers: {
    addZone(state, action) {
      state.zones.push(action.payload);
    },
    addSelectedZone(state, action) {
      state.selectedZone = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchZones.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchZones.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.zones = action.payload.data;
    });
    builder.addCase(fetchZones.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export const { addZone, addSelectedZone } = zoneSlice.actions;

export default zoneSlice.reducer;
