import MainRoute from "routes/MainRoute";

function App() {
  return (
    <div className="App">
      <MainRoute />
    </div>
  );
}

export default App;
