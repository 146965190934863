export const usePermissionHooks = (permission) => {
  const userPermissions = localStorage.getItem("permissions");
  const parsedPermissions = JSON.parse(userPermissions);

  const canView = parsedPermissions
    ? parsedPermissions.names?.some((item) => item === permission)
    : false;

  return {
    canView,
    permission,
  };
};
