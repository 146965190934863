import { useSelector } from "react-redux";
import NoViewPermission from "./components/NoViewPermission";
import { ORDER_VIEW_PERMISSION } from "constants/constants";
import OrdersPage from "./MainOrdersPage";
import Loader from "components/Loader/Loader";
import { usePermissionHooks } from "hooks/usePermissionHooks";
import GlobalPadding from "components/Layout/globalContainer/GlobalPadding";

const Orders = () => {
  const { locations, isLoading } = useSelector((state) => state.location);
  // const dispatch = useDispatch();

  // const restaurantDetail = localStorage.getItem("restaurant_details");

  // Set the restaurant details
  // useEffect(() => {
  //   if (!selectedRestaurant) {
  //     dispatch(selectRestaurant(JSON.parse(restaurantDetail)));
  //   }
  // }, [selectedRestaurant, dispatch, restaurantDetail]);

  const { canView } = usePermissionHooks(ORDER_VIEW_PERMISSION);

  return (
    <GlobalPadding>
      <div className="w-full ">
        {locations && locations?.length > 0 && !isLoading && (
          <div>
            {!canView ? (
              <NoViewPermission msg="You do not have permission to view this page. Please contact your administrator." />
            ) : (
              <OrdersPage />
            )}
          </div>
        )}

        {locations && locations?.length === 0 && !isLoading && (
          <NoViewPermission msg="No outlet found. Please contact your administrator." />
        )}
        {isLoading && (
          <div className="h-screen w-full">
            <Loader />
          </div>
        )}
      </div>
    </GlobalPadding>
  );
};

export default Orders;
