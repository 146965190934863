import classes from "./LoginButton.module.scss";

const LoginButton = ({ text, type, customClasses }) => {
  let defaultType = "button";
  if (type) {
    defaultType = type;
  }

  let styleClasses = classes.LoginButton;

  if (customClasses) {
    styleClasses = [styleClasses, customClasses].join(" ");
  }

  return (
    <button type={defaultType} className={styleClasses}>
      {text}
    </button>
  );
};

export default LoginButton;
