import React from "react";

const BulkTab = () => {
  return (
    <div className="w-full mt-16 flex flex-col items-center justify-center ">
      <h6 className="text-center text-4xl font-Inter text-greyText font-bold">
        Coming Soon
      </h6>
      <p className="text-center text-2xl font-Inter text-placeholder font-bold pt-5">
        This order service will be available soon.
      </p>
    </div>
  );
};

export default BulkTab;
