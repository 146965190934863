import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  isOpen: true,
  deliverectModal: false,
};

const drawerSlice = createSlice({
  name: "drawer",
  initialState,
  reducers: {
    toggleDrawer(state) {
      state.isOpen = !current(state).isOpen;
    },
    toggleDeliverectDrawer(state) {
      state.deliverectModal = !current(state).deliverectModal;
    },
    closeDrawer(state) {
      state.isOpen = true;
    },
  },
});

export const { toggleDrawer, toggleDeliverectDrawer, closeDrawer } =
  drawerSlice.actions;
export default drawerSlice.reducer;
