import noOrderImg from "assets/img/no-orders.png";
import Loader from "components/Loader/Loader";
import PrimaryInputField from "components/PrimaryInputField/PrimaryInputField";
import { useGetActiveLocation } from "hooks/useGetActiveLocation";
// import Loader from "components/Loader/Loader";
// import useToggle from "hooks/useToggle";
import { useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import { ShiftServices } from "services/ShiftService";
import classes from "./ShiftDefaultScreen.module.scss";
import Table from "components/table/Table";
import TableHeader from "components/table/TableHeader";
import { useDispatch, useSelector } from "react-redux";
import { saveReport, clearShiftReport } from "redux/shift";
import TableBody from "components/table/TableBody";
import TableRow from "components/table/TableRow";
import TableCell from "components/table/TableCell";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { CSVLink } from "react-csv";
// import MainLoader from "components/Loader/MainLoader";
import moment from "moment";
import { SHIFT_CREATE_PERMISSION, SHIFT_END_PERMISSION } from "constants/constants";
import { usePermissionHooks } from "hooks/usePermissionHooks";
import GlobalPadding from "components/Layout/globalContainer/GlobalPadding";
import BusinessReportReceipt from "components/BusinessReportReceipt/BusinessReportReceipt";

const LIMIT = 10;

const MainShift = () => {
  const addBoxRef = useRef(null);
  const [shiftValue, setShiftValue] = useState(0);
  const [status, setStatus] = useState(false);
  const { currency } = useGetActiveLocation();
  const [loading, setLoading] = useState(false);
  // const [endShiftLoading, setEndShiftLoading] = useState(false);
  const dispatch = useDispatch();
  const { shiftReport } = useSelector((state) => state.shiftReport);
  const [filterDate, setFilterDate] = useState("");

  // const { selectedRestaurant } = useSelector((state) => state.restaurant);
  const { canView: canCreateShift } = usePermissionHooks(SHIFT_CREATE_PERMISSION);
  const { canView: canEndShift } = usePermissionHooks(SHIFT_END_PERMISSION);

  // for pagination
  const [page, setPage] = useState(1);
  const offset = (page - 1) * LIMIT;
  const total_pages = Math.ceil(shiftReport.length / LIMIT);

  useEffect(() => {
    ShiftServices.checkShiftStatus(
      (res) => {
        if (res?.data?.data?.shift_started === 0) {
          toast.error(res.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setStatus(false);
        } else {
          setStatus(res?.data?.status);
          toast.warning(res.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      },
      (err) => {
        if (err && err?.response?.data?.message) {
          toast.error(err.response.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else
          for (const key in err.response.data.errors) {
            toast.error(err.response.data.errors[key][0], {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
      }
    );
  }, []);

  useMemo(() => {
    if (status) {
      ShiftServices.estimateShift(
        (res) => {
          setShiftValue(res?.data?.data?.estimated_amount || 0);
        },
        (err) => {
          if (err && err?.response?.data?.message) {
            toast.error(err.response.data.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else
            for (const key in err.response.data.errors) {
              toast.error(err.response.data.errors[key][0], {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }
        }
      );
    }
  }, [status]);

  const handleChange = (e) => setShiftValue(e.target.value);

  const handleShiftStart = () => {
    if (!status) {
      if (shiftValue < 0)
        toast.error("Please enter a positive value", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      else {
        setLoading(true);
        ShiftServices.startShift(
          shiftValue,
          (res) => {
            dispatch(clearShiftReport());
            setLoading(false);
            setStatus(true);
            toast.success(res?.data?.message || "Shift started successfully", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          },
          (err) => {
            setLoading(false);
            if (err && err?.response?.data?.message) {
              toast.error(err.response.data.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            } else
              for (const key in err.response.data.errors) {
                toast.error(err.response.data.errors[key][0], {
                  position: toast.POSITION.BOTTOM_RIGHT,
                });
              }
          }
        );
      }
    } else {
      setLoading(true);
      ShiftServices.endShift(
        shiftValue,
        (res) => {
          // console.log(res, "result");
          const result = res?.data?.data || {};
          
          setLoading(false);
          dispatch(saveReport({ data: result }));
          setStatus(false);
          toast.success(res?.data?.message || "Shift end successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setShiftValue(0);
        },
        (err) => {
          console.log(err?.response, "Error");
          setLoading(false);
          if (err && err?.response?.data?.message) {
            toast.error(err?.response?.data?.message ?? "Unable to end shift", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else
            for (const key in err?.response?.data?.errors) {
              toast.error(err?.response?.data?.errors[key][0] ?? "Unable to end shift", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }
        }
      );
    }
  };

  const shiftAvailable = status && shiftValue >= 0 ? true : false;

  // Transforming the shifts data
  const transformShiftReport = () => {
    // Formatting shift report
    let formattedShift = shiftReport && shiftReport.length > 0 ? [...shiftReport] : [];
    const newShift = formattedShift
      .map((item) => {
        return {
          ...item,
          No: item?.no,
          Date: item?.date,
          OrderNumber: item?.order_number,
          ItemName: item?.item_name,
          UnitPriceExcVat: item?.unit_price_exc_vat,
          Vat: item?.vat,
          QuantityConsumed: item?.quantity_consumed,
          TotalValue: item?.total_value,
        };
      })
      .map((item) => {
        delete item?.date;
        delete item?.no;
        delete item?.order_number;
        delete item?.item_name;
        delete item?.unit_price_exc_vat;
        delete item?.vat;
        delete item?.quantity_consumed;
        delete item?.total_value;
        return {
          ...item,
        };
      });
    return newShift;
  };

  const defaultScreen = (
    <div className={classes.DefaultScreen}>
      <div className={classes.GetStarted}>
        <img src={noOrderImg} alt="order pic here" />
        <p className={classes.GetStartedHeading}>
          <span>
            {" "}
            <span>{shiftAvailable ? "End Shift" : "Start Shift"}</span>
          </span>{" "}
          <span>{shiftAvailable ? "نهاية التحول" : "بدء التحول"}</span>
        </p>
        <div className={classes.GetStartedActions}>
          <p className={classes.SARtext}>{currency}</p>
          <div className="w-[68%]">
          <PrimaryInputField
            customClasses="mb-0 w-full"
            type="number"
            value={shiftValue}
            placeholder="Enter petty cash in SAR"
            onChange={handleChange}
          />
          </div>
          <div ref={addBoxRef} className={classes.AddBox}>
            {shiftAvailable ? (
              <button
                className={classes.AddBtn}
                onClick={
                  !canEndShift
                    ? () => {
                        toast.warning("You don not have permission to perform this action", {
                          position: toast.POSITION.BOTTOM_RIGHT,
                        });
                      }
                    : handleShiftStart
                }
              >
                <span>End Shift</span>
              </button>
            ) : (
              <button
                className={classes.AddBtn}
                onClick={
                  !canCreateShift
                    ? () => {
                        toast.warning("You don not have permission to perform this action", {
                          position: toast.POSITION.BOTTOM_RIGHT,
                        });
                      }
                    : handleShiftStart
                }
              >
                <span>Start Shift</span>
              </button>
            )}
          </div>
        </div>
      </div>
      {/* Report Screen */}
      <>
        {Object.keys(shiftReport).length > 0 && (
          <>
            <div className="mt-20 h-[70px] bg-white rounded-md flex items-center mx-auto py-7 mb-10 w-[70rem]">
              <div className="w-full px-10">
                <div className="mt-7">
                  <span className="text-gray-500 font-Inter text-4xl font-medium block capitalize">
                    Business Summary
                  </span>
                </div>
                <br />
                {/* <div className="hidden">
                <div className="w-[150px] h-[40px]">
                  <CSVLink
                    filename={`Shifts-Report-${moment(Date.now()).format(
                      "yyyy-MM-DD-h-mm-ss-A"
                    )}.csv`}
                    data={transformShiftReport()}
                  >
                    <button className="bg-primary text-white rounded outline-none  text-2xl font-Inter font-medium block w-full h-full">
                      <span>Download</span>
                    </button>
                  </CSVLink>
                </div>
                <div className={classes.inputDateContainer}>
                  <input
                    type="date"
                    className={classes.inputDate}
                    value={filterDate}
                    onChange={(val) => setFilterDate(val.target.value)}
                  />
                </div>
                <div className="w-full px-10 mt-10">
                  <Table>
                    <TableHeader items={[]} />
                    <TableBody>
                      {shiftReport?.slice(offset, offset + LIMIT).map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>{item?.no}</TableCell>
                          <TableCell>{item?.date}</TableCell>
                          <TableCell>{item?.order_number}</TableCell>
                          <TableCell>{item?.item_name}</TableCell>
                          <TableCell>{item?.unit_price_exc_vat}</TableCell>
                          <TableCell>{item?.vat}</TableCell>
                          <TableCell>{item?.quantity_consumed}</TableCell>
                          <TableCell>{item?.total_value}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
                <div className="w-full">
                  {shiftReport && shiftReport?.length >= 1 && (
                    <div className="flex mt-10 pb-6">
                      <div className="mx-auto">
                        {total_pages && total_pages > 1 && (
                          <p className="text-sm text-gray-400 font-semibold font-Inter text-center  my-3">
                            Page {page} of&nbsp;
                            {total_pages}
                          </p>
                        )}
                        <Pagination
                          count={total_pages}
                          page={page}
                          onChange={(event, val) => {
                            setPage(val);
                          }}
                          renderItem={(item) => (
                            <PaginationItem {...item} shape="rounded" size="small" />
                          )}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div> */}
              </div>
            </div>
            <div className="flex pb-20 justify-center">
              <BusinessReportReceipt report={shiftReport}/>
            </div>
          </>
        )}
      </>
    </div>
  );

  if (loading) {
    return (
      <div className="h-screen">
        <Loader />
      </div>
    );
  }
  return (
    <GlobalPadding>
      <div className=" w-full pb-10 h-screen">{defaultScreen}</div>
    </GlobalPadding>
  );
};

export default MainShift;
