import Backdrop from "../Backdrop/Backdrop";
import classes from "./Modal.module.scss";

const Modal = ({
  onClickOutside,
  background = "dark",
  show,
  children,
  customClasses = "",
}) => {
  let styleClasses = [classes.Modal, customClasses].join(" ");

  return show ? (
    <Backdrop onClicked={onClickOutside} type={background}>
      <div onClick={(e) => e.stopPropagation()} className={styleClasses}>
        {children}
      </div>
    </Backdrop>
  ) : null;
};

export default Modal;
