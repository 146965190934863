import { useGetActiveLocation } from "hooks/useGetActiveLocation";
import React from "react";

const SingleOrderAmount = ({
  title = "",
  amount = "",
  hideBackground = false,
  style = {},
}) => {
  const { currency } = useGetActiveLocation();
  return (
    <div
      className="w-full flex flex-row items-center justify-between"
      style={style}
    >
      <span className="text-gray-400 text-base lg:text-lg font-Inter">
        {title}
      </span>
      <div
        className={
          hideBackground
            ? "px-8 lg:px-12 py-2 flex flex-row items-center justify-center "
            : "px-8 lg:px-12 py-2 flex flex-row items-center justify-center bg-gray-100 border-dotted border border-gray-300 rounded"
        }
      >
        <span className="text-gray-500 font-Inter text-xl font-medium">
          {currency}&nbsp;{amount}
        </span>
      </div>
    </div>
  );
};

export default SingleOrderAmount;
