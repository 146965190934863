import React from "react";
import { useGetActiveLocation } from "hooks/useGetActiveLocation";
import classes from "./OrderDiscountNew.module.scss";
import { toast } from "react-toastify";

const DiscountByItem = ({
  shallowCart,
  setShallowCart,
  payableAmount,
  cartTotalAmount,
  cartTotalTax,
}) => {
  const { decimal_preference } = useGetActiveLocation();

  const itemDiscountHandler = (e, itemId, type) => {
    const updatedItems = shallowCart.cart[shallowCart.activeCart].map(
      (item) => {
        if (item.item_id === itemId) {
          if (type === "discount_value") {
            const itemPrice = (item.price + item.vat) * item.quantity;
            if (item.discount_type === "fixed") {
              if (Number(e.target.value) > itemPrice) {
                toast.error("Please enter valid discount value.", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                });
                return { ...item, [type]: 0 }; // Use dynamic property name
              }
            } else if (item.discount_type === "percentage") {
              if (Number(e.target.value) > 100) {
                toast.error("Please enter valid discount value.", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                });
                return { ...item, [type]: 0 }; // Use dynamic property name
              }
            }
          } else if (type === "discount_type") {
            const itemPrice = (item.price + item.vat) * item.quantity;
            if (e.target.value === "fixed") {
              if (item.discount_value > itemPrice) {
                toast.error("Please enter valid discount value.", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                });
                return {
                  ...item,
                  discount_value: 0,
                  discount_type: e.target.value,
                }; // Use dynamic property name
              }
            } else if (e.target.value === "percentage") {
              if (item.discount_value > 100) {
                toast.error("Please enter valid discount value.", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                });
                return {
                  ...item,
                  discount_value: 0,
                  discount_type: e.target.value,
                }; // Use dynamic property name
              }
            }
          }
          return { ...item, [type]: e.target.value ?? 0 }; // Use dynamic property name
        }
        return item;
      }
    );

    setShallowCart({
      ...shallowCart,
      cart: { ...shallowCart.cart, [shallowCart.activeCart]: updatedItems },
    });
  };

  return (
    <>
      <div className="flex justify-between mt-5">
        {/* Sub Total */}
        <div className="flex flex-col items-center justify-between mb-8">
          <p className="font-Inter text-xl font-normal text-gray-600">
            Subtotal
          </p>
          <p className="font-Inter text-xl font-semibold text-greyText">
            {cartTotalAmount.toFixed(decimal_preference)}
          </p>
        </div>
        {/* Tax */}
        <div className="flex flex-col items-center justify-between mb-8 space-y-3">
          <p className="font-Inter text-xl font-normal text-gray-600">Tax</p>
          <p className="font-Inter text-xl font-semibold text-greyText">
            {cartTotalTax?.toFixed(decimal_preference) || "N/A"}
          </p>
        </div>
        {/* Payable amount */}
        <div className="flex flex-col items-center justify-between mb-8">
          <p className="font-Inter text-xl font-normal text-gray-600">
            Payable Amount
          </p>
          <p className="font-Inter text-xl font-semibold text-greyText">
            {Number(payableAmount?.toFixed(decimal_preference)) || 0}
          </p>
        </div>
      </div>
      <div className="w-full h-[0.2px] mb-8 bg-gray-300" />
      <div className="max-h-[30rem] overflow-auto">
        <table className="table-auto w-full">
          <thead className="text-start">
            <tr className="items-start text-left">
              <th className="w-3/12">Item Name</th>
              <th className="w-1/12 pr-8">Quantity</th>
              <th className="w-2/12">Price + Tax</th>
              <th className="w-2/12">Discount</th>
              <th className="w-2/12">Type</th>
            </tr>
          </thead>
          <tbody>
            {shallowCart?.cart[shallowCart?.activeCart].map((item) => (
              <tr className="py-3 border-b-2">
                <td className="p-2">{item.item_name}</td>
                <td className="p-2">{item.quantity}</td>
                <td className="p-2">
                  {(item.price + item.vat) * item.quantity}
                </td>
                <td className="p-2">
                  <input
                    className="w-[80%] h-14 text-center border-2 rounded-lg border-gray-500 focus:outline-none"
                    min={0}
                    type="number"
                    value={item.discount_value}
                    name={`${item.item_id}-discount-value`}
                    placeholder="value"
                    onChange={(e) =>
                      itemDiscountHandler(e, item.item_id, "discount_value")
                    }
                  />
                </td>
                <td className="p-2">
                  <div className="custom-box flex">
                    <label className={`${classes.TableRadioButton} text-xl`}>
                      <input
                        onChange={(e) =>
                          itemDiscountHandler(e, item.item_id, "discount_type")
                        }
                        type="radio"
                        name={`${item.item_id}`}
                        value={"fixed"}
                        checked={item.discount_type === "fixed"}
                      />
                      <span className={classes.TableRadioButtonIcon}></span>
                      <span
                        className={classes.TableRadioButtonShapeRounded}
                      ></span>
                      Fixed
                    </label>
                    <label
                      className={`${classes.TableRadioButton} text-xl ml-6`}
                    >
                      <input
                        onChange={(e) =>
                          itemDiscountHandler(e, item.item_id, "discount_type")
                        }
                        type="radio"
                        name={`${item.item_id}`}
                        value={"percentage"}
                        checked={item.discount_type === "percentage"}
                      />
                      <span className={classes.TableRadioButtonIcon}></span>
                      <span
                        className={classes.TableRadioButtonShapeRounded}
                      ></span>
                      Percentage
                    </label>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default DiscountByItem;
