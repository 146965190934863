import { useGetActiveLocation } from "hooks/useGetActiveLocation";
import React from "react";

const SingleListItem = ({
  item,
  index,
  id,
  catId,
  name,
  price,
  size,
  quantity,
  sizes,
  translations,
}) => {
  const { decimal_preference, currency } = useGetActiveLocation();
  
  const {
    discount_type = "defaultDiscountType",
    discount_value = 0,
  } = item ?? {};

  const mainSize = sizes[0]?.size;
  const arTranslation = translations["ar"]?.name;
  const mainPrice = (Number(price) * quantity)?.toFixed(decimal_preference);
  
  const total_discount = () => {
    if (discount_type && discount_value) {
      return discount_type === "percentage"
        ? (Number(discount_value) * mainPrice) / 100
        : Number(discount_value);
    }
  };

  const discountedPrice = Number(price * quantity - total_discount())?.toFixed(
    decimal_preference
  );

  return (
    <div className="w-full flex flex-row items-center justify-between py-2 first:border-t-0 border-t-[0.2px] border-b-[0.2px] border-t-gray-200 border-b-gray-200">
      <div className="w-2/5 flex flex-row">
        <span className="font-Inter text-greyText">{index}</span>
        <div className="pl-5">
          <p className="truncate ... text-textColor font-medium md:text-xl text-base font-Inter pt-1">
            {name}
          </p>
          {arTranslation && (
            <p className=" text-greyText font-Inter text-xl">{arTranslation}</p>
          )}
          {discount_value ? (
            <p className="pt-1 text-[11px] font-medium text-green-700">
              {discount_value} {discount_type === "fixed" ? currency : "%"}{" "}
              discount
            </p>
          ) : null}
          {/* {mainSize && (
            <p className="pt-1 text-placeholder font-Inter text-base">
              {mainSize}
            </p>
          )} */}
        </div>
      </div>
      <div className=" py-2 w-[25%] flex flex-row items-center justify-between">
        <div>
          <p className="text-textColor font-medium md:text-xl text-base font-Inter pt-1">
            {total_discount() > 0 ? (
              <>
                <div className="font-bold">{discountedPrice}</div>
                <div className="line-through text-gray-400 text-[11px]">
                  {mainPrice}
                </div>
              </>
            ) : (
              <div className="font-bold">{mainPrice}</div>
            )}
          </p>
        </div>
        <div className="bg-gray-100 px-10 rounded-md w-[40%] h-16 flex flex-row items-center justify-center mt-1">
          <p className="font-bold font-Inter text-lg">{quantity}</p>
        </div>
      </div>
    </div>
  );
};

export default SingleListItem;
