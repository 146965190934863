import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import axios from "axios";
import "./i18n";
import AuthContextProvider from "./context/authContext";
import { ToastContainer } from "react-toastify";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <AuthContextProvider>
          <App />
        </AuthContextProvider>
      </Provider>
      <ToastContainer />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

/*
TODOS

1. Update the language switcher. It will be responsible for getting the translations from the api and main translation also
2. Update english to hardcoded
5. Work on the flow:
  i. Create uuid for apis
  ii. Collect orders with same uuid
  iii. Don't ask for table/party size when delivery.
  iv. Check if other than delivery exist. THen ask for the party/table size
6. Table CRUD
7. Zone CRUD
8. Local time in the time box in header


*/

/*

// Fixes
1. Toast on stock max reached

*/
