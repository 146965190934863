import React from "react";
import { FaCheck } from "react-icons/fa";

const SingleKeypad = ({
  value = "",
  icon = false,
  amount,
  resetAmount,
  changeAmount,
  calculateChange,
}) => {
  if (icon) {
    return (
      <button
        tabIndex="0"
        type="button"
        onClick={() => {
          calculateChange();
        }}
        onKeyDown={(e) => {
          console.log(e, "E");
        }}
        className="w-full border border-gray-300 flex flex-center items-center justify-center hover:border-green-300 transition-all duration-200 cursor-pointer rounded-md "
      >
        <FaCheck className="text-3xl text-gray-500" />
      </button>
    );
  }
  return (
    <button
      tabIndex="0"
      onClick={() => changeAmount(value)}
      className="w-full border border-gray-300 flex flex-center items-center justify-center hover:border-primary transition-all duration-200 h-[80px] cursor-pointer rounded-md"
    >
      <span className="text-4xl font-Inter text-center text-gray-600">
        {value}
      </span>
    </button>
  );
};

export default SingleKeypad;

// {
//   amount === INITIAL_VALUE ? (
//     <FaCheck className="text-3xl text-gray-500" />
//   ) : (
//     <FaTimes className="text-3xl text-gray-500" />
//   );
// }

//  onClick={
//         amount === INITIAL_VALUE
//           ? () => {
//               calculateChange();
//             }
//           : () => resetAmount()
//       }
