import React, { useRef } from "react";
import classes from "./BusinessReportReceipt.module.scss";
import ReportReceiptBuilder from "./components/ReportReceiptBuilder";
import { useReactToPrint } from "react-to-print";
import Icon from "components/Icon/Icon";

const BusinessReportReceipt = ({report}) => {
  const receiptRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => receiptRef.current,
  });

  return (
    <div className={classes.ReceiptBox}>
      <button
        onClick={() =>
          setTimeout(() => {
            handlePrint();
          }, 1000)
        }
        className={classes.DownloadReceipt}
      >
        <Icon name={"print"} width={1.8} height={1.8}/>{" "}
        <span>
        Print Report
        </span>
      </button>
      <div ref={receiptRef}>
        <ReportReceiptBuilder data={report || {}} />
      </div>
    </div>
  );
};

export default BusinessReportReceipt;
