import { useSelector } from "react-redux";

export const useGetActiveLocation = () => {
  const { activeLocation } = useSelector((state) => state.location);
  const activeCurrency =
    activeLocation && activeLocation.currency_code
      ? activeLocation.currency_code
      : "";

  const isTaxEnabled = activeLocation?.tax_enabled >= 1 ? true : false;
  const mainTax = isTaxEnabled ? activeLocation?.tax : 0;
  return {
    currentLocation: activeLocation,
    currency: activeCurrency,
    arCurrency: activeLocation?.currency?.translations?.ar?.currency_code ?? "",
    tax: mainTax,
    taxEnabled: isTaxEnabled,
    restaurantId: activeLocation?.restaurant_id,
    locationId: activeLocation?.id,
    notes: activeLocation && activeLocation.notes ? activeLocation?.notes : "",
    arabic_notes:
      (activeLocation &&
        activeLocation?.outletSettings?.translations?.ar?.notes) ||
      "",
    decimal_preference:
      activeLocation && activeLocation?.decimal_preference
        ? activeLocation?.decimal_preference
        : 0,
    time_zone: (activeLocation &&
      activeLocation?.outletSettings?.timezone) ||
      ""
  };
};
