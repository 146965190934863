import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../config/axiosWithLocationV2";

const initialState = {
  dishes: [],
  error: null,
  isLoading: false,
  extra: null,
  categoryId: null,
};

export const fetchCategoryDishes = createAsyncThunk(
  "dish/fetchCategoryDishes",
  async (payload, thunkAPI) => {
    const response = await axios
      .get(`/categories/items/${payload.categoryId}?page=${payload.page}`)
      .then((res) => {
        return {
          id: payload.categoryId,
          data: res.data.data?.data,
          extra: res?.data?.data?.extra,
        };
      })
      .catch((err) => {
        return thunkAPI.rejectWithValue(
          err?.response?.data?.message ||
            "Something went wrong! Please try again later"
        );
      });
    return response;
  }
);

export const fetchAllDishes = createAsyncThunk(
  "home/fetchAllDishes",
  async (payload, thunkAPI) => {
    let dishes = {};
    // for (let categoryId of payload.categories) {
    //   await axios
    //     .get("/categories/items/" + categoryId)
    //     .then((res) => {
    //       dishes[categoryId] = res.data.items;
    //     })
    //     .catch((err) => {
    //       return thunkAPI.rejectWithValue(
    //         "Something went wrong! Please try again later"
    //       );
    //     });
    // }
    // let requests = [];
    // for (let categoryId of payload.categories) {
    //   requests.push(axios.get("/categories/items/" + categoryId));
    // }

    // let data = await Promise.all(requests).then((res) => {
    //   let optData = res.map((obj) => obj.data);
    //   console.log(optData);

    //   return res;
    // });
    return dishes;
  }
);

const findItemIndex = (arr, id) => {
  return arr?.findIndex((item) => item.id === id);
};

export const dishSlice = createSlice({
  name: "dish",
  initialState,
  reducers: {
    setDishes: (state, action) => {
      state.dishes = [...action.payload];
      state.error = null;
    },
    updateDishStock: (state, action) => {
      const {
        // cat_id: catId,
        id: dishId,
        available_stock: availableStock,
        usedStock,
      } = action.payload;
      const index = findItemIndex(state.dishes, dishId);
      if (index > -1) {
        state.dishes[index] = {
          ...state.dishes[index],
          availableStock,
          usedStock: usedStock || 1,
        };
      }
      /** Saving this code here */
      // const index = findItemIndex(state.dishes[catId], dishId);
      // if (index > -1) {
      //   state.dishes[catId][index] = {
      //     ...state.dishes[catId][index],
      //     availableStock,
      //     usedStock: usedStock || 1,
      //   };
      // }
    },

    updateDishUsedStock: (state, action) => {
      // const catId = action?.payload?.catId;
      const dishId = action?.payload?.id;
      const index = findItemIndex(state.dishes, dishId);

      if (index > -1)
        state.dishes[index].usedStock -= action?.payload?.usedStock;

      /** Saving this code here */
      //  const index = findItemIndex(state.dishes[catId], dishId);

      //  if (index > -1)
      //    state.dishes[catId][index].usedStock -= action?.payload?.usedStock;
    },

    incrementDishUsedStock: (state, action) => {
      // const catId = action.payload.catId;
      const dishId = action.payload.id;
      const index = findItemIndex(state.dishes, dishId);

      if (index > -1) state.dishes[index].usedStock++;

      /** Saving this code here */

      // const index = findItemIndex(state.dishes[catId], dishId);

      // if (index > -1) state.dishes[catId][index].usedStock++;
    },

    decrementDishUsedStock: (state, action) => {
      // const catId = action.payload.catId;
      const dishId = action.payload.id;
      const index = findItemIndex(state.dishes, dishId);

      if (index > -1 && state.dishes[index].usedStock > 0)
        state.dishes[index].usedStock--;

      /** Saving this here */
      //  const index = findItemIndex(state.dishes[catId], dishId);

      //  if (index > -1 && state.dishes[catId][index].usedStock > 0)
      //    state.dishes[catId][index].usedStock--;
    },
    clearDishes: (state, action) => {
      state.dishes = null;
    },
    clearDishError: (state, action) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCategoryDishes.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCategoryDishes.fulfilled, (state, action) => {
      state.isLoading = false;

      state.dishes = action?.payload?.data;
      // if (!current(state).dishes) {
      //   state.dishes = {
      //     [action.payload.id]: action.payload.data,
      //   };
      // }
      // else {
      //   state.dishes[action.payload.id] = action.payload.data;
      // }
      state.extra = action.payload?.extra;
      state.categoryId = action.payload?.id;
    });
    builder.addCase(fetchCategoryDishes.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(fetchAllDishes.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAllDishes.fulfilled, (state, action) => {
      state.isLoading = false;
      state.dishes = action.payload;
    });
    builder.addCase(fetchAllDishes.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  setDishes,
  updateDishStock,
  incrementDishUsedStock,
  decrementDishUsedStock,
  updateDishUsedStock,
  clearDishes,
  clearDishError,
} = dishSlice.actions;

export default dishSlice.reducer;
