import { createSlice } from "@reduxjs/toolkit";
// import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import axios from "axios";

// export const fetchAllRestaurants = createAsyncThunk(
//   "restaurant/fetAllRestaurants",
//   async (payload, thunkAPI) => {
//     const response = await axios
//       .get("/all_restaurants")
//       .then((res) => {
//         return res.data.restaurants;
//       })
//       .catch((err) => {
//         return thunkAPI.rejectWithValue(
//           "Something went wrong! Please try again later"
//         );
//       });

//     return response;
//   }
// );

const initialState = {
  restaurants: null,
  selectedRestaurant: null,
  isLoading: false,
  error: null,
  screenState: null,
};

const restaurantSlice = createSlice({
  name: "restaurant",
  initialState,
  reducers: {
    selectRestaurant(state, action) {
      state.selectedRestaurant = action.payload;
    },
    logoutRestaurant(state) {
      state.restaurants = null;
      state.selectedRestaurant = null;
    },
    updateScreenState(state, action) {
      state.screenState = action.payload;
    },
    clearScreenLockState(state) {
      state.screenState = null;
    },
  },
  // extraReducers: (builder) => {
  //   builder.addCase(fetchAllRestaurants.pending, (state, action) => {
  //     state.isLoading = true;
  //   });
  //   builder.addCase(fetchAllRestaurants.fulfilled, (state, action) => {
  //     state.isLoading = false;
  //     state.restaurants = action.payload;
  //   });
  //   builder.addCase(fetchAllRestaurants.rejected, (state, action) => {
  //     state.isLoading = false;
  //     state.error = action.payload;
  //   });
  // },
});

export const {
  selectRestaurant,
  logoutRestaurant,
  updateScreenState,
  clearScreenLockState,
} = restaurantSlice.actions;
export default restaurantSlice.reducer;
