import Icon from "components/Icon/Icon";
import PrimaryButton from "components/PrimaryButton/PrimaryButton";
import PrimaryInputField from "components/PrimaryInputField/PrimaryInputField";
import { useState } from "react";
import { useSelector } from "react-redux";
import { TableServices } from "services/TableServices";
import { useNavigate } from "../../../../node_modules/react-router-dom/dist/index";
import { toast, ToastContainer } from "react-toastify";
import classes from "./UpdateTable.module.scss";
import Backdrop from "components/Backdrop/Backdrop";
import Loader from "components/Loader/Loader";
import { useDispatch } from "react-redux";
import { deleteTable, updateTable } from "redux/table";
import Modal from "components/Modal/Modal";
import useToggle from "hooks/useToggle";
import {
  OUTLET_DELETE_PERMISSION,
  OUTLET_UPDATE_PERMISSION,
} from "constants/constants";
import { usePermissionHooks } from "hooks/usePermissionHooks";
import GlobalPadding from "components/Layout/globalContainer/GlobalPadding";
import { useEffect } from "react";

const UpdateTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // let { tableNum } = useParams();
  // const { tables } = useSelector((state) => state.table);
  const { activeEditTable } = useSelector((state) => state.zoneTableDetail);
  const [updatingTableObj] = useState(activeEditTable);
  const { error: zonesError, zones } = useSelector((state) => state.zone);
  const [selectedZone, setSelectedZone] = useState(null);
  const [tableNumber, setTableNumber] = useState(updatingTableObj.table_number);
  const [seats, setSeats] = useState(updatingTableObj.no_of_seats);
  const [shape, setShape] = useState(updatingTableObj.shape);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, toggleisDeleting] = useToggle(false);

  // To determine if the logged in user can view create/add a new zone
  const { canView: canUpdateTable } = usePermissionHooks(
    OUTLET_UPDATE_PERMISSION
  );

  // To determine if the logged in user can view create/add a new zone
  const { canView: canDeleteTable } = usePermissionHooks(
    OUTLET_DELETE_PERMISSION
  );

  const tableZone = zones?.filter(
    (item) => item?.id === updatingTableObj?.zone_id
  );

  // Setting the zone for the selected table

  useEffect(() => {
    setSelectedZone(tableZone[0]?.id || zones[0].id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableZone]);

  const onProceedHandler = () => {
    setIsLoading(true);

    const table = {
      id: updatingTableObj.id,
      table_number: tableNumber,
      status: updatingTableObj.status,
      zone_id: selectedZone,
      no_of_seats: Number(seats),
      shape,
    };

    TableServices.updateTable(
      table,
      (res) => {
        toast.success("Table updated successfully.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        dispatch(updateTable(res.data.data));
        navigate("/floor");
      },
      (err) => {
        for (const key in err.response.data.errors) {
          toast.error(err.response.data.errors[key][0], {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        setIsLoading(false);
      }
    );
  };

  const deleteTableHandler = () => {
    toggleisDeleting();
    setIsLoading(true);

    TableServices.deleteTable(
      updatingTableObj.id,
      (res) => {
        toast.success("Table deleted successfully.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        dispatch(deleteTable(updatingTableObj.id));
        setIsLoading(false);
        navigate("/floor");
      },
      (err) => {
        if (err && err?.response?.data?.message) {
          toast.error(err.response.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else
          for (const key in err.response.data.errors) {
            toast.error(err.response.data.errors[key][0], {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        setIsLoading(false);
      }
    );
  };

  const tableShapeChangeHandler = (e) => {
    setShape(e.target.value);
  };

  // const verifyForm = () => {
  //   return (
  //     tableNumber.trim() !== "" &&
  //     !isNaN(seats) &&
  //     shape.trim() !== "" &&
  //     !isNaN(selectedZone)
  //   );
  // };

  return (
    <GlobalPadding>
      <div className="w-full h-screen">
        {isLoading && (
          <Backdrop type="dark">
            <Loader />
          </Backdrop>
        )}
        <Modal
          onClickOutside={toggleisDeleting}
          background="dark"
          show={isDeleting}
          customClasses={classes.CustomModal}
        >
          <Icon name="alert-circle-2" width={4.8} height={4.8} />
          <p>Delete the table</p>
          <p>
            Are you sure you want to delete this table? This action cannot be
            undone.
          </p>
          <div className={classes.ModalActions}>
            <button
              className={classes.ModalCancelBtn}
              onClick={toggleisDeleting}
            >
              Cancel
            </button>
            <button
              className={classes.ModalProceedBtn}
              onClick={deleteTableHandler}
            >
              Delete
            </button>
          </div>
        </Modal>

        <div className={classes.UpdateTable}>
          <button
            className={classes.DeleteButton}
            onClick={
              !canDeleteTable
                ? () => {
                    toast.warning(
                      "You don not have permission to perform this action",
                      {
                        position: toast.POSITION.BOTTOM_RIGHT,
                      }
                    );
                  }
                : toggleisDeleting
            }
          >
            <Icon name="trash" width={2.4} height={2.4} />
            Delete Table
          </button>
          <h1 className={classes.Heading}>Update Table</h1>

          <PrimaryInputField
            label="Table Name"
            placeholder="Name of the zone"
            value={tableNumber}
            onChange={(e) => setTableNumber(e.target.value)}
            customClasses={classes.TableNumberInput}
          />
          <div className={classes.TableShapes}>
            <label className={classes.TableRadioButton}>
              <input
                defaultChecked={updatingTableObj.shape === "round"}
                onChange={tableShapeChangeHandler}
                type="radio"
                name="table-shape"
                value="round"
              />
              <span className={classes.TableRadioButtonIcon}></span>
              <span className={classes.TableRadioButtonShapeRounded}></span>
            </label>
            <label className={classes.TableRadioButton}>
              <input
                defaultChecked={updatingTableObj.shape === "rectangle"}
                onChange={tableShapeChangeHandler}
                type="radio"
                name="table-shape"
                value="rectangle"
              />
              <span className={classes.TableRadioButtonIcon}></span>
              <span className={classes.TableRadioButtonShapeRectangle}></span>
            </label>
            <label className={classes.TableRadioButton}>
              <input
                defaultChecked={updatingTableObj.shape === "square"}
                onChange={tableShapeChangeHandler}
                type="radio"
                name="table-shape"
                value="square"
              />
              <span className={classes.TableRadioButtonIcon}></span>
              <span className={classes.TableRadioButtonShapeSquare}></span>
            </label>
            <label className={classes.TableRadioButton}>
              <input
                defaultChecked={updatingTableObj.shape === "circle"}
                onChange={tableShapeChangeHandler}
                type="radio"
                name="table-shape"
                value="circle"
              />
              <span className={classes.TableRadioButtonIcon}></span>
              <span className={classes.TableRadioButtonShapeCircle}></span>
            </label>
          </div>
          <PrimaryInputField
            type="number"
            label="How many seats do you want?"
            placeholder="Number of seats"
            value={seats}
            onChange={(e) => {
              e.target.value > 0 &&
                e.target.value < 13 &&
                setSeats(Number(e.target.value));
            }}
            customClasses={classes.SeatsInput}
          />
          {!zonesError && zones.length > 0 && (
            <div className={classes.SelectBox}>
              <Icon name="chevron-down" />
              <select
                onChange={(e) => setSelectedZone(Number(e.target.value))}
                className={classes.SelectInput}
                // defaultValue={selectedZone}
                value={selectedZone}
              >
                <option disabled>Select Zone</option>
                {zones.map((zone) => {
                  return (
                    <option key={zone.name} value={zone.id}>
                      {zone.name}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
          <div className={classes.Actions}>
            <PrimaryButton
              onClicked={() => navigate("/floor")}
              customClasses={classes.CancelBtn}
              text="Cancel"
            />
            <PrimaryButton
              onClicked={
                !canUpdateTable
                  ? () => {
                      toast.warning(
                        "You don not have permission to perform this action",
                        {
                          position: toast.POSITION.BOTTOM_RIGHT,
                        }
                      );
                    }
                  : onProceedHandler
              }
              text="Update"
              isDisabled={!canUpdateTable}
              // isDisabled={!verifyForm() || !canUpdateTable}
            />
          </div>
        </div>
        <ToastContainer />
      </div>
    </GlobalPadding>
  );
};

export default UpdateTable;
