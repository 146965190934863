import { useContext, useEffect, useState } from "react";
import CustomCheckbox from "components//CustomCheckbox/CustomCheckbox";
import CustomLink from "components//CustomLink/CustomLink";
import PrimaryInput from "components//PrimaryInput/PrimaryInput";
import LoadingScreen from "components//LoadingScreen/LoadingScreen";
import LoginButton from "components//LoginButton/LoginButton";
import Logo from "components//Logo/Logo";
import { AuthContext } from "context/authContext";
import axios from "axios";
import classes from "./LoginPage.module.scss";
// import Cookies from "js-cookie";
// import { useCookies } from "react-cookie";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { handleError } from "../../redux/locations";
import { updateScreenState } from "redux/restaurant";

const LoginPage = () => {
  const authContext = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [keepLogin, setKeepLogin] = useState(false);
  // const [cookies, setCookie] = useCookies(["email"]);
  const dispatch = useDispatch();
  // const { error } = useSelector((state) => state.location);
  useEffect(() => {
    dispatch(handleError());
  }, [dispatch]);

  const checkboxChangeHandler = () => {
    setKeepLogin((oldState) => !oldState);
  };

  const formSubmitHandler = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    await axios
      .post("/login", { email, password })
      .then((res) => {
        // console.log(res, "Res");
        localStorage.setItem(
          "permissions",
          JSON.stringify(res.data?.permissions)
        );
        setIsLoading(false);
        dispatch(updateScreenState(res?.data?.state));
        authContext.login(res?.data, res?.data?.token);
        localStorage.setItem("cashier_name", res?.data?.name);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err?.response?.data?.message || "Unable to authenticate!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  return (
    <div className={classes.LoginPage}>
      <LoadingScreen show={isLoading} />
      <form onSubmit={formSubmitHandler} className={classes.LoginForm}>
        <Logo />
        <h2 className={classes.LoginHeader}>Log in. </h2>
        <p className={classes.LoginParagraph}>
          Log in with your data that you entered during your registration.
        </p>
        <PrimaryInput
          label="Your e-mail"
          type="email"
          placeholder="vijay@jalebi.io"
          value={email}
          onChanged={(e) => setEmail(e.target.value)}
          customClasses="mb-3-2"
          required
        />
        <PrimaryInput
          label="Password"
          type="password"
          placeholder="****"
          value={password}
          onChanged={(e) => setPassword(e.target.value)}
          required
        />
        <div className="mt-1-6 mb-1-2 flex align-center justify-between">
          <CustomCheckbox
            name="login"
            value="keeplogin"
            text="Keep me logged in"
            isChecked={keepLogin}
            onChanged={checkboxChangeHandler}
          />
          <div className="text-end">
            <CustomLink link="/forget-password" text="Forgot Password?" />
          </div>
        </div>
        <LoginButton
          customClasses="mt-4-8 mb-1-6 w-full"
          type="submit"
          text="Log in"
        />
        {/* <div className={classes.NoAccountDiv}>
          <span>Dont have an account? </span>
          <CustomLink link="/signup" text="Sign up" />
        </div> */}
      </form>
      <div className={classes.SideImage}></div>
    </div>
  );
};

export default LoginPage;
