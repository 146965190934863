import classes from "./CustomCheckbox.module.scss";

const CustomCheckbox = ({
  text,
  value,
  name,
  isChecked,
  onChanged,
  customClasses,
}) => {
  let inputId = value.toLowerCase().replace(/ /g, "");

  let styleClasses = classes.CustomCheckbox;

  if (customClasses) {
    styleClasses = [styleClasses, customClasses].join(" ");
  }

  return (
    <div>
      <input
        className={styleClasses}
        id={inputId}
        type="checkbox"
        name={name}
        value={value}
        checked={isChecked}
        onChange={onChanged}
      />
      <label className="text-gray-600 font-medium" htmlFor={inputId}>
        {text}
      </label>
    </div>
  );
};

export default CustomCheckbox;
