import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosWithLocationV2 from "../config/axiosWithLocationV2";

const initialState = {
  zoneTableDetail: {},
  isLoading: false,
  error: null,
  message: "",
  activeEditTable: {},
};

export const fetchZoneTableDetail = createAsyncThunk(
  "zoneTableDetail/fetchZoneTableDetail",
  async (payload, thunkAPI) => {
    const response = await axiosWithLocationV2
      .get(`/zone/${payload.id}`)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        return thunkAPI.rejectWithValue(
          err?.response?.message ||
            "Something went wrong! Please try again later"
        );
      });
    return response;
  }
);

export const zoneTableDetailSlice = createSlice({
  name: "zoneTableDetail",
  initialState,
  reducers: {
    addZoneTableDetail(state, action) {
      state.zoneTableDetail = action.payload;
    },
    addActiveEditTable(state, action) {
      state.activeEditTable = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchZoneTableDetail.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchZoneTableDetail.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.zoneTableDetail = action.payload.data;
      state.message = action.payload.message;
    });
    builder.addCase(fetchZoneTableDetail.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export const { addZoneTableDetail, addActiveEditTable } =
  zoneTableDetailSlice.actions;

export default zoneTableDetailSlice.reducer;
