import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import axios from "axios";
import axios from "../config/axiosWithLocationV2";

const initialState = {
  categories: null,
  selectedCategory: null,
  error: null,
  isLoading: true,
  extra: null,
};

export const fetchCategories = createAsyncThunk(
  "category/fetchCategories",
  async (payload, thunkAPI) => {
    const response = await axios
      .get(`/restaurant/categories?page=${payload.page ?? 1}`)
      .then((res) => {
        return res.data?.data;
      })
      .catch((err) => {
        return thunkAPI.rejectWithValue(
          "Something went wrong! Please try again later"
        );
      });
    return response;
  }
);

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload || state.categories[0].id;
    },
    clearCategoryError: (state, action) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCategories.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCategories.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      if (action.payload.length === 0) {
        state.categories = [];
        state.extra = action.payload?.extra || null;
      } else {
        state.categories = action.payload?.data;
        state.extra = action.payload?.extra || null;
      }
    });
    builder.addCase(fetchCategories.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export const { setCategories, setSelectedCategory, clearCategoryError } =
  categorySlice.actions;

export default categorySlice.reducer;
