import React from "react";
import BeatLoader from "react-spinners/BeatLoader";

// const override: CSSProperties = {
//   display: "block",
//   margin: "0 auto",
//   borderColor: "red",
// };

const MainLoader = ({ size = 20 }) => {
  return (
    <BeatLoader
      color="#F3781F"
      size={size}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  );
};

export default MainLoader;
