import React from "react";
import Icon from "components/Icon/Icon";
import styled from "styled-components";
import { useSelector } from "react-redux";

const SingleAllocationSteps = ({
  active,
  step,
  isFirst = false,
  iconName,
  title,
  skipped,
  controlTextOne,
  skipFunc = () => {},
  controlOneFunc = () => {},
  skipText = "",
  hideDashedLine = false,
  notTable = false,
  showArrowIcon,
}) => {
  const { partySize } = useSelector((state) => state.createOrder);

  let style = "w-full flex flex-col mt-5";
  // let style = "lg:w-[50%] xl:w-[40%] w-[60%] flex flex-row mt-5";

  if (active && isFirst) {
    // eslint-disable-next-line no-self-assign
    style = style;
  } else if (active && !isFirst) {
    // eslint-disable-next-line no-self-assign
    style = style;
  } else if (!active && !isFirst) {
    // eslint-disable-next-line no-self-assign
    style = "w-full flex flex-row opacity-40 mt-5";
    // style = "lg:w-[50%] xl:w-[40%] w-[60%] flex flex-row opacity-40 mt-5";
  } else {
    // eslint-disable-next-line no-self-assign
    style = style;
  }

  return (
    <Container className={style}>
      {/* Indicator */}
      <div className="flex flex-row items-center justify-between mb-5">
        <div
          className={
            active
              ? "w-5 h-5 rounded-full border border-green-400 bg-gray-200 "
              : "w-5 h-5 rounded-full border border-primary bg-gray-200 "
          }
        />
        {!hideDashedLine && (
          <div
            style={{ width: "calc(100% - 20px)" }}
            className={
              active
                ? "border-t border-dashed border-t-green-500"
                : "border-t border-dashed border-t-gray-500"
            }
          />
        )}
      </div>
      {/* Right Side */}
      <div className="w-full ">
        <p className="text-gray-600 font-Inter font-medium text-xl">{step}</p>
        <div className="w-full h-[130px] bg-gray-200 rounded mt-5 relative px-8 flex flex-row items-center justify-between">
          <div className="w-full">
            <Icon name={iconName} height={2.2} width={2.2} />
            <div className="w-full flex flex-row items-center justify-between">
              <p className="text-3xl  font-Inter text-textColor font-medium pt-3">
                {title}
              </p>
              {showArrowIcon && (
                <Icon name="thick-arrow-right" height={2.2} width={2.2} />
              )}
            </div>
            {/* Controls */}
            <div className="flex flex-row items-center cursor-pointer mt-4">
              <p
                onClick={controlOneFunc}
                className="text-primary font-Inter font-medium text-xl mr-10"
              >
                {controlTextOne}
              </p>
              {skipText && partySize?.value === -1 && (
                <p
                  onClick={skipFunc}
                  className="text-greyText font-Inter text-xl font-medium"
                >
                  Skip for now
                </p>
              )}
            </div>
          </div>
          {/* For party size */}
          {skipped && (
            <p className="text-greyText font-Inter font-medium text-xl">
              Skipped
            </p>
          )}
          {!skipped && partySize?.value > 1 && !notTable && (
            <p className="text-greyText font-Inter font-medium text-xl">
              {partySize?.value} guests
            </p>
          )}
        </div>
      </div>
    </Container>
  );
};

export default SingleAllocationSteps;

const Container = styled.div`
  svg {
    color: #4b5563;
  }
`;
