import { Fragment } from "react";
import { Outlet } from "../../../../node_modules/react-router-dom/dist/index";

const CheckoutPage = () => {
  return (
    <Fragment>
      <Outlet />
    </Fragment>
  );
};

export default CheckoutPage;
