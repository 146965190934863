import Icon from "../Icon/Icon";
import classes from "./IconButton.module.scss";

const IconButton = ({
  icon,
  text,
  type,
  onClicked,
  iconPosition,
  customClasses,
  disabled,
}) => {
  let styleClasses = classes.IconButton;

  switch (type) {
    case "secondary":
      styleClasses = [styleClasses, classes.Secondary].join(" ");
      break;

    default:
      styleClasses = [styleClasses, classes.Primary].join(" ");
      break;
  }

  if (disabled) {
    styleClasses = [styleClasses, classes.Disabled].join(" ");
  }

  if (customClasses) {
    styleClasses = [styleClasses, customClasses].join(" ");
  }

  return (
    <button onClick={onClicked} className={styleClasses} disabled={disabled}>
      {iconPosition === "left" && <Icon name={icon} />}
      {text}
      {iconPosition === "right" && <Icon name={icon} />}
    </button>
  );
};

export default IconButton;
