import React from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { toggleDrawer } from "redux/drawer";
import Backdrop from "components/Backdrop/Backdrop";
import Icons from "components/Icon/Icon";
import Icon from "components/Icon/Icon";
import userImg from "assets/img/user.jpg";
import { useTranslation } from "react-i18next";
import classes from "./Drawer.module.scss";

const NEWLINKS = [
  { link: "/orders", text: "ORDERS", icon: "orders-bag" },
  { link: "/floor", text: "FLOOR", icon: "floor" },
  { link: "/queue", text: "QUEUE", icon: "queue" },
  { link: "/shift", text: "SHIFT", icon: "hour-glass" },
  { break: true },
  { link: "/settings", text: "SETTINGS", icon: "settings" },
];

const Drawer = ({ show }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const toggleDrawerHandler = () => {
    dispatch(toggleDrawer());
  };

  /*
  <Backdrop type="dark" onClicked={toggleDrawerHandler} />
        <button onClick={toggleDrawerHandler} className={classes.ToggleButton}>
          <Icons name="cross-2" width={2.4} height={2.4} />
        </button>
  */

  let drawer = null;
  // if (show) {
  //   drawer = (
  //     <div className={classes.Drawer}>
  //       <Backdrop type="dark" onClicked={toggleDrawerHandler} />
  //       <button onClick={toggleDrawerHandler} className={classes.ToggleButton}>
  //         <Icons name="cross-2" width={2.4} height={2.4} />
  //       </button>
  //       <nav>
  //         <ul>
  //           {LINKS.map((link) => {
  //             return (
  //               <li key={link.link}>
  //                 <NavLink
  //                   to={{ pathname: link.link }}
  //                   className={(navData) => {
  //                     return navData.isActive
  //                       ? classes.ActiveLink
  //                       : classes.Link;
  //                   }}
  //                 >
  //                   <Icon name={link.icon} />
  //                   <span className={classes.LinkText}>{link.text}</span>
  //                 </NavLink>
  //               </li>
  //             );
  //           })}
  //         </ul>
  //       </nav>
  //     </div>
  //   );
  // }
  if (show) {
    drawer = (
      <div className={classes.Drawer}>
        <Backdrop type="dark" onClicked={toggleDrawerHandler} />
        <button
          onClick={toggleDrawerHandler}
          className={classes.DrawerTogggleBtn}
        >
          <Icons name="cross-2" width={2.4} height={2.4} />
        </button>
        <div className={classes.DrawerHeader}>
          <Icon name="building" width={2.4} height={2.4} />
          <label>{t("YOU_ARE_IN_FOH")}</label>
          <a
            href={process.env.REACT_APP_BOH_URL}
            target="_blank"
            rel="noreferrer"
          >
            {t("SWITCH_TO_BOH")}
          </a>
        </div>
        <hr className={classes.Breaker} />
        <nav className={classes.DrawerNav}>
          <ul className={classes.DrawerNavList}>
            {NEWLINKS.map((link, index) => {
              if (link.break)
                return <hr key={index} className={classes.LinkBreaker} />;
              return (
                <li key={index} className={classes.DrawerNavItem}>
                  <NavLink
                    to={{ pathname: link.link }}
                    onClick={toggleDrawerHandler}
                    className={(navData) => {
                      return navData.isActive
                        ? classes.DrawerNavLinkActive
                        : classes.DrawerNavLink;
                    }}
                  >
                    <Icon name={link.icon} height={2.4} width={2.4} />
                    <span>{t(link.text)}</span>
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </nav>
        <div className={classes.UserBox}>
          <hr className={classes.Breaker} />
          <p className={classes.UserBoxLabel}>{t("LOGGED_IN_AS")}</p>
          <span className={classes.UserBoxStatus}>{t("ADMIN")}</span>
          <div className={classes.User}>
            <img className={classes.UserImg} src={userImg} alt="User Img" />
            <label className={classes.UserName}>Michale Beale</label>
            <Icon name="chevron-up" />
          </div>
        </div>
      </div>
    );
  }
  return drawer;
};

export default Drawer;
