import axiosWithLocation from "config/axiosWithLocationV2";

export const ShiftServices = {
  startShift: function (value, success, fail) {
    axiosWithLocation
      .post("/shift/start", { petty_cash: value })
      .then(success)
      .catch(fail);
  },
  checkShiftStatus: function (success, fail) {
    axiosWithLocation.get("/shift/checked-status").then(success).catch(fail);
  },
  estimateShift: function (success, fail) {
    axiosWithLocation.get("/shift/estimated-amount").then(success).catch(fail);
  },
  endShift: function (value, success, fail) {
    axiosWithLocation
      .post("/shift/end", { ending_cash_in_hand: value })
      .then(success)
      .catch(fail);
  },
};
