import Icon from "components/Icon/Icon";
import classes from "./PaymentChannelCard.module.scss";

const PaymentChannelCard = ({
  name,
  img,
  isSelected,
  onClicked,
  commission,
  id,
  tax,
  tax_type,
  tender_tax,
}) => {
  return (
    <li
      key={name}
      className={
        isSelected
          ? classes.PaymentChannelCardSelected
          : classes.PaymentChannelCard
      }
      onClick={() =>
        onClicked({ name, img, commission, id, tax, tax_type, tender_tax })
      }
    >
      {isSelected && <Icon name="tick" />}
      <img src={img} alt={name} className=" block" />
      <span>{name}</span>
    </li>
  );
};

export default PaymentChannelCard;
