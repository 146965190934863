import React from "react";
import { useTranslation } from "react-i18next";

const SingleMobileFilterSelect = ({
  item,
  active,
  onChangeFilter,
  closeDropdown,
}) => {
  const { t } = useTranslation();
  return (
    <button
      tabIndex="0"
      onClick={() => {
        closeDropdown();
        onChangeFilter(item);
      }}
      className={
        active?.value === item?.value
          ? "w-full h-16 flex flex-row items-center justify-center rounded-md cursor-pointer  transition-all duration-200 ease-in-out bg-primary mb-1 text-white"
          : "w-full h-16 flex flex-row items-center justify-center rounded-md cursor-pointer  transition-all duration-200 ease-in-out hover:bg-gray-100 mb-1 bg-gray-50"
      }
    >
      <p>{t(item?.textLg1)}</p>
      <p className="pl-4">{item?.textLg2}</p>
    </button>
  );
};

export default SingleMobileFilterSelect;
