import { Fragment } from "react";
import classes from "./Status.module.scss";

export const STATUS = {
  complete: {
    text: "Completed",
    bgColor: "#ECFDF3",
    dotColor: "#12B76A",
    textColor: "#027A48",
    trans: "مكتمل",
    value: "complete",
  },
  reserved: {
    text: "Reserved",
    bgColor: "#EFF8FF",
    dotColor: "#2E90FA",
    textColor: "#175CD3",
  },
  void: {
    text: "Void",
    bgColor: "#F2F4F7",
    dotColor: "#667085",
    textColor: "#344054",
    trans: "فارغ",
    value: "cancel",
  },
  "on-hold": {
    text: "On Hold",
    bgColor: "#FFFAEB",
    dotColor: "#F79009",
    textColor: "#B54708",
    trans: "في الانتظار",
    value: "on-hold",
  },
  draft: {
    text: "Serving",
    bgColor: "#fff5d7",
    dotColor: "#ffc300",
    textColor: "#f8ad01",
    trans: "خدمة",
    value: "draft",
    // bgColor: "#ECFDF3",
    // dotColor: "#959500",
    // textColor: "#959500",
    // bgColor: "#FFFAEB",
    // dotColor: "#F79009",
    // textColor: "#B54708",
  },
  serving: {
    text: "Serving",
    bgColor: "#fff5d7",
    dotColor: "#ffc300",
    textColor: "#f8ad01",
  },
  cancel: {
    text: "Cancel",
    bgColor: "#FFF6ED",
    dotColor: "#FB6514",
    textColor: "#C4320A",
  },
};

const Status = ({ status, customClasses }) => {
  const styleClasses = [customClasses, classes.Status].join(" ");

  return (
    <Fragment>
      {STATUS[status] && (
        <div
          style={{
            backgroundColor: STATUS[status].bgColor,
            color: STATUS[status].textColor,
          }}
          className={styleClasses}
        >
          <span style={{ backgroundColor: STATUS[status].dotColor }}></span>
          <span>{STATUS[status].text}</span>
        </div>
      )}
      {STATUS[status] === undefined && (
        <div
          style={{
            backgroundColor: "gray",
            color: "white",
          }}
          className={styleClasses}
        >
          <span style={{ backgroundColor: "gray" }}></span>
          <span>N/A</span>
        </div>
      )}
    </Fragment>
  );
};

export default Status;
