import { ZONE_VIEW_PERMISSION } from "constants/constants";
import NoViewPermission from "pages/OrdersPage/AllOrdersPage/components/NoViewPermission";
// import { permissionCheck } from "utillity/utils";
import MainFloor from "./MainFloor";
// import { useSelector } from "react-redux";
import { usePermissionHooks } from "hooks/usePermissionHooks";

const DefaultScreen = () => {
  // const { selectedRestaurant } = useSelector((state) => state.restaurant);
  // To determine if the logged in user can view the zone page
  const canViewFloor = usePermissionHooks(ZONE_VIEW_PERMISSION);

  return (
    <div className="w-full ">
      {!canViewFloor ? <NoViewPermission /> : <MainFloor />}
    </div>
  );
};

export default DefaultScreen;
