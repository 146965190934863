import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { fetchCategories, setSelectedCategory } from "redux//category";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import SearchInput from "components//SearchInput/SearchInput";
import Loader from "components//Loader/Loader";
import { AuthContext } from "context/authContext";
import { useTranslation } from "react-i18next";
// import defaultImg from "assets/img/drink.jpg";
import classes from "./CategorySelection.module.scss";
import styled from "styled-components";
import GlobalPadding from "components/Layout/globalContainer/GlobalPadding";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";

// const LIMIT = 15;
const CategorySelection = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useContext(AuthContext);
  const { categories, isLoading, error, extra } = useSelector(
    (state) => state.category
  );
  const [filterText, setFilterText] = useState("");

  // For pagination
  const [page, setPage] = useState(1);
  // const offset = (page - 1) * LIMIT;
  const total_pages = (extra && extra?.total_pages) || 1;

  useEffect(() => {
    dispatch(fetchCategories({ page }));
  }, [dispatch, page]);

  // useEffect(() => {
  //   if (isLoading && !categories) {
  //     dispatch(fetchCategories({ page }));
  //     console.log("Changibg");
  //   }
  // }, [dispatch, isLoading, categories, page]);

  const goToDishes = (id) => {
    dispatch(setSelectedCategory(id));
    navigate("/orders/create/dish-selection");
  };

  useMemo(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const getFilteredCategories = useCallback(() => {
    if (!categories) {
      return [];
    }
    if (filterText === "") {
      return categories;
    }
    const filteredCategories = categories?.filter(
      (category) =>
        category.name
          .toLocaleLowerCase()
          .indexOf(filterText.toLocaleLowerCase()) > -1
    );
    return filteredCategories;
  }, [filterText, categories]);

  const inputChangeHandler = (e) => {
    setFilterText(e.target.value);
  };

  const filteredCategories = useMemo(
    () => getFilteredCategories(),
    [getFilteredCategories]
  );

  let allCategories = null;
  let errorMessage = null;
  let noCategoryFound = null;
  let spinner = null;

  if (!error && !isLoading && categories) {
    allCategories = filteredCategories
      // ?.slice(offset, offset + LIMIT)
      ?.map((category, index) => {
        return (
          <div
            role="button"
            tabIndex={index + 1}
            key={category.name}
            onClick={() => goToDishes(category.id)}
            className={`w-full flex flex-col items-center justify-center rounded-sm text-gray-800 font-semibold font-Inter lg:text-lg text-base  min-h-[100px] max-h-[110px] aspect-[10/4] px-5 text-center capitalize cursor-pointer border border-gray-300 ${classes.categoryContainer}`}
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                goToDishes(category.id);
              }
            }}
            // style={getBgImg(category)}
          >
            <h3>{category.name}</h3>
            <h3>{category?.translations?.ar?.name}</h3>
            <h3>{category.arabicText}</h3>
          </div>
        );
      });
  }

  if (error && !isLoading) {
    errorMessage = <h3>{t("NOTF_NO_CATEGORIES")}.</h3>;
  }

  if (!error && !isLoading && categories && filteredCategories?.length === 0) {
    noCategoryFound = (
      <h2 className={classes.NoCategoryFound}>{t("NOTF_NO_CATEGORY")}.</h2>
    );
  }

  if (isLoading) {
    spinner = (
      <div className={classes.LoaderContainer}>
        <Loader />
      </div>
    );
  }

  return (
    <GlobalPadding>
      <div className="w-full">
        <div className={classes.CategoriesHeader}>
          <h2>{t("CATEGORIES")}</h2>
          <h2>فئات</h2>
          <div className={classes.Input}>
            <SearchInput onChanged={inputChangeHandler} />
          </div>
        </div>
        {errorMessage}
        <CategoryContainer className="w-full grid grid-cols-4 lg:grid-cols-4 xl:grid-cols-5   gap-5 mt-10">
          {!token && <h3>{t("NOTF_NO_ACCESS")}!</h3>}
          {allCategories}
          {noCategoryFound}

          {spinner}
        </CategoryContainer>
        {/* Pagination */}
        <div className="w-full">
          {!isLoading && categories && categories?.length >= 1 && (
            <div className="flex mt-10 pb-6">
              <div className="mx-auto">
                {total_pages && total_pages > 1 && (
                  <p className="text-sm text-gray-400 font-semibold font-Inter text-center  my-3">
                    Page {page} of&nbsp;
                    {total_pages}
                  </p>
                )}
                <Pagination
                  count={total_pages}
                  page={page}
                  onChange={(event, val) => {
                    setPage(val);
                  }}
                  renderItem={(item) => (
                    <PaginationItem {...item} shape="rounded" size="small" />
                  )}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </GlobalPadding>
  );
};

export default CategorySelection;

const CategoryContainer = styled.div`
  @media (min-width: 2500px) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
`;
