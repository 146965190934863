import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// import useCookies from "../../node_modules/react-cookie/cjs/useCookies";
// import { useCookies } from "react-cookie";

import { clearScreenLockState, logoutRestaurant, selectRestaurant } from "../redux/restaurant";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

export const AuthContext = React.createContext({});

const AuthContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [lockUserScreen, setLockUserScreen] = useState(false);
  const [logoutLoading, setLogoutLoading] = useState(false);
  const [restaurantLogo, setRestaurantLogo] = useState(null);
  const [token, setToken] = useState(null);
  const [logginChecked, setLogginChecked] = useState(false);
  // const [cookies, setCookie, removeCookie] = useCookies();
  const [deleteCookie, setDeleteCookie] = useState(false);
  const { t } = useTranslation();

  // console.log("Ouside: ", window.location.pathname);

  const logout = () => {
    const token = Cookies.get("jalebi_pos_token");
    setLogoutLoading(true);
    // navigate("/login");
    fetch(`${process.env.REACT_APP_API_URL}/logout`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    })
      .then(() => {
        toast.success("User log out success", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setLogoutLoading(false);
        setToken(null);
        setLoggedInUser(null);
        Cookies.remove("jalebi_pos_location");
        Cookies.remove("jalebi_pos_user");
        Cookies.remove("jalebi_pos_token");
        Cookies.remove("restaurant_logo");
        localStorage.removeItem("jalebi_pos_screen_lock_state");
        localStorage.removeItem("restaurant_details");
        localStorage.removeItem("jalebi_active_location_id");
        localStorage.removeItem("cashier_name");
        localStorage.removeItem("shiftStatus");
        dispatch(logoutRestaurant());
        dispatch(clearScreenLockState());
        // document.cookie = "jalebi_pos_user" + ";max-age=0";
        // document.cookie = "jalebi_pos_token" + ";max-age=0";
        // document.cookie = "jalebi_pos_location" + ";max-age=0";
        // document.cookie = "restaurant_logo" + ";max-age=0";
        navigate("/login");
        setDeleteCookie(true);
      })
      .catch((err) => {
        setLogoutLoading(false);
        navigate("/login");
        setToken(null);
        setLoggedInUser(null);
        Cookies.remove("jalebi_pos_location");
        Cookies.remove("jalebi_pos_user");
        Cookies.remove("jalebi_pos_token");
        Cookies.remove("restaurant_logo");
        Cookies.remove("jalebi_pos_screen_lock_state");
        Cookies.remove("jalebi_active_location_id");
        localStorage.removeItem("receiptOrder");
        localStorage.removeItem("staffReceipt");
        setTimeout(() => {
          Cookies.remove("jalebi_pos_screen_lock_state");
        }, 300);
        localStorage.removeItem("shiftStatus");
        setDeleteCookie(true);
        dispatch(logoutRestaurant());
        dispatch(clearScreenLockState());
      });
  };

  const clearCookies = () => {
    Cookies.remove("jalebi_pos_location");
    Cookies.remove("jalebi_pos_user");
    Cookies.remove("jalebi_pos_token");
    Cookies.remove("restaurant_logo");
    localStorage.removeItem("jalebi_pos_screen_lock_state");
    localStorage.removeItem("restaurant_details");
    localStorage.removeItem("jalebi_active_location_id");
    localStorage.removeItem("cashier_name");
    localStorage.removeItem("receiptOrder");
    localStorage.removeItem("staffReceipt");
    localStorage.removeItem("shiftStatus");
    // document.cookie = "jalebi_pos_user" + ";max-age=0";
    // document.cookie = "jalebi_pos_token" + ";max-age=0";
    // document.cookie = "restaurant_pos_logo" + ";max-age=0";
  };
  const manualLogout = () => {
    navigate("/login");
    setToken(null);
    setLoggedInUser(null);
    Cookies.remove("jalebi_pos_location");
    Cookies.remove("jalebi_pos_user");
    Cookies.remove("jalebi_pos_token");
    Cookies.remove("restaurant_logo");
    localStorage.removeItem("jalebi_pos_screen_lock_state");
    localStorage.removeItem("restaurant_details");
    localStorage.removeItem("jalebi_active_location_id");
    localStorage.removeItem("cashier_name");
    localStorage.removeItem("receiptOrder");
    localStorage.removeItem("staffReceipt");
    localStorage.removeItem("shiftStatus");
    setDeleteCookie(true);
    dispatch(logoutRestaurant());
    dispatch(clearScreenLockState());
  };

  const login = (user, userToken) => {
    clearCookies();
    localStorage.setItem("jalebi_pos_screen_lock_state", user?.is_locked);

    axios
      .get("/restaurant", { headers: { Authorization: `Bearer ${userToken}` } })
      .then((res) => {
        let restaurantDetails = {
          ...user,
          ...res?.data?.data?.data,
          restaurant_id: res?.data?.data?.data?.id,
          cashier_name: user?.name,
        };
        // console.log(res, "Res Details");
        let restaurantLogo = res?.data?.data?.data?.logo;
        setLoggedInUser(restaurantDetails);
        setRestaurantLogo(restaurantLogo);
        setLockUserScreen(user?.is_locked);
        setToken(userToken);
        dispatch(selectRestaurant(restaurantDetails));
        localStorage.setItem("restaurant_details", JSON.stringify(restaurantDetails));
        Cookies.set("jalebi_pos_token", userToken);
        Cookies.set("jalebi_pos_user", JSON.stringify(restaurantDetails));
        Cookies.set("restaurant_logo", restaurantLogo);
        // window.location.reload()
      })
      .catch(() => {
        toast.error(t("NOTF_LOGIN_EXPIRED"), {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });
        manualLogout();
      });
  };

  useEffect(() => {
    if (!deleteCookie) {
      const user = Cookies.get("jalebi_pos_user");
      const local_user = localStorage.getItem("restaurant_details");

      const token = Cookies.get("jalebi_pos_token");
      const restaurantLogo = Cookies.get("restaurant_logo");
      if ((user || local_user) && token) {
        // console.log({ user, token });
        setLoggedInUser(user ?? local_user);
        setToken(token);
        setRestaurantLogo(restaurantLogo);
        // setToken(token);
        // setToken(token);
      }
      setLogginChecked(true);
    }
  }, [deleteCookie]);
  useMemo(() => {
    if (deleteCookie) {
      clearCookies();
      window.location.reload();
    }
  }, [deleteCookie]);

  return (
    <AuthContext.Provider
      value={{
        loggedInUser,
        token,
        login,
        logout,
        logginChecked,
        restaurantLogo,
        clearCookies,
        logoutLoading,
        manualLogout,
        lockUserScreen,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;

//   const token = Cookies.get("jalebi_token");
//   document.cookie = "jalebi_user" + ";max-age=0";
//   document.cookie = "jalebi_token" + ";max-age=0";
//   document.cookie = "restaurant_logo" + ";max-age=0";
//   fetch(`${process.env.REACT_APP_API_URL}/logout`, {
//     method: "GET",
//     headers: {
//       Authorization: `Bearer ${token}`,
//       Accept: "application/json",
//     },
//   })
//     .then(() => {
//       toast.success("User log out success", {
//         position: toast.POSITION.BOTTOM_RIGHT,
//       });
//     })
//     .catch((err) => {
//       toast.error("User log out incomplete", {
//         position: toast.POSITION.BOTTOM_RIGHT,
//       });
//     });
//   setToken(null);
//   setLoggedInUser(null);
//   removeCookie("jalebi_location", {
//     path: "/orders",
//     domain: ".getjalebi.com",
//   });
//   removeCookie("jalebi_user", null, {
//     path: "/",
//     domain: ".getjalebi.com",
//   });
//   removeCookie("jalebi_token", null, {
//     path: "/",
//     domain: ".getjalebi.com",
//   });
//   removeCookie("jalebi_location", null, {
//     path: "/",
//     domain: ".getjalebi.com",
//   });
//   removeCookie("restaurant_logo", null, {
//     path: "/",
//     domain: ".getjalebi.com",
//   });
//   dispatch(logoutRestaurant());
//   document.cookie = "jalebi_user" + ";max-age=0";
//   document.cookie = "jalebi_token" + ";max-age=0";
//   document.cookie = "restaurant_logo" + ";max-age=0";

//   setDeleteCookie(true);
// };

// const clearCookies = () => {
//   removeCookie("jalebi_location", {
//     path: "/orders",
//     domain: ".getjalebi.com",
//   });
//   removeCookie("jalebi_location", {
//     path: "/",
//     domain: ".getjalebi.com",
//   });
//   removeCookie("jalebi_user", {
//     path: "/",
//     domain: ".getjalebi.com",
//   });
//   removeCookie("jalebi_token", {
//     path: "/",
//     domain: ".getjalebi.com",
//   });
//   removeCookie("jalebi_user", null, {
//     path: "/",
//     domain: ".getjalebi.com",
//   });
//   removeCookie("jalebi_token", null, {
//     path: "/",
//     domain: ".getjalebi.com",
//   });
//   removeCookie("jalebi_location", null, {
//     path: "/",
//     domain: ".getjalebi.com",
//   });
//   removeCookie("restaurant_logo", null, {
//     path: "/",
//     domain: ".getjalebi.com",
//   });
//   document.cookie = "jalebi_user" + ";max-age=0";
//   document.cookie = "jalebi_token" + ";max-age=0";
//   document.cookie = "restaurant_logo" + ";max-age=0";
// };

// const logout = () => {
//   // const token = Cookies.get("jalebi_token");
//   document.cookie = "jalebi_user" + ";max-age=0";
//   document.cookie = "jalebi_token" + ";max-age=0";
//   document.cookie = "restaurant_logo" + ";max-age=0";
//   fetch(`${process.env.REACT_APP_API_URL}/logout`, {
//     method: "GET",
//     headers: {
//       Authorization: `Bearer ${token}`,
//       Accept: "application/json",
//     },
//   })
//     .then(() => {
//       toast.success("User log out success", {
//         position: toast.POSITION.BOTTOM_RIGHT,
//       });
//     })
//     .catch((err) => {
//       toast.error("User log out incomplete", {
//         position: toast.POSITION.BOTTOM_RIGHT,
//       });
//     });
//   setToken(null);
//   setLoggedInUser(null);
//   removeCookie("jalebi_location", {
//     path: "/orders",
//     domain: ".getjalebi.com",
//   });
//   removeCookie("jalebi_user", null, {
//     path: "/",
//     domain: ".getjalebi.com",
//   });
//   removeCookie("jalebi_token", null, {
//     path: "/",
//     domain: ".getjalebi.com",
//   });
//   removeCookie("jalebi_location", null, {
//     path: "/",
//     domain: ".getjalebi.com",
//   });
//   removeCookie("restaurant_logo", null, {
//     path: "/",
//     domain: ".getjalebi.com",
//   });
//   dispatch(logoutRestaurant());
//   document.cookie = "jalebi_user" + ";max-age=0";
//   document.cookie = "jalebi_token" + ";max-age=0";
//   document.cookie = "restaurant_logo" + ";max-age=0";

//   setDeleteCookie(true);
// };

// const login = (user, userToken) => {
//   removeCookie("jalebi_location", {
//     path: "/orders",
//     domain: ".getjalebi.com",
//   });
//   removeCookie("jalebi_user", null, {
//     path: "/",
//     domain: ".getjalebi.com",
//   });
//   removeCookie("jalebi_token", null, {
//     path: "/",
//     domain: ".getjalebi.com",
//   });
//   removeCookie("jalebi_location", null, {
//     path: "/",
//     domain: ".getjalebi.com",
//   });
//   removeCookie("restaurant_logo", null, {
//     path: "/",
//     domain: ".getjalebi.com",
//   });
//   axios
//     .get("/restaurant", { headers: { Authorization: `Bearer ${userToken}` } })
//     .then((res) => {
//       let restaurantDetails = {
//         ...user,
//         ...res?.data?.data?.data,
//         restaurant_id: res?.data?.data?.data?.id,
//       };
//       let restaurantLogo = res?.data?.data?.data?.logo;
//       setCookie("jalebi_token", userToken);
//       setCookie("jalebi_user", JSON.stringify(restaurantDetails));
//       setCookie("restaurant_logo", restaurantLogo);

//       setLoggedInUser(restaurantDetails);
//       setRestaurantLogo(restaurantLogo);
//       setToken(userToken);
//       dispatch(selectRestaurant(restaurantDetails));
//     })
//     .catch(() => {
//       toast.error(t("NOTF_LOGIN_EXPIRED"), {
//         position: toast.POSITION.BOTTOM_RIGHT,
//       });
//       logout();
//     });
// };

// useEffect(() => {
//   if (!deleteCookie) {
//     const user = cookies["jalebi_user"];
//     const token = cookies["jalebi_token"];
//     const restaurantLogo = cookies["restaurant_logo"];
//     if (user && token) {
//       // console.log({ user, token });
//       setLoggedInUser(user);
//       setToken(token);
//       setRestaurantLogo(restaurantLogo);
//       setToken(token);
//       // setToken(token);
//     }
//     setLogginChecked(true);
//   }
// }, [cookies, deleteCookie]);
