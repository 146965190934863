import React from "react";
import SingleServices from "../components/SingleServices";
import { updateString } from "utillity/utils";

const InstantTab = ({
  services = [],
  onClicked,
  orderTypes,
  previousLySelectedTypes = [],
  isAddingService,
  servicesTranslations,
}) => {
  const getGridCol = () => {
    if (services.length >= 3) {
      return 3;
    } else if (services.length >= 2 || services.length >= 1) {
      return 2;
    } else {
      return 2;
    }
  };

  return (
    <div className={`grid grid-cols-2 lg:grid-cols-${getGridCol()} gap-5`}>
      {services.map((item, index) => (
        <SingleServices
          {...item}
          key={index}
          icon={updateString(item, " ", "-")}
          engText={item}
          arabicText={servicesTranslations.ar[item]}
          onClick={onClicked}
          isSelected={orderTypes.includes(item)}
          isLocked={isAddingService && previousLySelectedTypes.includes(item)}
        />
      ))}
    </div>
  );
};

export default InstantTab;
