import Status from "components/Status/Status";
import React from "react";
import moment from "moment";
import DefaultIMG from "../../../assets/img/talabat.png";

const DelevirectDrawerHeader = ({
  order_no,
  created_at,
  status,
  logo,
  channelName,
}) => {
  const imgUrl = logo ? logo : DefaultIMG;

  return (
    <div className="w-full pb-10">
      <div className="w-full flex flex-row justify-between">
        {/* Left side */}
        <div>
          <span> ORDER ID:</span>
          <span className="text-gray-600 font-Inter text-4xl font-medium block pt-5">
            #{order_no}
          </span>
          <div className="mt-5 font-Inter font-normal text-gray-400 text-2xl">
            <span>Time: {moment(created_at).format("h:mm:ss A")}</span>
          </div>
        </div>
        {/* Right Side */}
        <div>
          <Status status={status} />
          <div className="flex flex-row justify-end">
            <img
              src={imgUrl}
              alt={`${channelName}`}
              className="block w-[50px] h-[50px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DelevirectDrawerHeader;
