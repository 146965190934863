import React from "react";
import Icon from "../Icon/Icon";
import classes from "./SearchInput.module.scss";

const SearchInput = (props) => {
  return (
    <div className={classes.SearchInput}>
      <Icon name="magnifier" />
      <input
        type="text"
        placeholder="Search Categories"
        onChange={props.onChanged}
      />
    </div>
  );
};

export default SearchInput;
