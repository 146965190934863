import { useContext, useRef, useState } from "react";
import Icon from "components/Icon/Icon";
import classes from "./Header.module.scss";
import LanguageSwitcher from "./LanguageSwitcher/LanguageSwitcher";
import LocationSwitcher from "./LocationSwitcher/LocationSwitcher";
import TimeBox from "./TimeBox/TimeBox";
import { AuthContext } from "context/authContext";
// import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import useClickOutside from "hooks/useOutsideClick";
import { ResturantService } from "services/ResturantService";
import MainLoader from "components/Loader/MainLoader";
import { useDispatch, useSelector } from "react-redux";
import { updateScreenState } from "redux/restaurant";
import { MdRefresh } from "react-icons/md";
import { toggleConfig } from "redux/app";
import WarningNotification from "./WarningNotification/WarningNotification";

const Header = ({ customClasses, onExitPOS = () => { }, isOpen }) => {
  // const { t } = useTranslation();
  const { logout } = useContext(AuthContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();
  const { activeLocation } = useSelector((state) => state.location);
  const { info, isLoading: servicesLoading } = useSelector((state) => state.service);
  const { refetchConfig } = useSelector((state) => state.app);
  const dropDownRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [loading, setLoading] = useState(false);
  useClickOutside(dropDownRef, () => setShowDropdown(!showDropdown));

  let stylClasses = [classes.Header, customClasses].join(" ");

  const noNotification =
    location.pathname === "/orders/all" ||
    location.pathname === "/shift" ||
    location.pathname === "/login";

  const lockPosScreen = () => {
    setLoading(true);
    ResturantService.lockScreen(
      {
        restaurant_id: activeLocation?.restaurant_id,
        outlet_id: activeLocation?.id,
        state: `${location?.pathname}`,
      },
      (res) => {
        setLoading(false);
        dispatch(updateScreenState(res?.data?.data));
        localStorage.setItem("jalebi_pos_screen_lock_state", res?.data?.data?.is_locked);
        toast.success(res?.data?.message || "Screen Locked Successfully.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setShowDropdown(false);
        navigate(location?.pathname);
      },
      (err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message || "Unable to lock screen!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    );
  };

  const refreshSetting = () => {
    dispatch(toggleConfig(true));
  };

  return (
    <header className={!isOpen ? stylClasses : classes.Header_Collapsed} id={"headerContent"}>
      <LocationSwitcher />

      {info && !servicesLoading && !info?.shift_started && !noNotification && <WarningNotification />}

      <TimeBox />
      {/* <div className={classes.Notifications}>
        <button className={classes.NotificationsToggler}>
          <Icon name="bell" width={2.4} height={2.4} />
          <span>3</span>
        </button>
      </div> */}
      <LanguageSwitcher />
      <button
        className={classes.LogoutBtn}
        onClick={() => {
          setShowDropdown(true);
        }}
        type="button"
      >
        <Icon name="logout" width={2.4} height={2.4} />
        {showDropdown && (
          <DropdownWrapper
            className="absolute  -bottom-40 right-10  w-[200px] bg-white"
            ref={dropDownRef}
          >
            <button
              className="flex flex-row items-center  h-[45px] px-5 w-full hover:bg-gray-200 transition-all duration-150 ease-in-out"
              onClick={lockPosScreen}
            >
              <Icon name="padlock" width={1.8} height={1.8} />
              <span className="text-gray-600 font-Inter text-lg px-4">Lock Screen</span>
              {loading && <MainLoader size={5} />}
            </button>
            <button
              className="flex flex-row items-center  h-[45px] px-5 w-full hover:bg-gray-200 transition-all duration-150 ease-in-out"
              onClick={refreshSetting}
            >
              <MdRefresh className="text-3xl text-gray-600" />
              <span className="text-gray-600 font-Inter text-lg px-4">Refresh Settings</span>
              {refetchConfig && <MainLoader size={5} />}
            </button>
            <button
              onClick={() => {
                setShowDropdown(false);
                logout();
              }}
              className="flex flex-row items-center  h-[45px] px-5 w-full hover:bg-gray-200 transition-all duration-150 ease-in-out"
            >
              <Icon name="power" width={1.8} height={1.8} />
              <span className="text-gray-600 font-Inter text-lg pl-4">Logout</span>
            </button>
          </DropdownWrapper>
        )}

        {/* <span>{t("LOGOUT")}</span> */}
      </button>
    </header>
  );
};

export default Header;

export const DropdownWrapper = styled.div`
  z-index: 1000;
  border: 1px solid #f2f4f7;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 4px;
`;
