const WarningNotification = () => {
  return (
    <div className="ml-auto bg-yellow-200/40 text-yellow-400 p-4 text-2xl font-medium rounded-md flex gap-2 items-center">
      <svg fill="rgb(250 204 21)" width="20px" height="20px" viewBox="0 0 478.125 478.125">
        <g>
          <g>
            <g>
              <circle cx="239.904" cy="314.721" r="35.878" />
              <path
                d="M256.657,127.525h-31.9c-10.557,0-19.125,8.645-19.125,19.125v101.975c0,10.48,8.645,19.125,19.125,19.125h31.9
				c10.48,0,19.125-8.645,19.125-19.125V146.65C275.782,136.17,267.138,127.525,256.657,127.525z"
              />
              <path
                d="M239.062,0C106.947,0,0,106.947,0,239.062s106.947,239.062,239.062,239.062c132.115,0,239.062-106.947,239.062-239.062
				S371.178,0,239.062,0z M239.292,409.734c-94.171,0-170.595-76.348-170.595-170.596c0-94.248,76.347-170.595,170.595-170.595
				s170.595,76.347,170.595,170.595C409.887,333.387,333.464,409.734,239.292,409.734z"
              />
            </g>
          </g>
        </g>
      </svg>
      Order will be placed without starting shift.
    </div>
  );
};

export default WarningNotification;
