import logoImg from "assets//svg/logo.svg";
import classes from "./Logo.module.scss";

const Logo = () => {
  return (
    <div className={classes.Logo}>
      <img src={logoImg} alt="Logo" className={classes.LogoImg} />
    </div>
  );
};

export default Logo;
