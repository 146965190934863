import Icon from "components/Icon/Icon";
import classes from "./MainTableCard.module.scss";
import { singleTableStatus } from "utillity/productionData";
import { MdOutlineAddCircleOutline } from "react-icons/md";

// const STATUS = {
//   draft: {
//     textLg1: "On Hold",
//     textLg2: "في الانتظار",
//     icon: false,
//     color: "#F79009",
//   },
//   received: {
//     textLg1: "Order Received",
//     textLg2: "طلب وارد",
//     icon: false,
//     color: "#1D4ED8",
//   },
//   cancelled: {
//     textLg1: "Cancelled",
//     textLg2: "ألغيت",
//     icon: "order-cancelled",
//   },
//   complete: {
//     textLg1: "Completed",
//     textLg2: "مكتمل",
//     icon: "order-complete",
//   },
//   serving: {
//     textLg1: "Serving",
//     textLg2: "خدمة",
//     icon: false,
//     color: "#047857",
//   },
// };

const MainTableCard = ({
  onClicked,
  isSelected,
  isDisabled,
  table,
  isFloor,
}) => {
  const { id, status, no_of_seats: size, shape, table_number } = table;

  const checkForDisabled = () => {
    if (!isFloor && isDisabled) {
      return true;
    } else if (!isFloor && !isDisabled) {
      return false;
    } else {
      return false;
    }
  };

  return (
    <div
      onClick={checkForDisabled() ? () => {} : () => onClicked(id)}
      className={
        isSelected
          ? "w-full h-[270px] bg-gray-50 rounded-md cursor-pointer border border-primary"
          : checkForDisabled()
          ? "w-full h-[270px] bg-gray-50 rounded-md cursor-pointer opacity-40"
          : "w-full h-[270px] bg-gray-50 rounded-md cursor-pointer"
      }
    >
      {/* Table Icon */}
      <div
        className={classes[`TableIconSizeUpto${size}${shape}`]}
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          backgroundColor: singleTableStatus[status]?.bgColor,
          marginTop: 20,
        }}
      >
        <p></p>
        {Array(size)
          .fill(undefined)
          .map((item, index) => {
            return <span key={index}></span>;
          })}
      </div>
      {/* Other content */}
      <div className="w-full mt-8 px-5">
        <p className="text-lg text-gray-500 font-Inter font-medium text-center">
          {singleTableStatus[status]?.text}
        </p>
        <h6 className="text-2xl font-Inter font-medium text-gray-500 text-center">
          Table: {table_number}
        </h6>
        <div className="w-full mt-5">
          {/* Single Details */}
          <div className="w-full flex flex-row items-center justify-center">
            <div className="flex flex-row items-center text-gray-500 font-Inter font-regular text-lg">
              <Icon name="party-size" height={1.5} width={1.5} />
              <p className="pl-3 pr-2">{size}</p>
              <p>persons</p>
            </div>
          </div>
          {/* End of one single detail */}

          {/* Allocate button */}
          {status === "available" && !isFloor && (
            <div className="w-full flex flex-row items-center justify-center mt-5 ">
              <div className="w-[70%] lg:w-[80%] rounded bg-[#FFFCFA] flex flex-row items-center justify-center py-5">
                <MdOutlineAddCircleOutline className="text-3xl text-primary mr-2" />
                <p className="md:text-xl lg:text-base xl:text-xl text-primary font-Inter font-medium">
                  Allocate for this order
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MainTableCard;
