// import numbro from "numbro";

// import axiosWithLocationV2 from "config/axiosWithLocationV2";
// import { setActiveLocation } from "redux/locations";
// import { toast } from "react-toastify";

export const updateString = (str, exp = "", replaceWith = "") => {
  // console.log("STIRNG: ", str);
  return str.replace(exp, replaceWith);
};

export const taxPercentCalculator = (orderTotal, vat) => {
  const result = (Number(vat) / Number(orderTotal)) * 100;
  return result.toFixed(2);
};

// Formatting number
// export const numberFormat = (val) => {
//   return numbro(Number(val)).format({ thousandSeparated: true, mantissa: 3 });
// };
export const formatter = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 3,
  maximumFractionDigits: 3,
});

export const numberFormat = (value, exp) => {
  if (typeof exp === "undefined" || +exp === 0) return Math.round(value);

  value = +value;
  exp = +exp;

  if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) return NaN;

  // Shift
  value = value.toString().split("e");
  value = Math.round(+(value[0] + "e" + (value[1] ? +value[1] + exp : exp)));

  // Shift back
  value = value.toString().split("e");
  return +(value[0] + "e" + (value[1] ? +value[1] - exp : -exp));
};

export const insertItem = (arr, index, newItem) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index),
];

// Permission checker

export const permissionCheck = (permissionList = [], permission) => {
  return permissionList.some((item) => item === permission);
};

// Calculate the subtotal value of an item with tax
export const getItemSubTotalWithTax = (quantity, itemPrice, itemTax) => {
  return Number(Number(quantity) * Number(itemPrice) + Number(itemTax));
};

// Calculate the total for all item in cart with tax
export const getCartItemTotalWithTax = (cartItems) => {
  let subTotalTaxPrice = 0;
  if (cartItems) {
    for (const item of cartItems) {
      subTotalTaxPrice +=
        Number(item?.price) * Number(item?.quantity) + Number(item?.order_vat);
    }
  }
  return subTotalTaxPrice;
};
