import React from "react";
import classes from "./MainInput.module.scss";

const MainInput = ({
  onFocus = () => {},
  type = "text",
  label = "",
  placeholder,
  value,
  onChange,
  error = false,
  errorText = "",
  //   classes = "",
}) => {
  const id = label + Math.random();
  //   let mainClass = "w-full h-[5rem] rounded-lg border border-gray-300";
  //   if (classes) {
  //     mainClass = [classes, mainClass].join(" ");
  //   }
  return (
    <div className="w-full">
      <label htmlFor={id} className="text-xl pb-5 block text-gray-400">
        {label}
      </label>
      <div
        className={
          error
            ? "w-full h-[4rem] rounded-lg border border-red-600"
            : "w-full h-[4rem] rounded-lg border border-gray-300"
        }
      >
        <input
          className={classes.inputField}
          type={type}
          id={id}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          required={true}
          onFocus={onFocus}
        />
      </div>
      {error && (
        <p className="text-base text-red-600 pt-2 font-Inter font-bold">
          {errorText}
        </p>
      )}
    </div>
  );
};

export default MainInput;
