import React from "react";

const RoundedButton = ({
  style = {},
  onClick = () => {},
  enText = "",
  arText = "",
  secondaryBtn = false,
}) => {
  return (
    <div
      onClick={onClick}
      className={
        !secondaryBtn
          ? "px-5 py-4 rounded-[30px] flex flex-row items-center justify-center border border-primary  text-primary cursor-pointer"
          : "px-5 py-4 rounded-[30px] flex flex-row items-center justify-center border border-gray-400 text-gray-600 cursor-pointer"
      }
      style={style}
    >
      <p className="px-4 font-Inter font-medium capitalize">{enText}</p>
      <p className="px-4 font-Inter font-medium capitalize">{arText}</p>
    </div>
  );
};

export default RoundedButton;
