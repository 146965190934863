import Icons from "components/Icon/Icon";
import { useWindowSize } from "hooks/useWindowSize";
import { toggleDrawer } from "redux//drawer";
import classes from "./ToggleDrawerButton.module.scss";
import { useDispatch } from "react-redux";

const ToggleDrawerButton = () => {
  let size = useWindowSize();
  const dispatch = useDispatch();
  let el = null;

  const toggleDrawerHandler = () => {
    dispatch(toggleDrawer());
  };

  if (size.width < 851) {
    el = (
      <button onClick={toggleDrawerHandler} className={classes.TogglerButton}>
        <Icons name="burger" height={2.4} width={2.4} />
      </button>
    );
  }

  return el;
};

export default ToggleDrawerButton;
