import { useGetActiveLocation } from "hooks/useGetActiveLocation";
import React from "react";
import { MdOutlineBackspace } from "react-icons/md";
import SingleKeypad from "./SingleKeypad";
import { keypadData } from "utillity/productionData";
import styled from "styled-components";

const KeyPadInterface = ({
  amount,
  changeAmount,
  resetAmount,
  deleteNumber,
  calculateChange,
  numberPressed,
}) => {
  const { currency } = useGetActiveLocation();

  return (
    <div className="w-full h-full ">
      {/* Input */}
      <div className="w-full border-b pb-7 h-[50px] border-b-gray-100 flex flex-row items-center justify-between">
        {/* Input container */}
        <div className="w-[90%] flex flex-row items-center border-r border-r-gray-200 h-full">
          <div className="bg-[#F9FAFB] rounded w-[100px] py-2 flex flex-row items-center justify-center mr-4">
            <span className="font-Inter text-lg text-gray-400">{currency}</span>
          </div>
          <div className="flex flex-row items-center justify-end w-[80%] h-[40px] px-8">
            <InputContainer className="font-Inter text-4xl text-gray-600 font-medium w-full h-14 ">
              <input
                type="number"
                // value={value}
                value={amount}
                onChange={(e) => changeAmount(e.target.value)}
                className="font-Inter text-4xl text-gray-600 font-medium w-full h-full bg-transparent outline-none focus:outline-none  text-right"
                autoFocus={true}
              />
            </InputContainer>
            {/* <span className="font-Inter text-4xl text-gray-600 font-medium">
              {amount}
            </span> */}
          </div>
        </div>
        {/* Back Icon */}
        <div onClick={deleteNumber} className="cursor-pointer">
          <MdOutlineBackspace className="text-3xl text-gray-500" />
        </div>
      </div>
      {/* KeyPad */}
      <div className="w-full grid grid-cols-3 gap-5 flex-1 grid-rows-4 mt-10">
        {keypadData.map((item, index) => (
          <SingleKeypad
            changeAmount={numberPressed}
            calculateChange={calculateChange}
            key={index}
            {...item}
            amount={amount}
            resetAmount={resetAmount}
          />
        ))}
      </div>
    </div>
  );
};

export default KeyPadInterface;

const InputContainer = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;
