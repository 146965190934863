import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

/* Date Module */
import { format as formatDate, isDate } from "date-fns";
import { enUS, ar } from "date-fns/locale";

/* English translation files */
import enLang from "./lang/en/en.json";
import cartEn from "./lang/en/Cart.json";
import headerEn from "./lang/en/Header.json";
import homeEn from "./lang/en/Home.json";
import ordersEn from "./lang/en/Orders.json";
import layoutEn from "./lang/en/Layout.json";
import sidebarEn from "./lang/en/Sidebar.json";
import zonesEn from "./lang/en/Zones.json";

/* Arabic translation files */
import arLang from "./lang/ar/ar.json";
import cartAr from "./lang/ar/Cart.json";
import headerAr from "./lang/ar/Header.json";
import homeAr from "./lang/ar/Home.json";
import ordersAr from "./lang/ar/Orders.json";
import sidebarAr from "./lang/ar/Sidebar.json";
import zonesAr from "./lang/ar/Zones.json";

const locales = { enUS, ar };

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translations: {
          ...cartEn,
          ...headerEn,
          ...homeEn,
          ...ordersEn,
          ...layoutEn,
          ...sidebarEn,
          ...zonesEn,
          ...enLang,
        },
      },
      ar: {
        translations: {
          ...cartAr,
          ...headerAr,
          ...homeAr,
          ...ordersAr,
          ...sidebarAr,
          ...zonesAr,
          ...arLang,
        },
      },
    },
    fallbackLng: "ar",
    debug: process.env.NODE_ENV === "development",
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      format: (value, format, lng) => {
        if (isDate(value)) {
          const locale = locales[lng];
          return formatDate(value, format, { locale });
        }
      },
    },
  });

export default i18n;
