import classes from "./PrimaryInputField.module.scss";

const PrimaryInputField = ({
  type = "text",
  label = "",
  placeholder,
  value,
  onChange,
  customClasses = "",
  mainLabel = "",
  max = ""
}) => {
  const styleClasses = [classes.PrimaryInputField, customClasses].join(" ");
  const id = label + Math.random();

  return (
    <div className={styleClasses}>
      <label htmlFor={id}>{label}</label>
      <div>
        {mainLabel && (
          <p className="text-gray-400 font-Inter  text-2xl mb-4">{mainLabel}</p>
        )}

        <input
          type={type}
          id={id}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          maxLength={max}
        />
      </div>
    </div>
  );
};

export default PrimaryInputField;
