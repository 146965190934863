import axiosWithLocation from "config/axiosWithLocationV2";

export const DishServices = {
  getSingleItemStock: function (dish, success, fail) {
    axiosWithLocation
      .post("/items/available", { item: [dish.id] })
      .then(success)
      .catch(fail);
  },
  getMultipleItemsStock: function (dishes, success, fail) {
    return axiosWithLocation
      .post("/items/available", { item: dishes })
      .then(success)
      .catch(fail);
  },
};
