import classes from "./PrimaryInput.module.scss";

const Input = ({
  label,
  placeholder,
  value,
  type,
  onChanged,
  required,
  customClasses,
}) => {
  let inputId = label.toLowerCase().replace(/ /g, "");
  let styleClasses = classes.PrimaryInput;

  if (customClasses) {
    styleClasses = [styleClasses, customClasses].join(" ");
  }

  return (
    <div className={styleClasses}>
      <label htmlFor={inputId}>{label}</label>
      <input
        id={inputId}
        value={value}
        type={type}
        onChange={onChanged}
        placeholder={placeholder}
        required={required}
      />
    </div>
  );
};

export default Input;
