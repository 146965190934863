import React from "react";
import { STATUS } from "components/Status/Status";

const OrderStatus = ({ status = "draft" }) => {
  return (
    <div className="w-full rounded-3xl px-2 py-[2px] border border-gray-100 bg-gray-200 flex flex-row items-center justify-center">
      <div
        className="w-3 h-3 rounded-full"
        style={{ backgroundColor: STATUS[status].dotColor }}
      />
      <div className="flex flex-row items-center">
        <p className="text-lg font-Inter font-medium text-gray-600 pl-4">
          {STATUS[status].text}
        </p>
        <p className="text-lg font-Inter font-medium text-gray-600 pl-4">
          {STATUS[status].trans}
        </p>
      </div>
    </div>
  );
};

export default OrderStatus;
