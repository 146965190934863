// import noOrderImg from "assets/img/no-orders.png";
import Icon from "components/Icon/Icon";
import Loader from "components/Loader/Loader";
import ErrorImg from "assets/img/errorOne.webp";
import useClickOutside from "hooks/useOutsideClick";
import useToggle from "hooks/useToggle";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchZones, addSelectedZone } from "redux/zone";
import { ZoneServices } from "services/ZoneServices";
import { useNavigate } from "../../../../node_modules/react-router-dom/dist/index";
import {
  toast,
  ToastContainer,
} from "../../../../node_modules/react-toastify/dist/index";
// import classes from "./FloorDefaultScreen.module.scss";
import tableClasses from "../UpdateTable/UpdateTable.module.scss";
import Modal from "components/Modal/Modal";
import { fetchZoneTableDetail } from "redux/zoneTable";
import PrimaryButton from "components/PrimaryButton/PrimaryButton";
import SingleZone from "pages/OrdersPage/CreateOrdersPage/TableAllocation/components/SingleZone";
import useEventListener from "hooks/useEventListener";
import { RemoveScroll } from "react-remove-scroll";
import {
  OUTLET_CREATE_PERMISSION,
  ZONE_CREATE_PERMISSION,
  ZONE_DELETE_PERMISSION,
} from "constants/constants";
import { usePermissionHooks } from "hooks/usePermissionHooks";
import GlobalPadding from "components/Layout/globalContainer/GlobalPadding";

const MainFloor = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const addBoxRef = useRef(null);
  const [showOptions, toggleOptions] = useState(false);
  const [isDeleting, toggleisDeleting] = useToggle(false);

  // To determine if the logged in user can view create/add a new zone
  const { canView: canAddZone } = usePermissionHooks(ZONE_CREATE_PERMISSION);

  // To determine if the logged in user can view create/add a new zone
  const { canView: canDeleteZone } = usePermissionHooks(ZONE_DELETE_PERMISSION);

  const { canView: canAddOutlet } = usePermissionHooks(
    OUTLET_CREATE_PERMISSION
  );

  const canAdd = () => {
    if (!canAddZone) {
      toggleOptions(false);
      toast.warning("You don not have permission to perform this action", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      navigate("zones/add");
    }
  };

  useEventListener(
    "scroll",
    (e) => {
      //   console.log(e, "Event");
    },
    window
  );

  const {
    error: zonesError,
    isLoading: zonesLoading,
    zones,
  } = useSelector((state) => state.zone);
  const {
    zoneTableDetail,
    isLoading: zoneDetailLoading,
    error: zoneDetailError,
  } = useSelector((state) => state.zoneTableDetail);
  const [activeZone, setActiveZone] = useState({});

  // const zone_detail = zones?.filter((zn) => zn.id === selectedZone)?.[0] || {};

  useClickOutside(addBoxRef, () => {
    if (showOptions) toggleOptions(false);
  });

  // My code
  const getData = () => {
    dispatch(fetchZones());
  };
  const getTable = () => {
    dispatch(fetchZoneTableDetail({ id: activeZone?.id }));
  };

  // Change viewed zone
  const changeZone = (zone) => {
    setActiveZone(zone);
  };

  // const fet

  useEffect(() => {
    dispatch(fetchZones());
  }, [dispatch]);

  useEffect(() => {
    if (zones && zones.length > 0) {
      dispatch(addSelectedZone(Number(zones[0].id)));
      setActiveZone(zones[0]);
    }
  }, [zones, dispatch]);

  useEffect(() => {
    if (zones && zones?.length > 0 && activeZone?.id) {
      dispatch(fetchZoneTableDetail({ id: activeZone?.id }));
    }
  }, [activeZone, dispatch, zones]);
  // End if my codes

  // const defaultScreen = (
  //   <div classNaMainFloor}>
  //     <ontainer />
  //     <div className={classes.GetStarted}>
  //       <img src={noOrderImg} alt="order pic here" />
  //       <p className={classes.GetStartedHeading}>
  //         <span>Start New Order</span> <span>ابدأ طلب جديد</span>
  //       </p>
  //       <p className={classes.GetStartedText}>
  //         Any assets used in projects will live here.
  //       </p>
  //       <p className={classes.GetStartedText}>
  //         Start creating by uploading your files.
  //       </p>
  //       <div className={classes.GetStartedActions}>
  //         <button className={classes.SupprtBtn}>
  //           <span>Support article</span>
  //         </button>
  //         <div ref={addBoxRef} className={classes.AddBox}>
  //           <button onClick={toggleOptions} className={classes.AddBtn}>
  //             <Icon name="upload" />
  //             <span>Add</span>
  //           </button>
  //           {showOptions && (
  //             <ul className={classes.AddOptions}>
  //               <li>
  //                 <button onClick={() => navigate("zones/add")}>
  //                   Add Zone
  //                 </button>
  //               </li>
  //               {zones?.length > 0 && (
  //                 <li>
  //                   <button onClick={() => navigate("tables/add")}>
  //                     Add Table
  //                   </button>
  //                 </li>
  //               )}
  //             </ul>
  //           )}
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );

  // Delete zone function
  const deleteTableHandler = () => {
    toggleisDeleting();

    ZoneServices?.deleteZone(
      activeZone?.id,
      (res) => {
        setTimeout(() => {
          toast.success("Zone deleted successfully.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }, 500);

        dispatch(fetchZones());
        navigate("/floor");
      },
      (err) => {
        console.log(err.response, "Err");
        if (err && err?.response?.data?.message) {
          toast.error(err.response.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else
          for (const key in err.response.data.errors) {
            toast.error(err.response.data.errors[key][0], {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
      }
    );
  };

  const goToUpdatScreen = (tableNum) => {
    navigate("/floor/tables/update/" + tableNum);
  };

  if (zonesLoading) {
    return (
      <div className="h-screen">
        <Loader />
      </div>
    );
  } else if (zonesError) {
    return (
      <div className="w-full h-screen  flex flex-col items-center justify-center">
        <img
          src={ErrorImg}
          alt="Error Illustration"
          className="block w-[200px] h-[200px]"
        />
        <p className="text-3xl lg:text-4xl font-Inter font-medium text-red-300">
          Error occurred while fetching your zones
        </p>
        <div className="mt-16 w-full flex flex-row items-center justify-center">
          <PrimaryButton
            text="Try Again"
            onClicked={() => {
              getData();
            }}
          />
        </div>
      </div>
    );
  } else if (!zonesError && !zonesLoading && zones?.length === 0) {
    return (
      <RemoveScroll>
        <div className="w-full flex flex-col  items-center lg:mt-40 h-screen">
          <p className="pt-5 text-2xl font-Inter font-medium text-textColor">
            <span>No zones available. Please add zones.</span>
          </p>
          <div
            ref={addBoxRef}
            className="w-full mt-10 flex flex-row items-center justify-center mb-14 relative"
          >
            <div
              onClick={() => toggleOptions(!showOptions)}
              className=" flex flex-row w-[15%] h-[45px] items-center justify-center rounded-md bg-primary text-white cursor-pointer"
            >
              <Icon name="upload" />
              <span className="ml-4">Add</span>
            </div>
            {showOptions && (
              <div className="absolute w-[15%] -bottom-16  py-2 rounded-b  right-[43%] left-[42.5%] flex flex-col bg-white">
                <div
                  onClick={() => navigate("zones/add")}
                  className="cursor-pointer text-xl flex flex-row items-center justify-center h-14 transition-all duration-300 ease-in-out hover:bg-gray-200 text-greyText"
                >
                  <p>Add Zone</p>
                </div>
                {/* <div
                onClick={() => navigate("tables/add")}
                className="cursor-pointer text-xl flex flex-row items-center justify-center h-14 transition-all duration-300 ease-in-out hover:bg-gray-200 text-greyText"
              >
                <p>Add Table</p>
              </div> */}
              </div>
            )}
          </div>
        </div>
      </RemoveScroll>
    );
  }

  return (
    <div className="">
      <Modal
        onClickOutside={toggleisDeleting}
        background="dark"
        show={isDeleting}
        customClasses={tableClasses.CustomModal}
      >
        <Icon name="alert-circle-2" width={4.8} height={4.8} />
        <p>{`Delete [${activeZone?.name}] ?`}</p>
        <p>
          Doing so will also delete all tables contained in the zone. This
          action cannot be undone!
        </p>
        <div className={tableClasses.ModalActions}>
          <button
            className={tableClasses.ModalCancelBtn}
            onClick={toggleisDeleting}
          >
            Cancel
          </button>
          <button
            className={tableClasses.ModalProceedBtn}
            onClick={deleteTableHandler}
          >
            Delete
          </button>
        </div>
      </Modal>
      <GlobalPadding>
        <div className="w-full h-screen">
          <div
            ref={addBoxRef}
            className="w-full mt-10 flex flex-row items-center justify-end mb-14 relative"
          >
            <div
              onClick={() => toggleOptions(!showOptions)}
              className=" flex flex-row w-[15%] h-[45px] items-center justify-center rounded-md bg-primary text-white cursor-pointer"
            >
              <Icon name="upload" />
              <span className="ml-4">Add</span>
            </div>
            {showOptions && (
              <div className="absolute w-[15%] -bottom-32  py-2 rounded-b  right-0 flex flex-col bg-white">
                <div
                  onClick={canAdd}
                  className="cursor-pointer text-xl flex flex-row items-center justify-center h-14 transition-all duration-300 ease-in-out hover:bg-gray-200 text-greyText"
                >
                  <p>Add Zone</p>
                </div>
                <div
                  onClick={
                    !canAddOutlet
                      ? () => {
                          toggleOptions(false);
                          toast.warning(
                            "You don not have permission to perform this action",
                            {
                              position: toast.POSITION.BOTTOM_RIGHT,
                            }
                          );
                        }
                      : () => {
                          toggleOptions(false);
                          navigate("tables/add");
                        }
                  }
                  className="cursor-pointer text-xl flex flex-row items-center justify-center h-14 transition-all duration-300 ease-in-out hover:bg-gray-200 text-greyText"
                >
                  <p>Add Table</p>
                </div>
              </div>
            )}
          </div>
          {zones.map((item, index) => (
            <SingleZone
              zone={item}
              key={index}
              onClick={changeZone}
              zoneTableDetail={zoneTableDetail}
              zoneDetailLoading={zoneDetailLoading}
              zoneDetailError={zoneDetailError}
              getTable={getTable}
              activeZone={activeZone}
              isFloor={true}
              goToUpdatScreen={goToUpdatScreen}
              deleteZone={
                !canDeleteZone
                  ? () => {
                      toast.warning(
                        "You don not have permission to perform this action",
                        {
                          position: toast.POSITION.BOTTOM_RIGHT,
                        }
                      );
                    }
                  : toggleisDeleting
              }
            />
          ))}
        </div>
      </GlobalPadding>
      <ToastContainer />
    </div>
  );
};

export default MainFloor;
