import React from "react";

const DeliverectDrawerTabHeader = ({ tabs = [] }) => {
  return (
    <div className="w-full border-b-[0.2px] border-b-gray-200 pb-5">
      <div className={`"w-full "`}>
        {tabs.map((item, index) => (
          <div
            className="border-b-[2px] border-b-primary pb-5 w-[20%] text-center font-Inter font-medium cursor-pointer"
            key={index}
          >
            <span className="text-2xl capitalize text-primary">{item}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DeliverectDrawerTabHeader;
