import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  shiftReport: {}
};

const shiftSlice = createSlice({
  name: "shift",
  initialState,
  reducers: {
    saveReport(state, { payload }) {
      state.shiftReport = {...payload.data};
    },
    clearShiftReport(state) {
      state.shiftReport = {};
    },
  },
});

export const { saveReport, clearShiftReport } = shiftSlice.actions;
export default shiftSlice.reducer;
