import ToggleDrawerButton from "components/ToggleDrawerButton/ToggleDrawerButton";
import { useWindowSize } from "hooks/useWindowSize";
import React from "react";
import { useSelector } from "react-redux";

const GlobalPadding = ({
  children,
  className,
  hideRightPadding,
  hideTopPadding,
}) => {
  const { isOpen } = useSelector((state) => state.drawer);
  let size = useWindowSize();
  const getPaddingStatus = () => {
    if (!isOpen && !hideTopPadding) {
      return "mt-[150px] ";
    } else if (!isOpen && hideTopPadding) {
      return "lg:mt-[45px] lg:pt-[65px] md:mt-[45px] md:pt-[85px]";
    } else if (isOpen && !hideTopPadding) {
      return "lg:mt-[45px] md:mt-[55px]";
    } else if (!isOpen && hideTopPadding) {
      return "lg:mt-[45px] md:mt-[55px]";
    }
  };

  return (
    <div
      className={`w-full ${
        !hideRightPadding && "pr-[30.2px]"
      } ${getPaddingStatus()} ${className}`}
    >
      {size.width >= 768 && <ToggleDrawerButton />}
      {children}
    </div>
  );
};

export default GlobalPadding;
