import Modal from "react-modal";
import PinInput from "react-pin-input";
import { useContext, useRef, useState } from "react";
import { ResturantService } from "services/ResturantService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { clearScreenLockState } from "redux/restaurant";
import MainLoader from "components/Loader/MainLoader";
import { setSelectedLocation } from "redux/locations";
import { AuthContext } from "context/authContext";

const LockScreenModal = ({ showModal, toggleModal }) => {
  // const [pin, setPin] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { locations } = useSelector((state) => state.location);
  let inputRef = useRef();
  const { logout } = useContext(AuthContext);

  const unLockPosScreen = (pin) => {
    setLoading(true);
    ResturantService.unLockScreen(
      { pin },
      (res) => {
        localStorage.removeItem("jalebi_pos_screen_lock_state");
        setLoading(false);
        dispatch(clearScreenLockState());
        toast.success(res?.data?.message || "Screen unlocked Successfully.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        inputRef.current.clear();
        // Determine if the logged user location contain the stored outlet_id when screen was locked
        const isOutletAvailable =
          locations &&
          locations?.filter(
            (item) => item?.id === res?.data?.data?.session?.outlet_id
          );
        if (isOutletAvailable && isOutletAvailable.length) {
          dispatch(setSelectedLocation(res?.data?.data?.session?.outlet_id));
          navigate(res?.data?.data?.state);
        }
        window.location.reload();
      },
      (err) => {
        setLoading(false);
        inputRef.current.clear();
        // setPin("");
        toast.error(
          err?.response?.data?.message || "Unable to unlock screen!",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
      }
    );
  };

  return (
    <Modal
      isOpen={showModal}
      onRequestClose={toggleModal}
      shouldCloseOnEsc
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          zIndex: 1000,
          overflow: "auto",
          width: "100%",
          margin: 0,
          padding: 0,
        },
        content: {
          outline: "none",
        },
      }}
      shouldCloseOnOverlayClick={false}
      shouldReturnFocusAfterClose={true}
      ariaHideApp={false}
      className="bg-[#111827] h-full flex flex-col items-center justify-center w-full"
    >
      <section className="w-full flex flex-col items-center justify-center">
        <h6 className="text-white text-2xl font-Inter font-semibold">
          Please Enter your Pin to login{" "}
        </h6>
        <div className="mt-4">
          <PinInput
            length={4}
            ref={inputRef}
            initialValue=""
            secret
            secretDelay={100}
            type="numeric"
            inputMode="number"
            style={{ padding: "10px" }}
            inputStyle={{
              borderColor: "#FC7001",
              borderRadius: "5px",
              marginRight: "5px",
              color: "#fff",
              fontSize: "14px",
            }}
            inputFocusStyle={{ borderColor: "#eee" }}
            onComplete={(value) => {
              unLockPosScreen(value);
            }}
            autoSelect={true}
            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
          />
        </div>
        {loading && <MainLoader size={15} />}
        <button className="mt-5" onClick={logout}>
          <span className="text-primary underline font-Inter text-lg font-normal">
            Logout
          </span>
        </button>
      </section>
    </Modal>
  );
};

export default LockScreenModal;
