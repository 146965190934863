import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { increaseItemInCartInput } from "redux/cart";
import styled from "styled-components";
import Icon from "../../../components/Icon/Icon";
import { FaPercent } from "react-icons/fa";
import { useGetActiveLocation } from "hooks/useGetActiveLocation";

const PriceListItem = ({
  index,
  id,
  catId,
  name,
  price,
  quantity,
  // priceWithoutDiscount,
  onRemove,
  onReduceItemQuantity,
  onIncreaseItemQuantity,
  sizes,
  translations,
  noteClicked,
  discountClicked,
  discountInfo,
  item,
}) => {
  const { decimal_preference, currency } = useGetActiveLocation();
  const { discount_value, discount_type } = item;

  const mainSize = sizes[0]?.size;
  const arTranslation = translations["ar"]?.name;

  const mainPrice = (Number(price) * Number(quantity)).toFixed(decimal_preference);

  const total_discount = () => {
    if (discount_type && discount_value) {
      return discount_type === "percentage"
        ? (Number(discount_value) * mainPrice) / 100
        : Number(discount_value);
    }
  };

  const dispatch = useDispatch();

  const mainQuantity = useMemo(() => {
    if (Number(quantity) === 0) {
      return 1;
    } else {
      return Number(quantity);
    }
  }, [quantity]);

  const returnedQuantity = (val) => {
    if (Number(val) === 0) {
      return 1;
    } else {
      return Number(val);
    }
  };

  const discountedPrice = Number(price * quantity - total_discount())?.toFixed(decimal_preference);

  // const val = () => {
  //   if(discount_type === "percentage"){
  //     return (Number(mainPrice) * quantity) / 100
  //   } else if(discount_type === "fixed"){
  //     return (Number(discount_value) * quantity)
  //   }
  // }

  // const discount_here = discount_type === "percentage" ? calculateDiscount() : calculateDiscount()

  return (
    <div className="w-full flex flex-row justify-between items-center py-2 first:border-t-0 border-t-[0.2px] border-b-[0.2px] border-t-gray-200 border-b-gray-200">
      <div className="w-[55%] md:max-w-[60%] xl:max-w-[70%] flex flex-row items-center">
        <span className="font-Inter text-greyText">{index}</span>
        <div className="pl-5 w-[90%] flex flex-col flex-wrap">
          <p className=" text-textColor font-medium md:text-xl text-base font-Inter">{name}</p>
          {arTranslation && <p className=" text-greyText font-Inter text-xl">{arTranslation}</p>}
          {discount_value ? (
            <p className="pt-1 text-[11px] font-medium text-green-700">
              {discount_value} {discount_type === "fixed" ? currency : "%"} discount
            </p>
          ) : null}

          {/* <p className="pt-1 text-placeholder font-Inter text-base">
            {mainSize}
          </p> */}
        </div>
      </div>
      <div>
        <p
          className="text-primary font-medium md:text-xl text-base font-Inter cursor-pointer pt-2 mr-3"
          onClick={noteClicked}
        >
          <Icon name="edit" />
        </p>
      </div>
      <div className="py-2 w-[45%] flex flex-row justify-end md:space-x-8 xl:space-x-10 items-center">
        <div className="bg-gray-100 px-5 rounded-md h-16 grid grid-cols-[auto_1fr_auto] gap-3">
          <button
            onClick={
              quantity === 1
                ? () => onRemove(index - 1, id, catId)
                : () => onReduceItemQuantity(index, id, catId)
            }
            className="!cursor-pointer text-3xl block"
          >
            &#8722;
          </button>
          <InputContainer className="h-full">
            <input
              type="number"
              aria-label="quantity-input"
              value={mainQuantity}
              min="1"
              onChange={(e) =>
                dispatch(
                  increaseItemInCartInput({
                    itemIndex: index,
                    value: returnedQuantity(e.target.value),
                  })
                )
              }
              className="w-full h-full focus:outline-none bg-transparent font-bold font-Inter text-lg lg:text-xl text-center"
            />
          </InputContainer>
          <button
            onClick={() => onIncreaseItemQuantity(index, id, catId)}
            className="!cursor-pointer text-3xl block"
          >
            {" "}
            &#43;
          </button>
        </div>
        <div className="w-[180px] text-center">
          {total_discount() > 0 ? (
            <>
              <div className="font-medium">{discountedPrice}</div>
              <div className="line-through text-gray-400 text-[11px]">{mainPrice}</div>
            </>
          ) : (
            <div className="font-medium">{mainPrice}</div>
          )}
          <div className="flex flex-row items-center hidden">
            <p
              className="text-primary font-medium md:text-xl text-base font-Inter cursor-pointer pt-2"
              onClick={noteClicked}
            >
              <Icon name="edit" />
            </p>
            <p
              className="text-primary font-medium md:text-xl text-base font-Inter cursor-pointer pt-2 pl-5"
              onClick={discountClicked}
            >
              <FaPercent />
            </p>
          </div>
        </div>
        <div>
          <p
            className="text-primary font-medium md:text-xl text-base font-Inter cursor-pointer pt-2"
            onClick={() => onRemove(index - 1, id, catId)}
          >
            <Icon name="cross" />
          </p>
        </div>
      </div>
    </div>
  );
};

export default PriceListItem;

const InputContainer = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;
