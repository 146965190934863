import classes from "./PrimaryButton.module.scss";

const PrimaryButton = ({
  index,
  text,
  type,
  onClicked,
  customClasses,
  isDisabled,
}) => {
  let styleClasses = classes.PrimaryButton;

  switch (type) {
    case "secondary":
      styleClasses = [styleClasses, classes.Secondary].join(" ");
      break;

    default:
      styleClasses = [styleClasses, classes.Primary].join(" ");
      break;
  }

  if (isDisabled) {
    styleClasses = [classes.PrimaryButton, classes.Disabled].join(" ");
  }

  if (customClasses) {
    styleClasses = [styleClasses, customClasses].join(" ");
  }

  return (
    <button
      tabIndex={index}
      onClick={onClicked}
      className={styleClasses}
      disabled={isDisabled}
    >
      {text}
    </button>
  );
};

export default PrimaryButton;
