import axiosWithLocation from "config/axiosWithLocationV2";

export const TableServices = {
  createTable: function (table, success, fail) {
    axiosWithLocation.post("/outlet/tables", table).then(success).catch(fail);
  },
  updateTable: function (table, success, fail) {
    axiosWithLocation
      .put("/outlet/tables/" + table.id, table)
      .then(success)
      .catch(fail);
  },
  deleteTable: function (tableId, success, fail) {
    axiosWithLocation
      .delete("/outlet/tables/" + tableId)
      .then(success)
      .catch(fail);
  },
};
