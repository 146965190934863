import { RemoveScroll } from "../../../../../node_modules/react-remove-scroll/dist/es5/index";

const NoViewPermission = ({ msg = "" }) => {
  return (
    <RemoveScroll>
      <div className="w-full h-[80vh] flex flex-col items-center justify-center">
        <p className="text-gray-400 font-Inter font-semibold">
          {msg ? msg : ""}
        </p>
      </div>
    </RemoveScroll>
  );
};

export default NoViewPermission;
