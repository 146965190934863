import PartySizeModal from "components/PartySizeModal/PartySizeModal";
import useToggle from "hooks/useToggle";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import classes from "./AllocationSteps.module.scss";
import SingleAllocationSteps from "./components/SingleAllocationSteps";
import { skipPartySize } from "redux/createOrder";
import GlobalPadding from "components/Layout/globalContainer/GlobalPadding";
// import { useCallback, useEffect } from "react";
// import { useDefaultSetting } from "hooks/useDefaultSetting";

const AllocationSteps = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { orderTypes, partySize } = useSelector((state) => state.createOrder);
  const [showPartySizeModal, toggleModal] = useToggle(
    !!orderTypes?.includes("dine in")
  );

  return (
    <GlobalPadding>
      <PartySizeModal show={showPartySizeModal} toggleModal={toggleModal} />
      <div className="w-full h-[90vh]">
        <div className="w-full flex flex-row items-center justify-between">
          <div className="w-full">
            <h2 className="font-Inter text-3xl text-textColor font-medium">
              {t("CREATE_A_NEW_ORDER")}
            </h2>
            <p className="text-placeholder font-Inter text-xl pt-2">
              Complete the two steps and a new order will be created
              automatically at the end.
            </p>
          </div>
        </div>
        <div className="w-full mt-20 grid grid-auto-fit-sm gap-8">
          {/* Party Size step */}
          <SingleAllocationSteps
            active={true}
            step="Step 1"
            isFirst={true}
            iconName="party-size"
            title="Party Size"
            skipped={partySize.skipped}
            skipText="Skip for now"
            controlTextOne={partySize?.value > -1 ? "Edit" : "Choose"}
            controlOneFunc={toggleModal}
            skipFunc={() => dispatch(skipPartySize())}
          />
          {/* Table step */}
          <SingleAllocationSteps
            active={partySize.updated}
            step="Step 2"
            iconName="table-allocation"
            title="Table Allocation"
            controlTextOne="Choose"
            controlOneFunc={() => navigate("/orders/create/table-allocation")}
            notTable={true}
          />
          {/* Table step */}
          <SingleAllocationSteps
            active={true}
            step="Step 3"
            iconName="invoice"
            title="Create Order"
            controlTextOne="Choose"
            controlOneFunc={() => navigate("/orders/create/table-allocation")}
            showArrowIcon={true}
          />
        </div>
      </div>
    </GlobalPadding>
  );
};

export default AllocationSteps;
