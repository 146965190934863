import { useSelector } from "react-redux";

export const useGetActiveLocationWithOrderTab = ({
  activeTab = "",
  checkoutChannel = {},
}) => {
  const { activeLocation } = useSelector((state) => state.location);
  const { checkoutOrder } = useSelector((state) => state.cart);
  const activeCurrency =
    activeLocation && activeLocation.currency_code
      ? activeLocation.currency_code
      : "";

  const isTaxEnabled = activeLocation?.tax_enabled >= 1 ? true : false;
  const mainTax = isTaxEnabled ? activeLocation?.tax : 0;
  const showChannel =
    checkoutChannel &&
    checkoutChannel?.commission &&
    checkoutOrder?.type !== "dine in"
      ? true
      : false;
  const channelCommission =
    checkoutChannel &&
    checkoutChannel?.commission &&
    checkoutOrder?.type !== "dine in"
      ? checkoutChannel?.commission
      : 0;

  return {
    currency: activeCurrency,
    tax: mainTax,
    taxEnabled: isTaxEnabled,
    showChannel,
    channelCommission,
  };
};
