import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
// import axiosWithLocationV2 from "config/axiosWithLocationV2";

const initialState = {
  locations: [],
  selectedLocation: null,
  error: null,
  isLoading: false,
  activeLocation: {},
  activeLocationInfo: {},
};

export const fetchLocations = createAsyncThunk(
  "location/fetchLocations",
  async (payload, thunkAPI) => {
    const token = payload;
    const response = await axios
      .get("/restaurant/locations", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        return res?.data?.data?.data;
      })
      .catch((err) => {
        return thunkAPI.rejectWithValue(
          "Something went wrong! Please try again later"
        );
      });
    return response;
  }
);

export const locationsSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setSelectedLocation: (state, action) => {
      state.selectedLocation = action.payload;
    },
    setActiveLocation: (state, action) => {
      state.activeLocation = action.payload;
    },
    setActiveLocationInfo: (state, action) => {
      state.activeLocationInfo = action.payload;
    },
    handleError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLocations.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchLocations.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      if (action?.payload?.length === 0) {
        state.locations = [];
      } else {
        state.locations = action.payload;
      }
    });
    builder.addCase(fetchLocations.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export const {
  setSelectedLocation,
  setActiveLocation,
  setActiveLocationInfo,
  handleError,
} = locationsSlice.actions;

export default locationsSlice.reducer;
