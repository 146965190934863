import React from "react";
import { RiArrowLeftRightFill } from "react-icons/ri";

const PosStatus = ({ status }) => {
  return (
    <div className="w-[15%] h-16 border border-primary rounded-md bg-[#FFF3EB] flex flex-row items-center justify-center text-primary font-Inter font-medium">
      <RiArrowLeftRightFill className="text-primary text-3xl" />
      <p className="pl-3">{status}</p>
    </div>
  );
};

export default PosStatus;
