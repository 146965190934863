import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../config/axiosWithLocationV2";

export const fetchAllChannels = createAsyncThunk(
  "channel/fetchAllChannels",
  // .get("/delivery-channels")
  async (payload, thunkApi) => {
    const response = await axios
      .get(`/delivery-channels?order_id=${payload?.order_id}`)
      .then((res) => res.data?.data)
      .catch((error) =>
        thunkApi.rejectWithValue(
          error?.response?.data?.message ||
            "Something went wrong. Please try again later."
        )
      );
    return response;
  }
);

const initialState = {
  channels: null,
  error: null,
  isLoading: false,
  checkoutChannel: null,
};

const channelSlice = createSlice({
  name: "channel",
  initialState,
  reducers: {
    setCheckoutChannel: (state, action) => {
      state.checkoutChannel = action.payload.checkoutChannel;
    },
    clearCheckoutChannel: (state) => {
      state.checkoutChannel = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllChannels.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAllChannels.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.channels = action.payload?.data;
    });
    builder.addCase(fetchAllChannels.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export const { setCheckoutChannel, clearCheckoutChannel } =
  channelSlice.actions;

export default channelSlice.reducer;
