import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import axios from "../config/axiosWithLocationV2";

export const fetchAllOrders = createAsyncThunk(
  "orders/fetchAllOrders",
  async (payload, thunkApi) => {
    const response = await axios
      .get(`/orders?page=${payload}`)
      .then((res) => {
        if (!res.data?.data.data && !res.data?.data?.data?.length) {
          return [];
        } else {
          return res.data?.data;
        }
      })
      .catch((error) => {
        thunkApi.rejectWithValue(
          error?.response?.data?.message ||
            "Something went wrong. Please try again later."
        );
      });
    return response;
  }
);

const initialState = {
  orders: null,
  error: null,
  isLoading: false,
  extra: null,
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    updateOrder(state, action) {
      const index = current(state).orders.findIndex(
        (order) => order.id === action.payload.id
      );
      state.orders[index].status = action.payload.status;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllOrders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAllOrders.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.orders = action.payload?.data.reverse();
      state.extra = action.payload?.extra;
    });
    builder.addCase(fetchAllOrders.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export const { updateOrder } = orderSlice.actions;

export default orderSlice.reducer;
