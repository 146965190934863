import React from "react";
import { tableStatus } from "utillity/productionData";

const SingleTableStatus = ({ type }) => {
  const keyValue = Object.keys(type);
  const value = Object.values(type);
  return (
    <div className="flex flex-row items-center mr-10">
      <div
        className="w-5 h-5 rounded mr-2"
        style={{ backgroundColor: tableStatus[keyValue[0]]?.bgColor }}
      />
      <p className="text-xl font-Inter font-medium text-greyText">
        {value[0]}&nbsp;
        {tableStatus[keyValue[0]]?.text}
      </p>
    </div>
  );
};

export default SingleTableStatus;
