import React from "react";
import { useTranslation } from "react-i18next";

const SingleDesktopFilterSelect = ({ item, active, onChangeFilter }) => {
  const { t } = useTranslation();
  return (
    <button
      tabIndex="0"
      onClick={() => onChangeFilter(item)}
      className={
        active?.value === item?.value
          ? "lg:text-lg xl:text-xl font-Inter w-full  h-16 text-primary font-semibold flex flex-row items-center justify-start cursor-pointer border-b-2 border-b-primary transition-all duration-100 ease-in-out hover:text-primary"
          : "lg:text-lg xl:text-xl font-Inter w-full  h-16 text-textColor flex flex-row items-center justify-start cursor-pointer  transition-all duration-200 ease-in-out hover:text-primary"
      }
    >
      <p>{t(item?.textLg1)}</p>
      <p className="pl-4">{item?.textLg2}</p>
    </button>
  );
};

export default SingleDesktopFilterSelect;
