export const ORDERTYPES = [
  {
    textLg1: "SERVING",
    textLg2: "خدمة",
    value: "draft",
  },
  {
    textLg1: "ON_HOLD",
    textLg2: "في الانتظار",
    value: "on-hold",
  },
  // {
  //   textLg1: "RESERVED",
  //   textLg2: "محجوز",
  //   value: "reserved",
  // },
  {
    textLg1: "VOIDED",
    textLg2: "فارغ",
    value: "cancel",
  },
  {
    textLg1: "COMPLETED",
    textLg2: "مكتمل",
    value: "complete",
  },
  {
    textLg1: "ALL_ORDERS",
    textLg2: "جميع الطلبات",
    value: "all",
  },
  {
    textLg1: "LIVE_ORDERS",
    textLg2: "الطلبات الحية",
    value: "live-orders",
  },
];

export const tableTabStatusData = [
  { textLg1: "VACANT", textLg2: "شاغر", value: "vacant", slug: "vacant_table" },
  {
    textLg1: "RESERVED",
    textLg2: "محجوز",
    value: "reserved",
    slug: "reserved_table",
  },
  {
    textLg1: "OCCUPIED",
    textLg2: "احتل",
    value: "occupied",
    slug: "occupied_table",
  },
  {
    textLg1: "DISABLED",
    textLg2: "معاق",
    value: "disabled",
    slug: "disabled_table",
  },
  {
    textLg1: "ALL_TABLES",
    textLg2: "كل الطاولات",
    value: "all",
    slug: "all",
  },
];

export const servicesHeaderTabData = [
  {
    value: "instant",
    textLg1: "INSTANT",
    textLg2: "",
  },
  {
    value: "deferred",
    textLg1: "DEFERRED",
    textLg2: "",
  },
  {
    value: "bulk",
    textLg1: "BULK",
    textLg2: "",
  },
];

export const tableStatus = {
  vacant_table: {
    text: "Vacant",
    bgColor: "#12B76A",
  },
  reserved_table: {
    text: "Reserved",
    bgColor: "#2E90FA",
  },
  occupied_table: {
    text: "Occupied",
    bgColor: "#F3781F",
  },
  disabled_table: {
    text: "Disabled",
    bgColor: "#9CA3AF",
  },
};
export const singleTableStatus = {
  available: {
    text: "Available",
    bgColor: "#12B76A",
  },
  occupied: {
    text: "Occupied",
    bgColor: "#F3781F",
  },
  reserved: {
    text: "Reserved",
    bgColor: "#2E90FA",
  },
  disabled_table: {
    text: "Disabled",
    bgColor: "#9CA3AF",
  },
};

export const INITIAL_VALUE = 0;

export const keypadData = [
  { value: "1", icon: false },
  { value: "2", icon: false },
  { value: "3", icon: false },
  { value: "4", icon: false },
  { value: "5", icon: false },
  { value: "6", icon: false },
  { value: "7", icon: false },
  { value: "8", icon: false },
  { value: "9", icon: false },
  { value: ".", icon: false },
  { value: "0", icon: false },
  { value: "", icon: true },
];

export const ORDER_DISCOUNT_TYPE = [
  {
    textLg1: "By Order",
    value: "order",
  },
  {
    textLg1: "By Item",
    value: "item",
  },
]
