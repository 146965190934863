import { Link } from "react-router-dom";
import classes from "./CustomLink.module.scss";

const CustomLink = ({ link, text, customClasses }) => {
  let styleClasses = classes.CustomLink;

  if (customClasses) {
    styleClasses = [styleClasses, customClasses].join(" ");
  }
  return (
    <Link className={styleClasses} to={link}>
      {text}
    </Link>
  );
};

export default CustomLink;
