const FilterCategoryButton = ({ name, value, text, isChecked, onChanged }) => {
  // let id = value.replace(/ /g, "");

  return (
    <div
      onClick={() => onChanged(value)}
      className={
        isChecked
          ? "w-full px-2 py-2 flex flex-row items-center justify-center cursor-pointer rounded-md hover:bg-[#F3781F] hover:text-white  bg-[#F3781F] text-white font-semibold"
          : "w-full px-2 py-2 flex flex-row items-center justify-center cursor-pointer rounded-md hover:bg-[#F3781F] text-gray-500 hover:text-white  hover:font-semibold font-Inter"
      }
    >
      <p className="font-Inter font-medium text-base  capitalize text-center w-[90%]">
        {value}
      </p>
    </div>
  );
};

export default FilterCategoryButton;
