import React from "react";
import moment from "../../../node_modules/moment/moment";
import OrderServicesView from "./components/OrderServicesView";
import DefaultIMG from "../../assets/img/deliverect.png";

const Order = (props) => {
  const {
    created_at: createdAt,
    onClicked,
    multiOrderTypes,
    activeTab,
    platform,
    delivery_channel,
    order_calling_number
  } = props;
  // Getting unique items from multiOrderTypes array
  const uniqueArray = [
    ...new Map(multiOrderTypes?.map((item) => [item["type"], item]))?.values(),
  ];
  // Getting orders that match the current filter status
  let mainArray = [];
  if (activeTab?.value !== "all") {
    const statusData = uniqueArray?.filter(
      (item) => item?.status === activeTab?.value
    );
    mainArray = [...statusData];
  } else {
    mainArray = [...uniqueArray];
  }

  const imgUrl =
    delivery_channel && delivery_channel?.smallimage
      ? delivery_channel?.smallimage
      : DefaultIMG;
  return (
    <div
      className="w-full bg-gray-50 px-5 py-8 rounded-md "

      // onClick={() => {
      //   onClicked(id);
      // }}
    >
      {/* Header */}
      <div className="w-full border-b border-b-gray-200 pb-5 flex flex-row items-center justify-between">
        <div>
          <p className="md:text-xl lg:text-2xl text-placeholder font-medium font-Inter">
            Order ID{" "}
            <span className="md:text-2xl lg:text-3xl text-greyText pl-2 ">
              #{mainArray[0]?.order_no}
            </span>
          </p>
          {/* Time */}
          <div className="">
            <p className="text-lg text-gray-500 font-Inter  pt-1">
              Time: <span>{moment(createdAt).format("hh:mm:ss A")}</span>
            </p>
            <p className="text-lg text-gray-500 font-Inter  pt-1">
              Date: <span>{moment(createdAt).format("LL")}</span>
            </p>
            <p className="text-lg text-gray-500 font-Inter  pt-1">
              Order number: <span>{order_calling_number || ""}</span>
            </p>
          </div>
        </div>
        {platform && platform === "Deliverect" && (
          <div className="flex flex-row justify-end">
            <img
              src={DefaultIMG}
              alt={`${platform}`}
              // className="block w-[60px] h-[50px]"
            />
          </div>
        )}
        {platform &&
          platform !== "Deliverect" &&
          delivery_channel && (
            <div className="flex flex-row justify-end">
              <img src={imgUrl} alt={`${platform}`} className="block" />
            </div>
          )}
      </div>
      {/* Content */}
      <div
        className="w-full mt-5 "
        onClick={() => {
          // dispatch(changeActiveService(uniqueArray[0]?.type));
          // onClicked([]);
          onClicked(uniqueArray);
          // console.log(uniqueArray, "Hello");
        }}
      >
        <OrderServicesView multiOrderTypes={mainArray} />
      </div>
    </div>
  );
};

export default Order;
