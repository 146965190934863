import Icon from "components/Icon/Icon";
import classes from "./PaymentChannelCard.module.scss";

const MainPaymentChannelCard = ({
  name,
  img,
  isSelected,
  onClicked,
  commission,
  id,
  commission_type,
  channel_tax,
}) => {
  return (
    <li
      key={name}
      className={
        isSelected
          ? classes.PaymentChannelCardSelected
          : classes.PaymentChannelCard
      }
      onClick={() =>
        onClicked({ name, img, commission, id, commission_type, channel_tax })
      }
    >
      {isSelected && <Icon name="tick" />}
      <img src={`${img}`} alt="Cash Payment" className=" block" />
      <span>{name}</span>
    </li>
  );
};

export default MainPaymentChannelCard;
