import Status from "components/Status/Status";
import { useSelector } from "react-redux";
import moment from "../../../node_modules/moment/moment";
import classes from "./DrawerCart.module.scss";

const DrawerCart = ({
  children,
  onBackgrondClick,
  id,
  createdAt,
  status,
  activeCart: activeOrder,
  hideDefault = false,
}) => {
  const createOrder = useSelector((state) => state.createOrder);
  const cart = useSelector((state) => state.cart);
  const editOrder = useSelector((state) => state.editOrder);
  const activeTab =
    editOrder &&
    editOrder?.selectedOrders.filter((item) => item.type === cart?.activeCart);

  return (
    <div className={classes.DrawerCartContainer} onClick={onBackgrondClick}>
      <div className={classes.DrawerCart} onClick={(e) => e.stopPropagation()}>
        {!hideDefault && (
          <>
            <div className="w-full flex-col md:flex  md:flex-row items-center justify-between">
              <div>
                {createOrder?.selectedTable?.tableNum ? (
                  <h6 className="font-semibold font-Inter text-greyText text-3xl">
                    T-21
                  </h6>
                ) : (
                  <h6 className="font-semibold font-Inter text-greyText text-3xl capitalize">
                    {activeOrder}
                  </h6>
                )}
              </div>

              <div className="flex flex-row items-center justify-self-end">
                <p className="text-placeholder text-end pr-7">
                  {moment(createdAt).format("hh:mm A")} -{" "}
                  {moment(createdAt).format("DD/MM/YYYY")}
                </p>
                <Status status={status} />
              </div>
            </div>
            <div className="w-full flex flex-row items-center justify-between pt-4 border-b-[0.2px] border-b-gray-200 pb-2 ">
              <span className="font-medium font-Inter text-greyText text-xl ">
                Order ID <span>#{id}</span>
              </span>
              {cart?.activeCart === "dine in" && (
                <h6 className="font-medium font-Inter text-greyText text-xl">
                  Table - {activeTab[0]?.outletTable?.table_number}
                </h6>
              )}
            </div>
          </>
        )}
        {children}
      </div>
    </div>
  );
};

export default DrawerCart;
