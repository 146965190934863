import Backdrop from "components/Backdrop/Backdrop";
import Loader from "components/Loader/Loader";
import PrimaryButton from "components/PrimaryButton/PrimaryButton";
import PrimaryInputField from "components/PrimaryInputField/PrimaryInputField";
// import PrimarySelectField from "components/PrimarySelectField/PrimarySelectField";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { addZone } from "redux/zone";
import { ZoneServices } from "services/ZoneServices";
import { useNavigate } from "../../../../node_modules/react-router-dom/dist/index";
import classes from "./AddZone.module.scss";
import GlobalPadding from "components/Layout/globalContainer/GlobalPadding";

// const FACILITIES = [
//   "Smoking allowed",
//   "No smoking",
//   "Outdoor Heating",
//   "Outdoor Cooling",
//   "Pets Allowed",
//   "No Pets",
//   "Kids Friendly",
//   "Play area",
// ];

const AddZone = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [area, setArea] = useState(0);
  // const [facilities, setFacilities] = useState([]);
  const [zoneType, setZoneType] = useState("in-door");
  const [isLoading, setIsLoading] = useState(false);

  // const addFicility = (facility) => {
  //   let index = facilities.findIndex((item) => item === facility);
  //   let allFacillities = [...facilities];

  //   if (index > -1) {
  //     allFacillities.splice(index, 1);
  //   } else {
  //     allFacillities.push(facility);
  //   }
  //   setFacilities(allFacillities);
  // };

  // const getFacilityStyleClass = (facility) => {
  //   return facilities.includes(facility)
  //     ? classes.FacilitySelected
  //     : classes.Facility;
  // };

  const onProceedHandler = () => {
    setIsLoading(true);

    const zone = {
      name,
      type: zoneType,
      is_active: 1,
      area: Number(area),
    };

    ZoneServices.createZone(
      zone,
      (res) => {
        toast.success("Zone addes successfully.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        dispatch(addZone(res.data.data));
        navigate("/floor");
      },
      (err) => {
        for (const key in err.response.data.errors) {
          toast.error(err.response.data.errors[key][0], {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        setIsLoading(false);
      }
    );
  };

  const verifyForm = () => {
    return name.trim() !== "" && zoneType && !isNaN(area);
  };

  return (
    <GlobalPadding>
      <div className="w-full h-screen ">
        {isLoading && (
          <Backdrop type="dark">
            <Loader />
          </Backdrop>
        )}
        <ontainer />

        <div className={classes.AddZone}>
          <h1 className={classes.Heading}>Add Zone</h1>
          <PrimaryInputField
            label="Name"
            placeholder="Zone name (max 7 characters)"
            value={name}
            onChange={(e) => setName(e.target.value)}
            customClasses={classes.NameInput}
            max={7}
          />
          <PrimaryInputField
            type="number"
            label="Area"
            placeholder="Enter the area in sqaure meters"
            value={area}
            onChange={(e) => setArea(e.target.value)}
            customClasses={classes.AreaInput}
          />

          {/* <PrimarySelectField
          label="Head Waiter"
          placeholder="Head Waiter "
          options={["Rizwan Ahmad Khan", "Waleed Anjum"]}
          onChange={() => {}}
          customClasses={classes.SelectInput}
        /> */}
          <div className={classes.Area}>
            <span>Area</span>
            <label className={classes.AreaRaioInput}>
              <input
                defaultChecked
                onChange={() => setZoneType("in-door")}
                type="radio"
                name="area"
                value="in-door"
              />
              <span></span>
              Indoor
            </label>
            <label className={classes.AreaRaioInput}>
              <input
                onChange={() => setZoneType("out-door")}
                type="radio"
                name="area"
                value="out-door"
              />
              <span></span>
              Outdoor
            </label>
          </div>
          {/* <PrimarySelectField
          placeholder="Select Indoor location from dropdown"
          options={["Corner", "Center"]}
          onChange={() => {}}
          customClasses={classes.SelectInput}
        />
        <div className={classes.Facilities}>
          <span>Facilities</span>
          <ul>
            {FACILITIES.map((facility) => {
              return (
                <li
                  key={facility}
                  className={getFacilityStyleClass(facility)}
                  onClick={() => addFicility(facility)}
                >
                  {facility}
                </li>
              );
            })}
          </ul>
        </div> */}
          <div className={classes.Actions}>
            <PrimaryButton
              onClicked={() => navigate("/floor")}
              customClasses={classes.CancelBtn}
              text="Cancel"
            />
            <PrimaryButton
              isDisabled={!verifyForm()}
              text="Proceed"
              onClicked={onProceedHandler}
            />
          </div>
        </div>
        <ToastContainer />
      </div>
    </GlobalPadding>
  );
};

export default AddZone;
